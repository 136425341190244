import React from "react"
import { connect } from 'react-redux';
import {
	Login,setprojectlist
} from '../actions/Actions'
import {
	get_projects_list,
	get_categories_list,update_category,create_category,delete_category,
} from '../api'
import LoadingPage from './LoadingPage'
import add_icon from "./../assets/images/add_icon.png"
import refresh from "./../assets/images/refresh.png"
import more_icon from "./../assets/images/more.png"
import search_icon from "./../assets/images/search_white.png"
import photo_white from './../assets/images/client_white.png'
import up_icon from "./../assets/images/up.png"

class ProjectListPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isloading:true,
			scroll_refreshing:false,

			new_category_name:'',
			new_category_project:'',

			categories_list:[],
			add_dict:{},

			delete_item:null,

			text_search:''
		}
	}

	componentDidMount=async()=> {
		this.get_projects(true)
		this.get_categories_list_(true)
	}
	get_projects=async(force=false)=>{
		if(!force&&this.state.isloading)return
		this.setState({isloading:true})
		let result= await get_projects_list(this.props.control.token)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}else if(result===undefined||result===null){alert('Get project error');this.setState({isloading:false});return}
		else if(result.result!==undefined&&result.result!=='fail'){
			let dict={}
			for(let i of result.result){
				dict[i._id]=i
			}
			this.props.dispatch(setprojectlist(result.result.map((x)=>{return {...x,value:x.project_id,_id:x._id}}),dict))
		}
		this.setState({isloading:false})
	}
	get_categories_list_=async(force=false)=>{
		if(!force&&this.state.isloading)return
		this.setState({isloading:true})
		let result= await get_categories_list(this.props.control.token)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}else if(result===undefined||result===null){alert('Get Category List error');this.setState({isloading:false});return}
		else if(result.result!==undefined&&result.result!=='fail'){
			this.setState({categories_list:result.result})
		}
		this.setState({isloading:false})

	}

	handleAddCategory=(proj_id)=>async ()=>{
		if(this.state.add_dict[proj_id]===undefined||this.state.add_dict[proj_id]===''){
			alert('Please enter a new category')
			return
		}
		if(this.state.add_dict[proj_id].length>50){
			alert('Category Name Max 50 Characters')
			return
		}
		if(this.state.isloading)return
		this.setState({isloading:true})
		let data={
			project_id:proj_id,
			category:this.state.add_dict[proj_id]
		}
		let result= await create_category(this.props.control.token,data)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}else if(result===undefined||result===null){alert(result.message||'update error');this.setState({isloading:false});return}
		else if(result.result!==undefined&&result.result!=='fail'){
			this.get_categories_list_(true)
			let add_dict_={...this.state.add_dict}
			add_dict_[proj_id]=''
			this.setState({add_dict:add_dict_})
		}
		this.setState({isloading:false})
		
	}
	handleEditCategoryText=(item,index)=>(e)=>{
		let categories_list_=[...this.state.categories_list]
		categories_list_[index].category=e.target.value
		this.setState({categories_list:categories_list_})
	}
	handleAddCategoryText=(proj_id)=>(e)=>{
		let add_dict_={...this.state.add_dict}
		add_dict_[proj_id]=e.target.value
		this.setState({add_dict:add_dict_})
	}
	handleUpdateCategory=(item,index)=>async ()=>{
		if(!item.isEditing){
			let categories_list_=[...this.state.categories_list]
			categories_list_[index].isEditing=true
			this.setState({categories_list:categories_list_})
			return
		}
		if(item.category===''){
			alert('Category name is empty')
			return
		}
		if(item.category.length>50){
			alert('Category Name Max 50 Characters')
			return
		}
		if(this.state.isloading)return
		this.setState({isloading:true})
		let data={
			_id:item._id,
			project_id:item.project_id,
			category:item.category
		}
		let result= await update_category(this.props.control.token,data)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}else if(result===undefined||result===null){alert(result.message||'update error');this.setState({isloading:false});return}
		else if(result.result!==undefined&&result.result!=='fail'){
			this.get_categories_list_(true)
		}
		this.setState({isloading:false})
		
	}


	hanele_refresh=()=>{
		this.get_categories_list_()
	}
	handleDelete=(item)=>()=>{
		this.setState({ispopup:true,delete_item:item})
	}
	handledeleteCategory= async ()=>{
		if(this.state.isloading)return
		this.setState({isloading:true})
		let result= await delete_category(this.props.control.token,this.state.delete_item._id)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}else if(result===undefined||result===null){alert(result.message||'update error');this.setState({isloading:false});return}
		else if(result.result!==undefined&&result.result!=='fail'){
			this.get_categories_list_(true)
			this.setState({delete_item:null,ispopup:null})
		}
	}
	render(){
		let control=this.props.control
		return(<div style={{width:'100%',height:'100%',color:'white',overflowY: 'scroll'}}>
			<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
				<div style={{backgroundColor:'#007EA7',borderRadius:5,marginRight:2,width:'80%',height:46,marginLeft:10,display:'flex',flexDirection:'row',alignItems:'center'}}>
					<img src={search_icon} style={{marginLeft:10,marginRight:10,width:30,height:30}} alt='search'/>
					<input style={{ backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)', color:"#98E2FF",width:'95%',fontSize: 19,marginLeft:5}} value={this.state.text_search} onChange={(e)=>{this.setState({text_search:e.target.value})}} placeholder="Search for project...."/>
				</div>
			</div>
			<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
				<div style={{display:'flex',flexDirection:'row',width:'80%',alignItems:'center',justifyContent:'space-between'}}>
					<div className='center_row'>
						<img src={photo_white} style={{marginLeft:10,marginRight:10,width:47,height:47}} alt='photo_white'/>
						<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",}}>Category Management</div>
					</div>
					
					<div onClick={this.hanele_refresh} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
						<img className='mousehover' src={refresh} style={{width:20,height:20}} alt='refresh'/>
					</div>
				</div>
			</div>
			
			{<div>{control.project_list.map((proj,proj_index)=>(
				<div key={proj_index} style={{width:'100%'}}>
					{!this.state.text_search===''||!proj.project_id.toLowerCase().includes(this.state.text_search.toLowerCase())||<div  key={proj_index} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',justifyContent:'center'}}>
						<div className='mousehover' onClick={()=>{
							let pro_list=control.project_list
							pro_list[proj_index].isselected=!pro_list[proj_index].isselected
							this.props.dispatch(setprojectlist(pro_list))
						}} style={{display:'flex',flexDirection:'row',width:'80%',borderBottom:"1px solid rgba(0, 126, 167, 1.0)",borderTop:"1px solid rgba(0, 126, 167, 1.0)",padding:10,alignItems:'center'}}>
							<div style={{color:'rgba(68,204,215,1.0)',fontSize:20,fontWeight:'bold',width:'95%'}}>{proj.project_id}</div>						 		
							{!proj.isselected?<img src={more_icon} style={{width:17,height:17}} alt='more_icon'/>:
							<img src={up_icon} style={{width:17,height:17}} alt='up_icon'/>}
						</div>
						{proj.isselected&&
							<div style={{display:'flex',flexDirection:'column',width:'80%',}}>
								{this.state.categories_list.map((item,index)=>item.project_id===proj._id?
									<div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'center',alignItems:'center'}}>
										<div style={{color:'white',fontSize:20,fontWeight:'bold',flex:1}}>Category: </div>
										{item.isEditing?
											<input style={{color:'black',fontSize:18,fontWeight:'bold',flex:1}} value={item.category} onChange={this.handleEditCategoryText(item,index)} placeholder='Please Enter'/>:
											<div style={{color:'rgba(125, 218, 255, 1.0)',fontSize:18,fontWeight:'bold',flex:1}}>{item.category||''}</div>
										}
										<div className='mousehover' style={{display:'flex',padding:5,margin:10,width:90,justifyContent:'center',alignItems:'center',borderRadius:10,fontSize:18,color:'white',backgroundColor:'#7DAADD'}} onClick={this.handleUpdateCategory(item,index)}>
											{item.isEditing?'Update':'Edit'}
										</div>
										<div className='mousehover' style={{display:'flex',padding:5,margin:10,width:90,justifyContent:'center',alignItems:'center',borderRadius:10,fontSize:18,color:'white',backgroundColor:'#FF4F4F'}} onClick={this.handleDelete(item)}>
											Delete
										</div>
									</div>
									:<div key={index}/>
								)}
								<div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'center',alignItems:'center'}}>
									<div style={{color:'white',fontSize:18,fontWeight:'bold',flex:1}}>New Category: </div>
									<input style={{color:'black',fontSize:18,fontWeight:'bold',flex:1}} value={this.state.add_dict[proj._id]||''} onChange={this.handleAddCategoryText(proj._id)} placeholder='Please Enter'/>
									<div className='mousehover' style={{display:'flex',padding:5,margin:10,width:90,justifyContent:'center',alignItems:'center',borderRadius:10,fontSize:18,color:'white',backgroundColor:'#7DAADD'}} onClick={this.handleAddCategory(proj._id)}>
										+ ADD
									</div>
								</div>

							</div>
						}

					</div>}
				</div>
			))}</div>}
			<div style={{minWidth:'100%',minHeight:'300px'}}/>
			{!this.state.isloading||<LoadingPage/>}
			{!this.state.ispopup||<div style={{backgroundColor:'#003459',position:'absolute',top:0,bottom:0,left:0,right:0,opacity:.5}}></div>}
			{!this.state.ispopup||<div style={{display:'flex',flexDirection:'column',position:'absolute',top: window.innerHeight/2-100,left: window.innerWidth/2-150,width:300,height:200,padding:20,backgroundColor:'rgba(0, 66, 113, 1.0)',alignItems:'center',borderRadius:15,border:'2px solid white',}}>
				<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",borderBottom:'2px solid white'}}>Confirm?</div>
				<div style={{display:'flex',width:'100%',flexDirection:'row',alignItems:'center',flex:1,justifyContent:'center',height:'100%'}}>
					<div className='mousehover' onClick={this.handledeleteCategory}
						style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
						<div style={{color: "rgba(68, 215, 182, 1.0)",fontSize: 24,fontWeight: "bold",}}>YES</div>
					</div>
					<div className='mousehover' onClick={()=>{this.setState({ispopup:false})}}
						style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
						<div style={{color: "red",fontSize: 24,fontWeight: "bold",}}>NO</div>
					</div>
				</div>
			</div>}
		</div>)
	}
}


export default connect((store)=>{
  return{
	control:store.firstReducer,
  }
})(ProjectListPage)



