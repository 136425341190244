import React from "react"
import { connect } from 'react-redux';
import {
	setpageindex,
	Login,
	setmenu,
	setprojectlist
} from '../actions/Actions'
import {
	get_submittedform_by_project,
	get_form_by_id,
	approve_form_batch
} from '../api'
import LoadingPage from './LoadingPage'
import {formatDate}from '../util'

import menu from "./../assets/images/menu.png"
import search_icon from "./../assets/images/search_white.png"
import Submitted_icon from "./../assets/images/Submitted.png"
import more_icon from "./../assets/images/more.png"
import up_icon from "./../assets/images/up.png"
import refresh from "./../assets/images/refresh.png"
import preview from "./../assets/images/preview.png"

const MonthDict={
	0:'January',
	1:'February',
	2:'March',
	3:'April',
	4:'May',
	5:'June',
	6:'July',
	7:'August',
	8:'September',
	9:'October',
	10:'November',
	11:'December',
}

class SubmittedFormPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isloading:false,
			scroll_refreshing: false,
			item_list:{},
			ispopup:false,
			text_search:'',
		}
	}

	componentDidMount() {
		let pro_list=this.props.control.project_list
		for (let i in pro_list)
			pro_list[i].isexpend=false
		this.props.dispatch(setprojectlist(pro_list))
	}
	componentWillUnmount() {
	}
	get_form_id=async(_id,force=false)=>{
		if (this.state.isloading&&!force)return;
		this.setState({isloading:true});
		let result=await get_form_by_id(this.props.control.token,_id)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));
			return
		}
		if(result!=null&&result.result!==undefined&&result.result.length>0&&result.result!=='fail'){
			this.props.dispatch(setpageindex(6,result.result[0]))
			return
		}else alert('Fetch error')
		this.setState({isloading:false});
	}
	get_submitted_form_by_project=async(proj,proj_index)=>{
		this.setState({isloading:true});
		let item_list=this.state.item_list
		if(item_list[proj.value]===undefined){
			item_list[proj.value]={}
			let result=await get_submittedform_by_project(this.props.control.token,proj.value)
			if(result.message&&result.message==='Unauthorized'){
				this.props.dispatch(Login(false));alert('Session Expired!');return
			}else if(result!=null&&result.result!==undefined&&result.result!=='fail'){
				for (let i in result.result){
					let date = new Date(result.result[i].create_date)
					let quarter = MonthDict[Math.ceil((date.getMonth()))]||'error' 
					let category = result.result[i].user_category||'Uncategorized'
					let year = date.getFullYear()
					if(item_list[proj.value][`${year}`]===undefined)
						item_list[proj.value][`${year}`]={quarter:{}}
					if(item_list[proj.value][`${year}`].quarter[`${quarter}`]===undefined)
						item_list[proj.value][`${year}`].quarter[`${quarter}`]={category:{}}
					if(item_list[proj.value][`${year}`].quarter[`${quarter}`].category[category]===undefined)
						item_list[proj.value][`${year}`].quarter[`${quarter}`].category[category]={list:[]}
					item_list[proj.value][`${year}`].quarter[`${quarter}`].category[category].list.push(result.result[i])
				}
				this.setState({item_list:item_list,isloading:false})
			} else {
				alert('fetch error!')
				this.setState({isloading:false})
			}
		}else this.setState({isloading:false});

		let pro_list=this.props.control.project_list
		pro_list[proj_index].isexpend=!pro_list[proj_index].isexpend
		this.props.dispatch(setprojectlist(pro_list))
	}
	hanele_refresh=()=>{
		this.setState({item_list:{}})
		let pro_list=this.props.control.project_list
		for (let i in pro_list)
			pro_list[i].isexpend=false
		this.props.dispatch(setprojectlist(pro_list))
	}
	handlePreview=()=>{
		if (this.state.isloading)return;
		this.setState({isloading:true});
		let selected_id=null
		let isSelected=false
		let item_list=this.state.item_list
		for (let p in item_list){
			for (let y in item_list[p]){
				if(item_list[p][y].quarter)
					for(let m in  item_list[p][y].quarter)
						if(item_list[p][y].quarter[m].category)
							for(let c in item_list[p][y].quarter[m].category)
								if(item_list[p][y].quarter[m].category[c].list)
									for(let f in item_list[p][y].quarter[m].category[c].list){
										if(item_list[p][y].quarter[m].category[c].list[f].isSelected){
											if(isSelected){
												alert('Please select ONE form only for preview.')
												this.setState({isloading:false});
												return
											}
											selected_id=item_list[p][y].quarter[m].category[c].list[f]._id
											isSelected=true
										}
									}
			}
		}
		if(!isSelected) {
			alert('Please a form for preview.')
			this.setState({isloading:false});
			return
		}
		this.get_form_id(selected_id,true)
	}
	handleApprove=async ()=>{
		if (this.state.isloading)return;
		this.setState({isloading:true});

		let list=[]
		let item_list=this.state.item_list
		for (let p in item_list){
			for (let y in item_list[p]){
				if(item_list[p][y].quarter)
					for(let m in  item_list[p][y].quarter)
						if(item_list[p][y].quarter[m].category)
							for(let c in item_list[p][y].quarter[m].category)
								if(item_list[p][y].quarter[m].category[c].list)
									for(let f in item_list[p][y].quarter[m].category[c].list){
										if(item_list[p][y].quarter[m].category[c].list[f].isSelected){
											list.push(item_list[p][y].quarter[m].category[c].list[f])
										}
									}
			}
		}
		if(list.length===0){
			alert('Please select at least one form')
			this.setState({isloading:false});
			return
		}
		let data={
			list:list
		}
		let result= await approve_form_batch(this.props.control.token,data)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}else if(result.errno!==0){
			alert(result.message||'Error');this.setState({isloading:false});return
		}else{
			this.setState({isloading:false});
			this.hanele_refresh()
		}

	}
	
	render() {
		return(
			<div style={{width:'100%',height:'100%',overflowY: 'scroll'}}>
				<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
					<div style={{backgroundColor:'#007EA7',borderRadius:5,marginRight:2,width:'80%',height:46,marginLeft:10,display:'flex',flexDirection:'row',alignItems:'center'}}>
						{true||<img onClick={()=>{this.props.dispatch(setmenu(true))}} className='mousehover' src={menu} style={{marginLeft:10,marginRight:10,width:20,height:20}} alt='menu'/>}
						<img src={search_icon} style={{marginLeft:10,marginRight:10,width:30,height:30}} alt='forms'/>
						<input style={{ backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)', color:"#98E2FF",width:'95%',fontSize: 19,marginLeft:5}} value={this.state.text_search} onChange={(e)=>{this.setState({text_search:e.target.value})}} placeholder="Search for Form...."/>
					</div>
				</div>

				<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20,marginBottom:20}}>
					<div className='center_row' style={{width:'60%'}}>
						<img src={Submitted_icon} style={{marginLeft:10,marginRight:10,width:47,height:47}} alt='Submitted_icon'/>
						<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",}}>Submitted</div>
						<div className='mousehover' onClick={this.handlePreview} style={{display:'flex',opacity:1,flexDirection:'row',backgroundColor:'#7DDAFF',borderRadius:5,marginLeft:20,height:40,paddingLeft:20,paddingRight:20,justifyContent:'center',alignItems:'center'}}>
							<img src={preview} style={{width:17,height:17,marginRight:5}} alt='preview'/>
							<div style={{color:'white',fontSize:20,fontWeight:'bold'}}>Preview</div>
						</div>

						<div className='mousehover' onClick={this.handleApprove} style={{display:'flex',flexDirection:'row',backgroundColor:'#7DEEAA',borderRadius:5,marginLeft:20,height:40,paddingLeft:20,paddingRight:20,justifyContent:'center',alignItems:'center'}}>
							<div style={{color:'white',fontSize:20,fontWeight:'bold'}}>Approve</div>
						</div>

					</div>
					<div onClick={this.hanele_refresh}style={{display:'flex',flexDirection:'row',width:'20%',alignItems:'center',justifyContent:'flex-end'}}>
						<img className='mousehover' src={refresh} style={{width:20,height:20}} alt='refresh'/>
					</div>
				</div>
				<div style={{width:'100%',display:'flex',flex:1,height:'80%',overflowY:'auto',flexDirection:'column'}}>
					
					{this.props.control.project_list.map((proj,proj_index)=>(
						<div key={proj_index} style={{width:'100%'}}>
							{!this.state.text_search===''||!proj.value.toLowerCase().includes(this.state.text_search.toLowerCase())||<div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',justifyContent:'center'}}>
								<div className='mousehover' onClick={()=>{
									this.get_submitted_form_by_project(proj,proj_index)
								}} style={{display:'flex',flexDirection:'row',width:'80%',borderBottom:"1px solid rgba(0, 126, 167, 1.0)",borderTop:"1px solid rgba(0, 126, 167, 1.0)",padding:10,alignItems:'center'}}>
									<div style={{color:'rgba(68,204,215,1.0)',fontSize:20,fontWeight:'bold',width:'95%'}}>{proj.value}</div>						 		
									{!proj.isexpend?<img src={more_icon} style={{width:17,height:17}} alt='more_icon'/>:
									<img src={up_icon} style={{width:17,height:17}} alt='up_icon'/>}
								</div>
								{!proj.isexpend||this.state.item_list[proj.value]===undefined||Object.keys(this.state.item_list[proj.value]).map( x => 
									<div key={x} style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
									 	<div className='mousehover' style={{display:'flex',flexDirection:'column',width:'80%',borderTop:'1px solid rgba(0, 126, 167, 1.0)',borderBottom:'1px solid rgba(0, 126, 167, 1.0)',paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10,alignItems:'center'}} onClick={()=>{
											let list={...this.state.item_list}
											list[proj.value][x].isexpend=!list[proj.value][x].isexpend
											this.setState({item_list:list})
										}}>
											<div style={{color: "#7DDAFF",fontSize: 20,fontWeight: "bold"}}>
												{this.state.item_list[proj.value][x].isexpend?"----------":''}&nbsp;{x}&nbsp;{this.state.item_list[proj.value][x].isexpend?"----------":''}
											</div>
										</div>
										{!this.state.item_list[proj.value][x].isexpend||Object.keys(this.state.item_list[proj.value][x].quarter).map( q => 
											<div key={q} style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
												<div className='mousehover' style={{display:'flex',flexDirection:'column',width:'80%',borderTop:'1px solid rgba(0, 126, 167, 1.0)',borderBottom:'1px solid rgba(0, 126, 167, 1.0)',paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10,alignItems:'center'}} onClick={()=>{
													let list={...this.state.item_list}
													list[proj.value][x].quarter[q].isexpend=!list[proj.value][x].quarter[q].isexpend
													this.setState({item_list:list})
												}}>
													<div style={{color: "white",fontSize: 20,fontWeight: "bold"}}>
														{this.state.item_list[proj.value][x].quarter[q].isexpend?"----------":''}&nbsp;{q}&nbsp;{this.state.item_list[proj.value][x].quarter[q].isexpend?"  ----------":''}
													</div>
												</div>


												{!this.state.item_list[proj.value][x].quarter[q].isexpend||Object.keys(this.state.item_list[proj.value][x].quarter[q].category).map( c => 
													<div key={c} style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
														<div className='mousehover' style={{display:'flex',flexDirection:'column',width:'80%',borderTop:'1px solid rgba(0, 126, 167, 1.0)',borderBottom:'1px solid rgba(0, 126, 167, 1.0)',paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10,alignItems:'center'}} onClick={()=>{
															let list={...this.state.item_list}
															list[proj.value][x].quarter[q].category[c].isexpend=!list[proj.value][x].quarter[q].category[c].isexpend
															this.setState({item_list:list})
														}}>
															<div style={{color: "rgba(169,250,180,.7)",fontSize: 20,fontWeight: "bold"}}>
																{this.state.item_list[proj.value][x].quarter[q].category[c].isexpend?"----------":''}&nbsp;{c}&nbsp;{this.state.item_list[proj.value][x].quarter[q].category[c].isexpend?"  ----------":''}
															</div>
														</div>


											 			{!this.state.item_list[proj.value][x].quarter[q].category[c].isexpend||this.state.item_list[proj.value][x].quarter[q].category[c].list.map((item,index)=>(
												 			<div className='mousehover' onClick={()=>{
																let list={...this.state.item_list}
																list[proj.value][x].quarter[q].category[c].list[index].isSelected=!list[proj.value][x].quarter[q].category[c].list[index].isSelected
																this.setState({item_list:list})

															}} key={index} style={{display:'flex',flexDirection:'row',width:'80%',borderBottom:"1px solid rgba(0, 126, 167, 1.0)",borderTop:"1px solid rgba(0, 126, 167, 1.0)",padding:10,alignItems:'center'}}>
																<div style={{width:26,height:26,borderRadius:13,border:'1px solid rgba(125,218,255,.7)',display:'flex',justifyContent:'center',alignItems:'center'}}>
																	<div style={{width:20,height:20,borderRadius:10,backgroundColor:item.isSelected?'rgba(0, 218, 195, 1.0)':'transparent'}}></div>
																</div>
																<div style={{display:'flex',flexDirection:'column',marginLeft:20}}>
																	<div className='center_row' style={{width:'100%',justifyContent:'space-between',alignItems:'center'}}>
																		<div style={{color: "#ffffff",fontSize: 30,fontWeight: "bold",}}>{item.form_id}</div>
																		<div style={{color:"rgba(2, 172, 228, 1.0)",fontSize:18,fontWeight:"bold",marginLeft:30}}>Test Date: {formatDate(new Date(item.create_date))}</div>
																	</div>
																	{item.form_description===null||item.form_description===undefined||<div style={{color: "#00DAC3",fontSize: 14,fontWeight: "bold"}}>Description: {item.form_description}</div>}
																	<div style={{display:'flex',width:'100%',flexDirection:'row',fontSize:17,fontWeight:'bold',color:'white',alignItems:'center'}}>
																		<div style={{display:'flex',flexDirection:'row',}}>Submitted by:&nbsp;<div style={{color:"rgba(125,218,255,1.0)",fontSize:20,fontWeight:"bold"}}>{item.submitted_by}</div>&nbsp;&nbsp;</div>
																		{item.location===null||item.location===undefined||item.location===''||<div style={{display:'flex',flexDirection:'row',color:"white",fontSize:17,fontWeight:"bold",marginLeft:30}}>Location:&nbsp;<div style={{color:"rgba(125,218,255,1.0)",fontSize:20,fontWeight:"bold"}}>{item.location}</div>&nbsp;&nbsp;</div>}
																		{item.position===null||item.position===undefined||item.position===''||<div style={{display:'flex',flexDirection:'row',color:"white",fontSize:17,fontWeight:"bold",marginLeft:30}}>Position:&nbsp;<div style={{color:"rgba(125,218,255,1.0)",fontSize:20,fontWeight:"bold"}}>{item.position}</div>&nbsp;&nbsp;</div>}
																		{item.type_serial===null||item.type_serial===undefined||item.type_serial===''||<div style={{display:'flex',flexDirection:'row',color:"white",fontSize:17,fontWeight:"bold",marginLeft:30}}>Type/ Serial No.:&nbsp;<div style={{color:"rgba(125,218,255,1.0)",fontSize:20,fontWeight:"bold"}}>{item.type_serial}</div>&nbsp;&nbsp;</div>}
																	</div>
																</div>
															</div>
														))}
													</div>
											 	)}

											</div>
										)}
									</div>)
								}
							</div>}
						</div>
					))}
					<div style={{width:'100%',minWidth:'100%',height:'200px',minHeight:'200px'}}/>
				</div>
				{!this.state.isloading||<LoadingPage/>}
			</div>
		);
	}
}

export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(SubmittedFormPage)
