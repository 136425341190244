import Dropdown from 'react-dropdown'
import React from "react"
import { connect } from 'react-redux';
import html2pdf from 'html2pdf.js'
import {
	setpageindex,Login
} from '../actions/Actions'
import {
	get_stamp,create_reports,
	get_photos_by_photoid,
	convert_to_pdf,
	get_covers_by_project_id,
	update_cover,
	delete_cover
} from '../api'
import StampView from './StampView'
import ViewPDFPage from './ViewPDFPage'
import {formatDate2}from '../util'
import LoadingPage from './LoadingPage'
import {render_preview} from './RenderForm';
import header from './header.json'
import header2 from './header2.json'
import mgistamp from './mgistamp.json'

//import { PDFDocument } from 'pdf-lib';
//import { Buffer } from 'buffer'

import back_icon from "./../assets/images/back.png"
import complete_icon from "./../assets/images/complete.png"
import preview_icon from "./../assets/images/preview.png"
import picture_icon from "./../assets/images/picture.png"
import save_icon from "./../assets/images/save.png"
import select_icon from "./../assets/images/eye.png"

class FillReportPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isloading:true,

			tab_index:0,

			project:'Project Name',
			address:'Canossa Hospital (Caritas)\r\n1 Old Peak Road\r\nHong Kong',
			ref:'',
			towhom:'Mr. Tsang',
			quotation_no:'Our Quotation Reference No.:[Quotation No.]\r\n[Project Name] \r\n(Contract Priod: [Date] ~ [Date]) ',
			detail:'Details here',
			stamp:'',
			staff_name:'',
			staff_title:'',
			remarks:'remarks',

			isbyfax:false,isbyemail:false,isbymail:false,
			fax_no:'',email_addr:"",

			isPDFmode:false,
			pdfurl:'',
			forms:[],

			cover_type:'Type 1',

			include_cover:true,include_fs:true,include_report:true,

			text_A:'Reference is made to the captioned maintenance services. We would like to report to you that the 2nd maintenance service has been completed on [Date]. The installed equipment under this maintenance service contract has been checked/ adjusted/ regulated and are now in normal operating condition with following particulates: ',
			text_AA:'Enclosed please find the Service Report for your reference/ record.',
			text_B:'If you have any query, please feel free to contact our Mr. Kwai Chan at 3913 0243/ 5547 6197.',
			text_C:'Page 3 Text',
			text_D:'Page 4 Text',
			text_E:'Page 5 Text',


			company_header:'MGI (Far East) Limited',
			
			project_id:'',
			cover_list:[],
			cover_index:0,
		}
	}

	async componentDidMount() {
		let projects=this.props.control.project_list
		let proj={}
		for (let p in projects){
			if (projects[p].project_id===this.props.control.forms[0].project)
				proj=projects[p]
		}
		this.setState({
			forms:this.props.control.forms,
			project:this.props.control.forms[0].project,
			project_id:proj._id,
			address:`${proj.client_id}\r\n${proj.client_address}`,
			towhom:proj.client_contact,
			quotation_no:proj.quotation_info,
		})
		this.get_stamp_photo(true)
		this.get_covers_by_project_id_(proj._id,true)
	}
	handleImageChange(e) {
		e.preventDefault();
		let file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			this.setState({
				file: file,
				field_service_photo: reader.result
			});
		};
	}

	get_covers_by_project_id_ = async (project_id,force=false) => {
		if(this.state.isloading&&!force)return
		this.setState({isloading:true})
		let result=await get_covers_by_project_id(this.props.control.token,project_id)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert("Session Expired!");return
		}else if(result===undefined||result.errno!=0){
			alert(result.message||'fetch covers error');this.setState({isloading:false});return
		}else{
			for (let i of result.result)
				i.value=i.cover_id
			this.setState({isloading:false,cover_list:result.result,cover_index:0})
		}
	}
	

	get_stamp_photo=async (force=false)=>{
		if(this.state.isloading&&!force)return
		this.setState({isloading:true})
		let result=await get_stamp(this.props.control.token)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));
			alert("Session Expired!")
			return
		}
		if(result!=null&&result.result!==undefined&&result.result!=='fail'&&result.result.length>0){
			this.setState({staff_title:result.result[0].position||'',staff_name:result.result[0].username})
		}else alert('fetch stamp error')
		this.setState({isloading:false})
	}

	create_reports_=async (force=false)=> {
		if(!force&&this.state.isloading)return
		this.setState({isloading:true})
		let form_ids=[]
		for (let i in this.props.control.forms){
			form_ids.push({_id:this.props.control.forms[i]._id,form_id:this.props.control.forms[i].form_id})
		}
		let report={
			form_ids:form_ids,
			base64:this.state.pdfurl,
			project_id:this.state.project
		}
		let result=await create_reports(this.props.control.token,report)
		if(result.message&&result.message==='Unauthorized'){this.props.dispatch(Login(false));alert('Session Expired!');return}
		if(result===undefined||result===null)alert('create report error 1')
		else if(result.result!=='success')alert(result.message)
		else if(result.result==='success'){
			this.props.dispatch(setpageindex(10))
			return
		}else alert('create report error')
		this.setState({isloading:false})
	}
	calculateWidth=(line,index)=>{
		let total=0
		for(let q of line)
			total+=q.flex||1
		return 100/total*(line[index].flex||1)
	}
	renderCheckBox=(ans,type)=>{
		return`<div style="margin-left:5px;display:flex;flex-direction:row;align-items:center;justify-content:center;">
			<div style="width:7px;min-width:7px;height:7px;min-height:7px; border: 1px solid black;padding:1px;padding-top:2px;display:flex;justify-content:center;align-items:center;">
				${(ans?ans.toLowerCase():'')===type.toLowerCase()?'√':'&nbsp;'}
			</div> 
		</div>
		<div style="margin-left:5px;">${
			type==='NA'
			?'N/A'
			:type==='notinstalled'
			?'Not Installed'
			: type==='50O250N20'
			? 'O2/N2O'
			:type
		}</div>`
	}

	createPDF=async ()=> {
		const pdf_Width=600
		const pdf_Height=850
		const right_margin='15px'
		this.setState({isloading:true})
		let htmlstring=''

		const pdfheight=794
		const font_S=12

		if(this.state.include_cover){
			let cover_html=`
<img src="${this.state.company_header==='MGI (Far East) Limited'?header.base64:header2.base64}" style="width:100%;" alt="load header error">
<p>Our Ref: ${this.state.ref}</p>
<p>${formatDate2(new Date())}</p>
<div style="display: table;width:100%;">
	<div style="display: table-cell; width:55%; ">${this.state.address.replace(/\n/g, "<br />")}<br/></div>
	<div style="display: table-cell; width:45%;">
		<div style="line-height:20px; font-size: ${font_S*1.2}px;"><b><div style="min-width:12px;height:12px; display:inline-block; border: 1px solid black;padding:1px">${this.state.isbyfax?'√':'&nbsp;'}</div> By Fax:${this.state.fax_no}</b></div>
		<div style="line-height:20px; font-size: ${font_S*1.2}px;"><b><div style="min-width:12px;height:12px; display:inline-block; border: 1px solid black;padding:1px">${this.state.isbyemail?'√':'&nbsp;'}</div>  By Email:${this.state.email_addr}</b></div>
		<div style="line-height:20px; font-size: ${font_S*1.2}px;"><b><div style="min-width:12px;height:12px; display:inline-block; border: 1px solid black;padding:1px">${this.state.isbymail?'√':'&nbsp;'}</div>  By Mail</b></div>
	</div>
</div>
<p>Attn: ${this.state.towhom}</p>
<p>Dear Sir,</p>
<div style="display: table;width:100%;">
	<div style="display: table-cell; width:5%;"><b>Re:&nbsp;&nbsp;</b></div>
	<div style="display: table-cell; width:90%;"><b>${this.state.quotation_no.replace(/  +/g, ' ').replace(/\n/g, "<br />")}</b></div>
</div>
<p>${this.state.text_A.replace(/\n/g, "<br />").replace('1st','1<sup>st</sup>').replace('2nd','2<sup>nd</sup>').replace('3rd','3<sup>rd</sup>')}</p>
<p>${this.state.detail.replace(/\n/g, "<br />")} </p>
<p>${this.state.text_AA.replace(/\n/g, "<br />").replace('1st','1<sup>st</sup>').replace('2nd','2<sup>nd</sup>').replace('3rd','3<sup>rd</sup>')}</p>

${(this.state.cover_type==="2 Pages"||this.state.cover_type==="3 Pages"||this.state.cover_type==="4 Pages"||this.state.cover_type==="5 Pages")?
	`</div></div><div style="page-break-inside:avoid !important; height:100%;font-size: ${font_S*1.1}px;"><div style="padding:40;">
<img src="${this.state.company_header==='MGI (Far East) Limited'?header.base64:header2.base64}" style="width:100%;" alt="load header error">
<p>Our Ref: ${this.state.ref}</p>
<p>${formatDate2(new Date())}</p>
<p>Page 2 of ${this.state.cover_type.charAt(0)}</p>
<br/>	`:""
}
<p>${this.state.text_B.replace(/\n/g, "<br />").replace('1st','1<sup>st</sup>').replace('2nd','2<sup>nd</sup>').replace('3rd','3<sup>rd</sup>')}</p></br>

${(this.state.cover_type==="3 Pages"||this.state.cover_type==="4 Pages"||this.state.cover_type==="5 Pages")?
	`</div></div><div style="page-break-inside:avoid !important; height:100%;font-size: ${font_S*1.1}px;"><div style="padding:40;">
<img src="${this.state.company_header==='MGI (Far East) Limited'?header.base64:header2.base64}" style="width:100%;" alt="load header error">
<p>Our Ref: ${this.state.ref}</p>
<p>${formatDate2(new Date())}</p>
<p>Page 3 of ${this.state.cover_type.charAt(0)}</p>
<br/>
<p>${this.state.text_C.replace(/\n/g, "<br />").replace('1st','1<sup>st</sup>').replace('2nd','2<sup>nd</sup>').replace('3rd','3<sup>rd</sup>')}</p></br>
	`:""
}

${(this.state.cover_type==="4 Pages"||this.state.cover_type==="5 Pages")?
	`</div></div><div style="page-break-inside:avoid !important; height:100%;font-size: ${font_S*1.1}px;"><div style="padding:40;">
<img src="${this.state.company_header==='MGI (Far East) Limited'?header.base64:header2.base64}" style="width:100%;" alt="load header error">
<p>Our Ref: ${this.state.ref}</p>
<p>${formatDate2(new Date())}</p>
<p>Page 4 of ${this.state.cover_type.charAt(0)}</p>
<br/>
<p>${this.state.text_D.replace(/\n/g, "<br />").replace('1st','1<sup>st</sup>').replace('2nd','2<sup>nd</sup>').replace('3rd','3<sup>rd</sup>')}</p></br>
	`:""
}

${(this.state.cover_type==="5 Pages")?
	`</div></div><div style="page-break-inside:avoid !important; height:100%;font-size: ${font_S*1.1}px;"><div style="padding:40;">
<img src="${this.state.company_header==='MGI (Far East) Limited'?header.base64:header2.base64}" style="width:100%;" alt="load header error">
<p>Our Ref: ${this.state.ref}</p>
<p>${formatDate2(new Date())}</p>
<p>Page 5 of ${this.state.cover_type.charAt(0)}</p>
<br/>
<p>${this.state.text_E.replace(/\n/g, "<br />").replace('1st','1<sup>st</sup>').replace('2nd','2<sup>nd</sup>').replace('3rd','3<sup>rd</sup>')}</p></br>
	`:""
}

<p>Yours faithfully,</p><p>${this.state.company_header}</p>
${(this.state.stamp===undefined||this.state.stamp===null||this.state.stamp==='')?'':`<img src="${this.state.stamp}" width="150" alt=""><br/>`}
<p>${this.state.staff_name}</p>
<p>${this.state.staff_title}</p>
<p>${this.state.remarks.replace(/\n/g, "<br />")}</p>`
			htmlstring+=`<div style="page-break-inside:avoid !important; height:100%; min-height: ${pdfheight}px; font-size: ${font_S*1.1}px;"><div style="padding:40;">${cover_html}</div></div>`
			
		}
		//field service report
		if(this.state.include_fs){

			let fsr_html=`<img src="${this.state.field_service_photo}" style="filter: gray;-webkit-filter: grayscale(1);filter: grayscale(1)" width="100%" height="100%" alt=" ">`
			htmlstring+=`<div style="page-break-inside:avoid !important; height:100%; min-height: ${pdfheight}px;">${fsr_html}</div>`
		}

		let forms=this.state.forms
		let page_number=0
		let total_page_number=0
		if(this.state.include_report)
			for(let i in forms){
				total_page_number+=1
				for(let tab in forms[i].definition){
					for (let line in forms[i].definition[tab].form){
						for (let question in forms[i].definition[tab].form[line]){
							let q= forms[i].definition[tab].form[line][question]
							switch(q.type){
								case 'pagebreak':{
									total_page_number+=1
									break;
								}
								default: {
									;
								}
							}
						}
					}
				}
			}
		if(this.state.include_report)
			for(let i in forms){
				page_number+=1
				let header_html=()=> {return `<div style="width:100%;border-bottom: 2px solid black; padding-top:10px; margin-bottom:5px;">
					<div style="text-align: right;font-size: ${font_S*1.4}px; font-weight: bold; margin-top:10px;">MGI (Far East) Ltd </div>
					<div style="text-align: right;font-size: ${font_S*1.4}px; font-weight: bold; ">MGI Projects Ltd</div>
					<div style="text-align: right;font-size: ${font_S*1.4}px; font-weight: bold; ">MGI Service Ltd</div>
					<div style="text-align: left; font-size: ${font_S*1.4}px; font-weight: bold; " >FORM NO.: ${forms[i].form_id}</div>
					<div style=" display: flex; flex-direction: row; justify-content: space-between; width:100%; ">
						<div style="text-align: left; font-size:${font_S*1.4}px; font-weight: bold; ">${forms[i].description}</div>
						<div style="text-align: right;"> (Sheet ${page_number} of ${total_page_number} Sheet${total_page_number>1?'s':''})</div>
					</div>
				</div>`}
				let questions_html=''
				for(let tab in forms[i].definition){
					for (let line in forms[i].definition[tab].form){
						let line_html=''
						for (let question in forms[i].definition[tab].form[line]){
							let q= forms[i].definition[tab].form[line][question]
							switch(q.type){
								case "project":{
									//if(q.question) line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%"><b>${q.question}</b>&nbsp;&nbsp;<p style="border-bottom: 1px solid black;display:inline;">${this.state.project}</p></div>&nbsp;&nbsp;&nbsp;&nbsp;`
									break
								}
								case "text":{
									line_html+=`<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;">${q.question==undefined?'<br/>':q.question}</div>`
									break
								}
								case "date":
								case "question_integer":
								case "question_number":
								case 'multiplechoice':
								case 'formula':
								case "question_text":{
									let html=''
									let prefix=''
									let postfix=''
									if(q.prefix!==undefined)
										prefix=`${q.prefix}&nbsp;`
									if(q.postfix!==undefined)
										postfix=`&nbsp;${q.postfix}`
									html+=`
									<div style="width:100%;padding-top:1px;display:flex;flex-direction:row;align-items:center;">
										<div style="display:inline;">${q.question?q.question+'&nbsp;&nbsp':'&nbsp;'}${prefix?`${prefix};&nbsp`:''}</div>
										<div style="flex:1;border-bottom: 1px solid black;display:inline;text-align:${q.justifyContent&&q.justifyContent[0]||'center'};margin-right:${postfix?'2px':right_margin};">${q.answer===undefined?'&nbsp;':(q.type==='date'?formatDate2(new Date(q.answer)):q.answer)}</div>
										<div style="display:inline;margin-right:${right_margin};">&nbsp;${postfix}</div>
									</div>`
									line_html+=`<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;">${html}</div>`
									break
								}
								case "stamp":{
									line_html+=`<div style="display: table-cell; position:relative; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;"><img src="${q.answer}" style="max-height:50px;position:absolute;top:-150px;left:135px;height:45px;z-index:-1;" alt=""></div>`
									//else line_html+=`<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;"></div>`
									break
								}
								case "contract_visit":{
									line_html+=`
										<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;padding-top:1px;">
											<div style="width:100%;padding-top:1px;display:flex;flex-direction:row;align-items:center;">
												Contract Visit:&nbsp;&nbsp;
												<div style="flex:1; border-bottom: 1px solid black; display:inline; text-align:center;margin-right:${right_margin};">
													${q.answer[0]===undefined ? '': q.answer[0] } 
													&nbsp;&nbsp;/&nbsp;&nbsp;
													${q.answer[1]===undefined?'':q.answer[1]}
												</div>
											</div>
										</div>`
									break
								}
								case "multipleoptions":{
									let html=''
									let choices=''
									for (let i in q.choices){
										choices+=this.renderCheckBox((q.answer?q.answer[i]:false)?q.choices[i].value:'',q.choices[i].value)
									}
									html+=`
										<div style="display:flex;width:100%;flex-direction:row;align-items:center;justify-content:flex-start;">
											${q.question}&nbsp;&nbsp;
											${choices}
										</div>`
									line_html+=`<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;padding-top:1px;">${html}</div>`
									break
								}
								case 'photo':{
									let result=await get_photos_by_photoid(this.props.control.token,q.photoid)
									let photo_base64=null
									if(result.message&&result.message=='Unauthorized'){
										this.props.dispatch(Login(false));alert('Session Expired!');return
									}
									if(result!=null&&result.result!==undefined&&result.result!=='fail'&&result.result.length>0){
										photo_base64=result.result[0].base64
									}
									if(photo_base64)line_html+=`<div style="position:relative;display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;"><div style="position:absolute;top:0;left:0;"><img src="${photo_base64}" style="width:100%;" alt="load photo error"></div></div>`
									break
								}
								case "table":{
									let table_html=''
									if(q.question) table_html+=`<div style="width:100%; align-items: center; ">${q.question}</div>`
									
									if(q.column.length>0) {
										let column_html=''
										let total_flex=0;
										for (let col in q.column){
											if(q.column[col].flex!==undefined)total_flex+=q.column[col].flex
											else total_flex+=1
										}
										if(!q.noheader){
											for (let col in q.column){
												column_html+=`<div style="position: relative; display: table-cell; text-align: ${(q.justifyContent!==undefined && q.justifyContent[col]) ? q.justifyContent[col]:'center'} ;vertical-align: middle; width:${100*q.column[col].flex/total_flex}%; min-width:${100*q.column[col].flex/total_flex}%; ">
													<div style="position:absolute; top:0;left:0;right:0;bottom:0;border-style: solid;
														border-width: ${q.mergeTop===undefined?'1px':q.mergeTop[col]?'0':'1px'}  ${q.mergeRight===undefined?'1px':q.mergeRight[col]?'0':'1px'} ${q.mergeBottom===undefined?'1px':q.mergeBottom[col]?'0':'1px'} ${q.mergeLeft===undefined?'1px':q.mergeLeft[col]?'0':'1px'};
													">
													</div>
													<div style="padding:2px">
														${q.column[col].value===''?'&nbsp;':q.column[col].value}
													</div>
												</div>`
											}
											table_html+=`<div style="display: table; table-layout: fixed; width:${100-forms[i].definition[tab].form[line].length}%; min-width:${100-forms[i].definition[tab].form[line].length}%;min-height:12px;">${column_html}</div>`
										}
										for (let z=0;z<q.noofrow;z++){
											column_html=''
											for (let col in q.column){
												
												column_html+=`
													<div style="position: relative; font-size: ${font_S}px; display: table-cell;  text-align: ${(q.justifyContent!==undefined && q.justifyContent[col]) ? q.justifyContent[col]:'left'}; vertical-align: middle; width:${100*q.column[col].flex/total_flex}%; min-width:${100*q.column[col].flex/total_flex}%; ">
														<div style="position:absolute; top:0;left:0;right:0;bottom:0;border-style: solid;
															border-width: ${q.mergeTop===undefined?'1px':q.mergeTop[col]?'0':'1px'}  ${q.mergeRight===undefined?'1px':q.mergeRight[col]?'0':'1px'} ${q.mergeBottom===undefined?'1px':q.mergeBottom[col]?'0':'1px'} ${q.mergeLeft===undefined?'1px':q.mergeLeft[col]?'0':'1px'};
														">
														</div>
														<div style="padding:2px">
															${												
																(q.column[col].type==='check'||q.column[col].type.startsWith('check_setformula'))
																? (q.answer[z][col]?
																	`<div style="display:flex;flex-direction:row;align-items:center;justify-content:center;">
																	<div style="width:7px;min-width:7px;height:7px;min-height:7px; border: 1px solid black;padding:1px;padding-top:2px;display:flex;justify-content:center;align-items:center;">
																		√
																	</div>
																</div>`
																:`<div style="display:flex;flex-direction:row;align-items:center;justify-content:center;">
																	<div style="width:7px;min-width:7px;height:7px;min-height:7px; border: 1px solid black;padding:1px;padding-top:2px;display:flex;justify-content:center;align-items:center;">
																	</div>
																</div>`)
																: (q.column[col].type==='yesno'||q.column[col].type==='yesnona'||q.column[col].type==='done'
																	||q.column[col].type==='twinsingle'||q.column[col].type==='donena'
																	||q.column[col].type==='na'||q.column[col].type==='notinstalled'||q.column[col].type==='ok'
																	||q.column[col].type==='pass'||q.column[col].type==='passfail'||q.column[col].type==='passfailna'
																	||q.column[col].type==='合格不合格不適用'||q.column[col].type==='合格不合格'||q.column[col].type==='已更換不用更換'||q.column[col].type==='已補充不用補充'||q.column[col].type==='已調整不用調整'||q.column[col].type==='已調整不用調整不適用'
																	||q.column[col].type==='yesminimalno'||['合格','不合格','不適用','已通知','完成','CW','HW','CA','VAC','N2','DI','TG','LPG','O2','CO2','H2','HE','NH3','MA4','N20','SA7','50O250N20'].includes(q.column[col].type))
																? ` <div style="display:flex;width:100%;flex-direction:row;align-items:center;justify-content:${(q.justifyContent!==undefined && q.justifyContent[col]) ? q.justifyContent[col]:'flex-start'};">
																	${q.column[col].type.includes('合格')?this.renderCheckBox(q.answer[z][col],'合格'):''}
																	${q.column[col].type.includes('不合格')?this.renderCheckBox(q.answer[z][col],'不合格'):''}
																	${q.column[col].type.includes('已更換')?this.renderCheckBox(q.answer[z][col],'已更換'):''}
																	${q.column[col].type.includes('不用更換')?this.renderCheckBox(q.answer[z][col],'不用更換'):''}
																	${q.column[col].type.includes('已補充')?this.renderCheckBox(q.answer[z][col],'已補充'):''}
																	${q.column[col].type.includes('不用補充')?this.renderCheckBox(q.answer[z][col],'不用補充'):''}
																	${q.column[col].type.includes('已調整')?this.renderCheckBox(q.answer[z][col],'已調整'):''}
																	${q.column[col].type.includes('不用調整')?this.renderCheckBox(q.answer[z][col],'不用調整'):''}
																	${q.column[col].type.includes('不適用')?this.renderCheckBox(q.answer[z][col],'不適用'):''}
																	${q.column[col].type.includes('yes')?this.renderCheckBox(q.answer[z][col],'Yes'):''}
																	${q.column[col].type.includes('twin')?this.renderCheckBox(q.answer[z][col],'Twin'):''}
																	${q.column[col].type.includes('pass')?this.renderCheckBox(q.answer[z][col],'Pass'):''}
																	${q.column[col].type.includes('ok')?this.renderCheckBox(q.answer[z][col],'OK'):''}
																	${q.column[col].type.includes('notinstalled')?this.renderCheckBox(q.answer[z][col],'notinstalled'):''}
																	${q.column[col].type.includes('done')?this.renderCheckBox(q.answer[z][col],'Done'):''}
																	${q.column[col].type.includes('fail')?this.renderCheckBox(q.answer[z][col],'Fail'):''}
																	${q.column[col].type.includes('single')?this.renderCheckBox(q.answer[z][col],'Single'):''}
																	${q.column[col].type.includes('minimal')?this.renderCheckBox(q.answer[z][col],'Minimal'):''}
																	${(q.column[col].type.includes('no')&&q.column[col].type!=='notinstalled')?this.renderCheckBox(q.answer[z][col],'No'):''}
																	${q.column[col].type.includes('na')?this.renderCheckBox(q.answer[z][col],'NA'):''}
																	${['合格','不合格','不適用','已通知','完成','CW','HW','CA','VAC','N2','DI','TG','LPG','O2','CO2','H2','HE','NH3','MA4','N20','SA7','50O250N20'].includes(q.column[col].type)
																		? this.renderCheckBox(q.answer[z][col]?q.column[col].type:'',q.column[col].type)
																		:''
																	}
																</div>`
																: q.column[col].type==='passfailcheck'
																? (q.answer[z][col]==='pass'?'√':q.answer[z][col]==='fail'?'⨉':'')
																: q.column[col].type==='okna'
																? (q.answer[z][col]==='ok'?'OK':q.answer[z][col]==='na'?'N/A':q.answer[z][col])||'&nbsp;'
																: q.answer[z][col]||'&nbsp;'														
															}
														</div>
													</div>`
											}
											table_html+=`<div style="display: table;table-layout: fixed; width:${100-forms[i].definition[tab].form[line].length}%; min-width:${100-forms[i].definition[tab].form[line].length}%;min-height:12px;">${column_html}</div>`
										}
										
									}
									line_html+=`<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%; min-width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;">${table_html}</div>`
									break
								}
								case 'pagebreak':{
									questions_html+=`<div style="display: table; width:100%;">${line_html}</div>`
									let form_html=`${header_html()}${questions_html}`
									htmlstring+=`<div style="page-break-inside:avoid !important; height:100%; min-height: ${pdfheight}px;width:100%;flex:1;font-size: ${font_S}px;"><div style="padding:40;padding-top:1;padding-bottom:1;">${form_html}</div></div>`
									questions_html='';
									line_html=''

									page_number+=1
									break;
								}
								case 'yesno':
								case 'yesnona':
								case 'passfail':
								case 'passfailna':
								case 'donena':
								case 'okna':
								case 'donena':{
									
									line_html+=`<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%; font-size: ${font_S}px; padding:1px;">
										<div style="width:100%;padding-top:1px;display:flex;flex-direction:row;align-items:center;">
											${q.question?q.question.replace(/  +/g, ' '):''}
											&nbsp;&nbsp;
											<div style="display:flex;width:100%;flex-direction:row;align-items:center;justify-content:flex-start};">
												${q.type.includes('yes')?this.renderCheckBox(q.answer,'Yes'):''}
												${q.type.includes('pass')?this.renderCheckBox(q.answer,'Pass'):''}
												${q.type.includes('ok')?this.renderCheckBox(q.answer,'OK'):''}
												${q.type.includes('notinstalled')?this.renderCheckBox(q.answer,'notinstalled'):''}
												${q.type.includes('done')?this.renderCheckBox(q.answer,'Done'):''}
												${q.type.includes('fail')?this.renderCheckBox(q.answer,'Fail'):''}
												${q.type.includes('minimal')?this.renderCheckBox(q.answer,'Minimal'):''}
												${(q.type.includes('no')&&q.type!=='notinstalled')?this.renderCheckBox(q.answer,'No'):''}
												${q.type.includes('na')?this.renderCheckBox(q.answer,'NA'):''}
											</div>
										</div>
									</div>`
									
									break;
								}
								default: {
									if(q.question) 
										line_html+=`
											<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;padding-top:1px;">
												<div style=" width:100%; padding-top:1px; display:flex; flex-direction:row;align-items:center; ">
													<p style="display:inline;">${q.question===undefined?'':q.question}&nbsp;&nbsp;&nbsp;&nbsp;</p>
													<div style="flex:1; display:inline; text-align:center;margin-right:${right_margin};">
														${q.answer===undefined?'':q.answer}
													</div>
												</div>
											</div>`
								}
							}
						}
						if(line_html.length>0)
							questions_html+=`<div style="display: table; width:100%;margin-top:0; margin-bottom:0; font-size: ${font_S}px; ">${line_html}</div>`
					}
					questions_html+=``
				}
				let form_html=`${header_html()}${questions_html}`
				let stamp_html=`<img src="${mgistamp.base64}" style="position:relative;width:40px;top:-140px;left:78%;" alt="load header error">`
				htmlstring+=`<div style="page-break-inside:avoid !important; height:100%; min-height: ${pdfheight}px; width:100%;flex:1;font-size: ${font_S}px;"><div style="padding:40;padding-top:1;padding-bottom:1;">${form_html}${stamp_html}</div></div>`
			}
		let form_ids=[]
		for (let forms of this.state.forms){
			form_ids.push(forms._id)
		}
		let pdf_api_call= await convert_to_pdf(this.props.control.token,{html:`<HTML><BODY>${htmlstring}</BODY></HTML>`,form_ids:form_ids})
		if(pdf_api_call!==undefined)
			window.open(`${this.props.control.server_ip}/pdf/temp/${pdf_api_call.result}`, "_blank")
		else alert('PDF Error!')
		this.setState({isloading:false});
	}

// 	createPDF__=async ()=> {
// 		this.setState({isloading:true})
// 		let htmlstring=''
// 		//let page=0
// 		//cover letter
// 		const pdfheight=792
// 		if(this.state.include_cover){
// 			//page+=1
// 			let cover_html=`
// <img src="${this.state.company_header==='MGI (Far East) Limited'?header.base64:header2.base64}" style="width:100%" alt="load header error">
// <p>Our Ref: ${this.state.ref.replace(/  +/g, ' ')}</p>
// <p>${formatDate2(new Date())}</p>
// <div style="display: table;width:100%;">
// 	<div style="display: table-cell; width:70%">${this.state.address.replace(/  +/g, ' ').replace(/\n/g, "<br />")}<br/></div>
// 	<div style="display: table-cell; width:30%">
// 		<div style="line-height:18px"><b>${this.state.isbyfax?'☑':'☐'} By Fax:${this.state.fax_no}</b></div>
// 		<div style="line-height:18px"><b>${this.state.isbyemail?'☑':'☐'} By Email:${this.state.email_addr}</b></div>
// 		<div style="line-height:18px"><b>${this.state.isbymail?'☑':'☐'} By Mail</b></div>
// 	</div>
// </div>
// <p>Attn: ${this.state.towhom.replace(/  +/g, ' ')}</p>
// <p>Dear Sir,</p>
// <div style="display:flex;flex-direction:row">
// 	<p><b>Re:&nbsp;&nbsp;</b></p>
// 	<p><b>${this.state.quotation_no.replace(/  +/g, ' ').replace(/\n/g, "<br />")}</b></p>
// </div>
// <p>${this.state.text_A.replace(/  +/g, ' ').replace(/\n/g, "<br />").replace('1st','1<sup>st</sup>').replace('2nd','2<sup>nd</sup>').replace('3rd','3<sup>rd</sup>')}</p>
// <p>${this.state.detail.replace(/  +/g, ' ').replace(/\n/g, "<br />")} </p>
// <p>${this.state.text_AA.replace(/  +/g, ' ').replace(/\n/g, "<br />")}</p>
// <p>${this.state.text_B.replace(/  +/g, ' ').replace(/\n/g, "<br />")}</p></br>
// <p>Yours faithfully,</p><p>${this.state.company_header.replace(/  +/g, ' ')}</p>
// ${(this.state.stamp===undefined||this.state.stamp===null)?'':`<img src="${this.state.stamp}" width="150" alt=""><br/>`}
// <p>${this.state.staff_name.replace(/  +/g, ' ')}</p>
// <p>${this.state.staff_title.replace(/  +/g, ' ')}</p>
// <p>${this.state.remarks.replace(/  +/g, ' ').replace(/\n/g, "<br />")}</p>`
// 			htmlstring+=`<div style="height:100%;font-size:14px;"><div style="padding:60;">${cover_html}</div></div>`
// 		}
// 		//field service report
// 		if(this.state.include_fs){
// 			//page+=1
// 			let fsr_html=`<img src="${this.state.field_service_photo}" style="filter: gray;-webkit-filter: grayscale(1);filter: grayscale(1)" width="100%" height="100%" alt="no field service report">`
// 			htmlstring+=`<div class="pagebreak"></div><div style="height:100%; min-height: ${pdfheight}px;">${fsr_html}</div>`
// 		}

// 		let forms=this.state.forms
// 		let page_number=0
// 		let total_page_number=0
// 		if(this.state.include_report)
// 			for(let i in forms){
// 				total_page_number+=1
// 				for(let tab in forms[i].definition){
// 					for (let line in forms[i].definition[tab].form){
// 						for (let question in forms[i].definition[tab].form[line]){
// 							let q= forms[i].definition[tab].form[line][question]
// 							switch(q.type){
// 								case 'pagebreak':{
// 									total_page_number+=1
// 									break;
// 								}
// 								default: {
// 									;
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}

// 		if(this.state.include_report)
// 			for(let i in forms){
// 				//page+=1
// 				page_number+=1
// 				let header_html=()=>{return `<div style="width:100%;border-bottom: 3px solid black;">
// 					<div style="text-align: right;font-size:17px;line-height: 4px; margin-top: 3px; "><br/>MGI (Far East) Ltd</div><br/>
// 					<div style="text-align: right;font-size:17px;line-height: 4px;"><br/>MGI Projects Ltd</div><br/>
// 					<div style="text-align: right;font-size:17px;line-height: 4px;"><br/>MGI Service Ltd</div><br/>
// 					<div style="text-align: left;font-size:22px;font-weight:bold;line-height: 5px;"><br/>FORM NO.: ${forms[i].form_id.replace(/  +/g, ' ')}</div><br/>
					
// 					<div style=" display: flex; flex-direction: row; justify-content: space-between; width:100%; ">
// 						<div style="text-align: left;font-size:22px;font-weight:bold; width:80%;">${forms[i].description.replace(/  +/g, ' ')}</div>
// 						<div style=" font-size:14px; "> (Sheet ${page_number} of ${total_page_number} Sheet${total_page_number>1?'s':''})</div>
// 					</div>
// 				</div>`}
// 				let questions_html=''
// 				for(let tab in forms[i].definition){
// 					for (let line in forms[i].definition[tab].form){
// 						let line_html=''
// 						for (let question in forms[i].definition[tab].form[line]){
// 							let q= forms[i].definition[tab].form[line][question]
// 							switch(q.type){
// 								case "project":{
// 									;//if(q.question) line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%"><pre><b>${q.question}</b>&nbsp;&nbsp;<pre style="border-bottom: 1px solid black;display:inline;">${this.state.project}</pre></pre></div>&nbsp;&nbsp;&nbsp;&nbsp;`
// 									break
// 								}
// 								case "text":{
// 									line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%; max-width:${100/forms[i].definition[tab].form[line].length}%; min-width:${100/forms[i].definition[tab].form[line].length}%;"><b>${q.question===undefined?'':`${q.question}`.replace(/  +/g, ' ')} </b></div>&nbsp;&nbsp;&nbsp;&nbsp;`
// 									break
// 								}
// 								case "question_integer":
// 								case "question_number":
// 								case "question_text":{
// 									let html=''
// 									let prefix=''
// 									let postfix=''
// 									if(q.prefix!==undefined)
// 										prefix=`${q.prefix}&nbsp;`
// 									if(q.postfix!==undefined)
// 										postfix=`&nbsp;${q.postfix}`
// 									html+=`
// 									<div style="width:100%; max-width:100%; min-width:100%;">
// 										<p style="display:inline;"><b>${q.question?q.question.replace(/  +/g, ' ')+'&nbsp;&nbsp':''}</b>${prefix.replace(/  +/g, ' ')}</p>
// 										<b><div style="min-width:100px;text-decoration:underline;display:inline;">${q.answer===undefined?'':q.answer.replace(/  +/g, ' ')}</div></b>
// 										<p style="display:inline;">&nbsp;${postfix.replace(/  +/g, ' ')}</p>
// 									</div>`
// 									line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%">${html}</div>`
// 									break
// 								}
// 								case "stamp":{
// 									if(q.answer) line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%"><img src="${q.answer}" width="120px" alt="no stamp"><br/></div>`
// 									else line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%"><br/></div>`
// 									break
// 								}
// 								case "date":{
// 									line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%">${q.question===undefined?'Date:&nbsp;':q.question+'&nbsp;'}${(q.answer!==undefined)?formatDate2(new Date(q.answer)):''}</div>`
// 									break;
// 								}
// 								case "contract_visit":{
// 									if(q.answer) line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%">Contract Visit:&nbsp;&nbsp;${q.answer[0]===undefined?'':q.answer[0]}/${q.answer[1]===undefined?'':q.answer[1]}</div>`
// 									else  line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%">Contract Visit:&nbsp;&nbsp;}/</div>`
// 									break
// 								}
// 								case "multipleoptions":{
// 									let html=''
// 									let choices=''
// 									for (let i in q.answer){
// 										if(q.answer[i]) choices+=`${q.choices[i].value} `
// 									}
// 									if(choices==='')choices='Nil'
// 									html+=`<p><b>${q.question} </b> ${choices}</p>`
// 									line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%">${html}</div>`
// 									break
// 								}
// 								case 'photo':{
// 									let result=await get_photos_by_photoid(this.props.control.token,q.photoid)
// 									let photo_base64=null
// 									if(result.message&&result.message==='Unauthorized'){
// 										this.props.dispatch(Login(false));alert('Session Expired!');return
// 									}
// 									if(result!=null&&result.result!==undefined&&result.result!=='fail'&&result.result.length>0){
// 										photo_base64=result.result[0].base64
// 									}
// 									if(photo_base64)line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%"><img src="${photo_base64}" style="width:100%" alt="load photo error"></div>`
// 									break
// 								}
// 								case "table":{
// 									let table_html=''
// 									if(q.question) table_html+=`<div style="width:100%; align-items: center;"><b>${q.question}</b></div>`
									
// 									if(q.column.length>0) {
// 										let column_html=''
// 										let total_flex=0;
// 										for (let col in q.column){
// 											if(q.column[col].flex!==undefined)total_flex+=q.column[col].flex
// 											else total_flex+=1
// 										}
// 										if(!q.noheader){
// 											let accumulate_width=0
// 											for (let col in q.column){
// 												column_html+=`<div style="position: relative; text-align: center; vertical-align: middle; width: ${100*q.column[col].flex/total_flex}% ;margin-bottom: -100px; padding-bottom: 100px; ">
// 													<div style="
// 	-webkit-box-sizing: border-box !important;
// 	-moz-box-sizing: border-box !important;
// 	-ms-box-sizing: border-box !important;
// 	box-sizing: border-box !important;
// 	border-style: solid;border-width: ${q.mergeTop===undefined?'1px':q.mergeTop==col?'0':'1px'} 1px ${q.mergeBottom===undefined?'1px':q.mergeBottom==col?'0':'1px'};
// 	height: 100%;
// 	vertical-align: middle;
// 	padding: 1px;
// 	">
// 														<b>${q.column[col].value} </b>
// 													</div>
// 												</div>`
// 												accumulate_width+=100*q.column[col].flex/total_flex
// 											}
// 											table_html+=`<div style="overflow: hidden;  display: flex;  flex-direction: row; width: 100%;min-width: 100%;max-width: 100%;">${column_html}</div>`
// 										}
// 										for (let i=0;i<q.noofrow;i++){
// 											column_html=''
// 											let accumulate_width=0
// 											for (let col in q.column){
// 												column_html+=`<div style="position: relative; text-align: left;vertical-align: middle; width: ${100*q.column[col].flex/total_flex}%; margin-bottom: -100px; padding-bottom: 100px;">
// 													<div style="
// 	-webkit-box-sizing: border-box !important;
// 	-moz-box-sizing: border-box !important;
// 	-ms-box-sizing: border-box !important;
// 	box-sizing: border-box !important;
// 	border-style: solid;border-width: ${q.mergeTop===undefined?'1px':q.mergeTop==col?'0':'1px'} 1px ${q.mergeBottom===undefined?'1px':q.mergeBottom==col?'0':'1px'};
// 	height: 100%;
// 	vertical-align: middle;
// 	padding: 1px;
// 	">
// 														${
// 															(q.answer[i][col]===undefined||q.answer[i][col]===null)
// 															? ''
// 															: (q.column[col].type==='check'||q.column[col].type.startsWith('check_setformula'))
// 															? (q.answer[i][col]?'√':'')
// 															: q.answer[i][col]==='yes'
// 															? 'Yes'
// 															: q.answer[i][col]==='no'
// 															? 'No'
// 															: q.answer[i][col]==='na'
// 															? 'N/A'
// 															: q.answer[i][col]==='pass'
// 															? 'Pass'
// 															: q.answer[i][col]==='fail'
// 															? 'Fail'
// 															: q.answer[i][col]==='done'
// 															? 'Done'
// 															: q.answer[i][col]==='ok'
// 															? 'OK'
// 															: q.answer[i][col]==='minimal'
// 															? 'Minimal'
// 															: `${q.answer[i][col]}`.replace(new RegExp("\\-","g"),'&minus;')

// 														}
// 													</div>	
// 												</div>`
// 												accumulate_width+=100*q.column[col].flex/total_flex
// 											}
// 											table_html+=`<div style="overflow: hidden; display: flex;  flex-direction: row; width: 100%;min-width: 100%;max-width: 100%;">${column_html}</div>`
// 										}
										
// 									}
// 									line_html+=`<div style="overflow: hidden; display: flex; flex-direction:column ;  table-layout: fixed;width: ${100/forms[i].definition[tab].form[line].length}%;min-width: ${100/forms[i].definition[tab].form[line].length}%;max-width: ${100/forms[i].definition[tab].form[line].length}%;">${table_html}</div>`
// 									break
// 								}
// 								case 'pagebreak':{
// 									//page+=1
// 									questions_html+=`<div style=" display: table; table-layout: fixed;width:100%;">${line_html}</div>`
// 									let form_html=`${header_html()}${questions_html}`
// 									htmlstring+=`<div class="pagebreak"></div><div style="font-size:10px;"><div style="">${form_html}</div></div>`
// 									questions_html='';
// 									line_html=''
// 									page_number+=1
// 									break;
// 								}
// 								case 'yesno':
// 								case 'yesnona':
// 								case 'passfail':
// 								case 'passfailna':
// 								case 'donena':
// 								case 'okna':
// 								case 'donena':{
// 									if(q.question) 
// 										line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%; ">
// 											<b>${q.question===undefined?'':q.question.replace(/  +/g, ' ')}</b>
// 											&nbsp;&nbsp;
// 											${
// 												q.answer===undefined
// 												? ''
// 												: q.answer==='yes'
// 												? 'Yes'
// 												: q.answer==='no'
// 												? 'No'
// 												: q.answer==='na'
// 												? 'N/A'
// 												: q.answer==='pass'
// 												? 'Pass'
// 												: q.answer==='fail'
// 												? 'Fail'
// 												: q.answer==='done'
// 												? 'Done'
// 												: q.answer==='ok'
// 												? 'OK'
// 												:`${q.answer}`.replace(/  +/g, ' ')
// 											}
// 										</div>&nbsp;&nbsp;&nbsp;&nbsp;`
// 									break;
// 								}
// 								default: {
// 									if(q.question) line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%; "><b>${q.question===undefined?'':`${q.question}`.replace(/  +/g, ' ')}</b>&nbsp;&nbsp;${q.answer===undefined?'':`${q.answer}`.replace(/  +/g, ' ')}</div>&nbsp;&nbsp;&nbsp;&nbsp;`
// 								}
// 							}
// 						}
// 						if(line_html.length>0)
// 							questions_html+=`<div style=" overflow: hidden; display: flex; flex-direction:row ; table-layout: fixed; width:100%;">${line_html}</div>`
// 					}
// 					questions_html+=`<br/><br/>`
// 				}
// 				let form_html=`${header_html()}${questions_html}`
// 				let stamp_html=`<img src="${mgistamp.base64}" style="position:relative;width:40px;top:${mgistamp.base64?'-200':'-50'}-200px;left:75%" alt="load header error">`
// 				htmlstring+=`<div class="pagebreak"></div><div style="font-size:10px;"><div style="">${form_html}${stamp_html}</div></div>`
// 			}
			
// 		var opt = {
// 		  margin:       .5,
// 		  pagebreak: {mode: '',after:'.pagebreak'},
// 		  filename:     'MGI_report.pdf',
// 		  image:        { type: 'jpeg', quality: 1 },
// 		  html2canvas:  { scale: 2 },
// 		  jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' }
// 		};

// 		try{
// 			//var worker = 
// 			html2pdf().set(opt).from(`
// <html>
// 	<head>
// 	    <title>html2pdf Test - Pagebreaks</title>
// 	    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

// 	    <style type="text/css">
// 	      .pagebreak {
// 	        page-break-after: always;
// 	      }
// 	    </style>
// 	</head>
//      <body>${htmlstring}</body>
// </html>`).save()
// 			// let file = await Print.printToFileAsync(options)
// 			// //alert('pages: '+file.numberOfPages)
// 			// this.remove_extra_pages(file.base64,page)
// 			// //this.setState({isPDFmode:true,pdfurl:`data:application/pdf;base64,${file.base64}`})
// 		}catch(err){
// 			alert('error: '+err)
// 		}
// 		this.setState({isloading:false});
// 	}

	// remove_extra_pages=async (base64,num)=>{
	// 	//let str=this.props.url.split('data:application/pdf;base64,')[1]
	// 	let pdfBuffer=Buffer.from(base64, 'base64');
	// 	const ori_pdfDoc = await PDFDocument.load(pdfBuffer);
	// 	const pdfDoc = await PDFDocument.create();
	// 	for (let i=0;i<num;i++){
	// 		const [Page] = await pdfDoc.copyPages(ori_pdfDoc, [i])
	// 		pdfDoc.addPage(Page)
	// 	}

	// 	const pdfBytes = await pdfDoc.save()
	// 	let base=binaryToBase64(pdfBytes);
	// 	this.setState({isPDFmode:true,pdfurl:`data:application/pdf;base64,${base}`})

	// }
	set_text_A(TYPE){
		if(TYPE==='Type 1')
			this.setState({
				text_A:`Reference is made to the captioned maintenance services. We would like to report to you that the 2nd maintenance service has been carried out on [Date]. The installed equipment under this maintenance service contract has been checked/ adjusted/ regulated and are now in normal operating condition with following particulates: `,
				text_AA:'Enclosed please find the Service Report for your reference/ record.',
				text_B:`If you have any query, please feel free to contact our Mr. Kwai Chan at 3913 0243/ 5547 6197.`,
				text_C:'',
				text_D:'',
				text_E:'',
			});
		else if(TYPE==='Type 2') 
			this.setState({
				text_A:`Reference is made to the captioned maintenance services. We would like to report to you that the 1st maintenance service has been carried out on [Date]. The installed equipment under this maintenance service contract has been checked/ adjusted/ regulated and are now in normal operation condition.`,
				text_AA:'Enclosed please find the Service Report for your reference/ record.',
				text_B:`If you have any query, please feel free to contact the undersigned at 5547 7591 or Ms Kinki Lam at 5547 7062.`,
				text_C:'',
				text_D:'',
				text_E:'',
			});
		else this.setState({
				text_A:`Reference is made to the captioned maintenance services. We would like to report to you that the 1st maintenance service has been carried out on [Date]. The installed equipment under this maintenance service contract has been checked/ adjusted/ regulated and are now in normal operation condition.`,
				text_AA:'Enclosed please find the Service Report for your reference/ record.',
				text_B:`If you have any query, please feel free to contact the undersigned at 5547 7591 or Ms Kinki Lam at 5547 7062.`,
				text_C:'Page 3 Text',
				text_D:'Page 4 Text',
				text_E:'Page 5 Text',
			});
	}
	saveCover=async ()=>{
		if(this.state.ref===''||this.state.ref===undefined||this.state.ref===null){
			alert('Refernce No. is empty');return
		}else {
			this.update_cover_(this.state.ref)
		}

	}
	loadCover=async ()=>{
		if(this.state.ref===''||this.state.ref===undefined||this.state.ref===null){
			alert('Refernce No. is empty');return
		}else {
			for (let cover of this.state.cover_list){
				if (cover.cover_id===this.state.ref){
					this.setState({
						cover_type:cover.cover_type,
						company_header:cover.cover_header,
						...cover.cover_content,
					})
					return
				}
			}
			alert(`Cover with refernece number ${this.state.ref} not found`)
		}
	}
	selectCover=async ()=>{
		this.setState({isSelectCoverDraft:true})
	}

	update_cover_ =async (cover_id,force=false)=>{
		if(cover_id===''||cover_id===undefined||cover_id===null||cover_id.length<1){alert('Refernce No. is empty');return;}
		else if(this.state.project_id===''||this.state.project_id===undefined||this.state.project_id===null){alert('project_id is empty');return;}
		else if(this.state.isloading&&!force)return
		this.setState({isloading:true})
		let data={
			cover_id:cover_id,
			project_id:this.state.project_id,
			cover_type:this.state.cover_type,
			cover_header:this.state.company_header,
			cover_content:{
				ref:this.state.ref,
				//towhom:this.state.towhom,
				quotation_no:this.state.quotation_no,
				detail:this.state.detail,
				stamp:this.state.stamp,
				staff_name:this.state.staff_name,
				staff_title:this.state.staff_title,
				remarks:this.state.remarks,

				isbyfax:this.state.isbyfax,
				isbyemail:this.state.isbyemail,
				isbymail:this.state.isbymail,
				fax_no:this.state.fax_no,email_addr:this.state.email_addr,
				text_A:this.state.text_A,
				text_AA:this.state.text_AA||'',
				text_B:this.state.text_B,
				text_C:this.state.text_C||'',
				text_D:this.state.text_D||'',
				text_E:this.state.text_E||'',
			},
		}
		let result= await update_cover(this.props.control.token,data)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert("Session Expired!");return
		}else if(result===undefined||result.errno!=0){
			alert(result.message||'Save error');this.setState({isloading:false});return
		}else{
			this.get_covers_by_project_id_(this.state.project_id,true)
		}
	}
	handleLoadCover=()=>{
		if(this.state.cover_index>=this.state.cover_list.length){
			alert(`Error, cover not found`);
			return
		}
		let cover = this.state.cover_list[this.state.cover_index]
		this.setState({
			cover_type:cover.cover_type,
			company_header:cover.cover_header,
			...cover.cover_content,
			isSelectCoverDraft:false
		})
		
	}
	deleteCover=async ()=>{
		if(this.state.cover_index>=this.state.cover_list.length){
			alert(`Error, cover not found`);
			return
		}else if(this.state.isloading)return
		this.setState({isloading:true})
		let result= await delete_cover(this.props.control.token,this.state.cover_list[this.state.cover_index]._id)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert("Session Expired!");return
		}else if(result===undefined||result.errno!=0){
			alert(result.message||'Delete error');this.setState({isloading:false});return
		}else{
			this.get_covers_by_project_id_(this.state.project_id,true)
		}

	}

	handleMoveForm=(index)=>()=>{
		let current_index=this.state.tab_index
		let forms=this.state.forms
		if(current_index<2)return
		if(current_index===2&&index===-1){return}
		if(current_index-2===this.state.forms.length-1&&index===1)return
		var temp = forms[current_index-2];
		forms[current_index-2] = forms[current_index+index-2];
		forms[current_index+index-2] = temp;
		this.setState({
			forms:forms,
			tab_index:current_index+index
		})
	}

	
	render() {
		let tab_width=window.innerWidth*.9
		let tabs=this.state.forms
		if(this.state.isPDFmode)
			return(<ViewPDFPage url={this.state.pdfurl} 
				generate={()=>{this.create_reports_()}}
				return={()=>{this.setState({isPDFmode:false})}}
			/>)
		return(
			<div style={{width:'100%',height:'100%',overflowY: 'scroll',}}>
				<div className='center_row'>
					<div className='mousehover center_row' style={{padding:10,width:'20%'}} onClick={()=>this.props.dispatch(setpageindex(10))}>
						<img src={back_icon} style={{width:17,height:17}} alt='back'/>
						<div style={{marginLeft:10, fontSize:25,color:'white',fontWeight:'bold'}}>Back</div>
					</div>
					<div  style={{width:'60%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
						<div className='center_row' style={{opacity:.3}}>
							<img src={preview_icon} style={{width:17,height:17}} alt='preview'/>
							<div style={{color:'white',fontWeight:'bold',fontSize:20,marginLeft:20}}>REPORT GENERATOR</div>
						</div>
						<div style={{color:"white",fontSize: 22,fontWeight: "bold",marginTop:10}}>{this.state.ref}</div>
						<div style={{color:"#00DAC3",fontSize: 14,fontWeight: "bold",marginTop:10}}>{this.state.description}</div>
					
					</div>
					<div style={{display:'flex',width:'25%',padding:10,justifyContent:'flex-end'}}>
						<div className='mousehover center_row' style={{width:130,padding:10,backgroundColor:'rgba(37, 229, 154, 1.0)',borderRadius:5,marginRight:10,}} onClick={this.createPDF}>
							<img src={complete_icon} style={{width:17,height:17}} alt='complete'/>
							<div style={{color: "white",fontSize: 20,fontWeight:"bold",marginLeft:10}}>Preview</div>
						</div>
					</div>
				</div>

				<div style={{display:'flex',flexDirection:'row',minHeight:60,width:'100%',alignItems:'flex-end',justifyContent:'center',marginTop:10}}>
					<div className='mousehover' style={{display:'flex',alignItems:'center',justifyContent:'center',height:'100%',marginLeft:3,borderTopLeftRadius:10,borderTopRightRadius:10,
						backgroundColor:this.state.tab_index===0?'#44CDD7':'rgba(0, 66, 113, 1.0)',
						width:this.state.tab_index===0?((tab_width-100)/(tabs.length+2)+100):((tab_width-100)/(tabs.length+2))
					}} onClick={()=>{
						if(this.state.tab_index===0)this.setState({include_cover:!this.state.include_cover})
						else this.setState({tab_index:0})
					}}>
						<div style={{display:'flex',height:'50%',justifyContent:'center',alignItems:'center'}}>
							<div style={{display:'flex',marginLeft:5,marginRight:5,width:26,height:26,borderRadius:13,border:'1px solid rgba(125,218,255,.7)',justifyContent:'center',alignItems:'center'}} >
								<div style={{width:20,height:20,borderRadius:10,backgroundColor:this.state.include_cover?'rgba(125, 218, 255, 1.0)':'transparent'}}></div>
							</div>
						</div>
						<div style={{display:'flex',paddingTop:10,paddingBottom:10,alignItems:'center',textAlign:'center',fontSize: 25,fontWeight: "bold",height:'100%',color:"#0052FF",marginLeft:20,marginRight:20}}>Cover</div>
					</div>
					<div className='mousehover' style={{display:'flex',alignItems:'center',justifyContent:'center',height:'100%',marginLeft:3,borderTopLeftRadius:10,borderTopRightRadius:10,
						backgroundColor:this.state.tab_index===1?'#44CDD7':'rgba(0, 66, 113, 1.0)',
						width:this.state.tab_index===1?((tab_width-100)/(tabs.length+2)+100):((tab_width-100)/(tabs.length+2))
					}} onClick={()=>{
						if(this.state.tab_index===1)this.setState({include_fs:!this.state.include_fs})
						else this.setState({tab_index:1})
					}}>
						<div style={{display:'flex',height:'50%',justifyContent:'center',alignItems:'center'}}>
							<div style={{display:'flex',marginLeft:5,marginRight:5,width:26,height:26,borderRadius:13,border:'1px solid rgba(125,218,255,.7)',justifyContent:'center',alignItems:'center'}} >
								<div style={{width:20,height:20,borderRadius:10,backgroundColor:this.state.include_fs?'rgba(125, 218, 255, 1.0)':'transparent'}}></div>
							</div>
						</div>
						<div style={{display:'flex',paddingTop:10,paddingBottom:10,alignItems:'center',textAlign:'center',fontSize: 25,fontWeight: "bold",height:'100%',color:"#0052FF",marginLeft:20,marginRight:20}}>Field Service Report</div>
					</div>
					{this.state.forms.map((item,index)=>(
						<div key={index} className='mousehover' style={{display:'flex',alignItems:'center',justifyContent:'center', height:'100%',marginLeft:3,borderTopLeftRadius:10,borderTopRightRadius:10 ,
							backgroundColor:this.state.tab_index===index+2?'#44CDD7':'rgba(0, 66, 113, 1.0)',
							width:this.state.tab_index===index+2?((tab_width-100)/(tabs.length+2)+100):((tab_width-100)/(tabs.length+2))
						}} onClick={()=>{
							if(this.state.tab_index===(index+2))this.setState({include_report:!this.state.include_report})
							else this.setState({tab_index:index+2})
						}}>
							<div style={{display:'flex',height:'50%',justifyContent:'center',alignItems:'center'}}>
								<div style={{display:'flex',marginLeft:5,marginRight:5,width:26,height:26,borderRadius:13,border:'1px solid rgba(125,218,255,.7)',justifyContent:'center',alignItems:'center'}} >
									<div style={{width:20,height:20,borderRadius:10,backgroundColor:this.state.include_report?'rgba(125, 218, 255, 1.0)':'transparent'}}></div>
								</div>
							</div>
							<div style={{display:'flex',paddingTop:10,paddingBottom:10,alignItems:'center',textAlign:'center',fontSize: 25,fontWeight: "bold",height:'100%',color:this.state.tab_index===index+2?"#0052FF":'rgba(125, 218, 255, 1.0)',marginLeft:20,marginRight:20}}>{item.form_id}</div>
						</div>
					))}
				</div>
				<div style={{height:'80%',width:'100%',overflowY: 'scroll',backgroundColor:'rgba(5, 102, 141, 1.0)'}}>
					{this.state.tab_index!==0||<div style={{width:'100%'}}>
						<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
							<div style={{padding:20,flex:1,marginBottom:20}}>
								<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>Cover Letter Type:</div>
								<Dropdown
									controlClassName='dropdown_control'
									placeholderClassName='dropdown_placeholder'
									options={[{value:'Type 1'},{value:'Type 2'},{value:'2 Pages'},{value:'3 Pages'},{value:'4 Pages'},{value:'5 Pages'}]} onChange={(e)=>{
										let value=e.value
										this.setState({cover_type:value})
										this.set_text_A(value)
									}} value={`-${this.state.cover_type}`} placeholder="Select an option"
								/>
							</div>
							<div style={{padding:20,flex:1,marginBottom:20}}>
								<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>Letter Header::</div>
								<Dropdown
									controlClassName='dropdown_control'
									placeholderClassName='dropdown_placeholder'
									options={[{value:'MGI (Far East) Limited'},{value:'MGI Service Limited'}]} onChange={(e)=>{
										let value=e.value
										this.setState({company_header:value})
									}} value={`${this.state.company_header} `} placeholder="Select an option"
								/>
							</div>
						</div>

						<div style={{display:'flex',marginLeft:20,marginTop:30,flexDirection:'row'}}>
							<div style={{color:'white',fontSize:18}}>Our Ref:</div>
							<input style={{ backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',borderBottom:"1px solid rgba(125, 218, 255, 1.0)", color:'rgba(125, 218, 255, 1.0)',width:300,fontSize: 18,marginLeft:5,marginTop:2}} value={this.state.ref} onChange={(e)=>{this.setState({ref:e.target.value})}} placeholder="Reference No."/>
							<div className='mousehover center_row' style={{marginLeft:15,padding:5,backgroundColor:'rgba(37, 209, 154, 1.0)',borderRadius:5}} onClick={this.saveCover}>
								<img src={save_icon} style={{width:15,height:15}} alt='save'/>
								<div style={{color: "white",fontSize: 16,fontWeight:"bold",marginLeft:5}}>Save</div>
							</div>
							<div className='mousehover center_row' style={{marginLeft:2,padding:5,backgroundColor:'rgba(57, 154, 230, 1.0)',borderRadius:5}} onClick={this.loadCover}>
								<img src={preview_icon} style={{width:15,height:15}} alt='load'/>
								<div style={{color: "white",fontSize: 16,fontWeight:"bold",marginLeft:5}}>Load</div>
							</div>
							<div className='mousehover center_row' style={{marginLeft:2,padding:5,backgroundColor:'rgba(150, 154, 230, 1.0)',borderRadius:5}} onClick={this.selectCover}>
								<img src={select_icon} style={{width:15,height:15}} alt='select'/>
								<div style={{color: "white",fontSize: 16,fontWeight:"bold",marginLeft:5}}>Select</div>
							</div>
						</div>
						
						<div style={{marginLeft:20,marginTop:30}}>
							<div style={{color:'white',fontSize:18}}>{formatDate2(new Date())}</div>
						</div>
						<div style={{display:'flex',marginLeft:20,marginTop:20,flexDirection:'row'}}>
							<div style={{color:'white',fontSize:18,flex:1,whiteSpace: 'pre-line'}}>{this.state.address}</div>
							<div style={{flex:1}}>
								<div style={{display:'flex',flexDirection:'row',margin:2}} >
									<div className='mousehover' onClick={()=>{this.setState({isbyfax:!this.state.isbyfax})}} style={{marginLeft:5,marginRight:5,width:26,height:26,borderRadius:13,border:'1px solid rgba(125,218,255,.7)',display:'flex',justifyContent:'center',alignItems:'center'}}>
										<div style={{width:16,height:16,borderRadius:8,backgroundColor:this.state.isbyfax?'rgba(0, 218, 195, 1.0)':'transparent'}}></div>
									</div>
									<div style={{color:'white',fontSize:18,width:110}}> By Fax </div>
									<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:18,width:170,borderBottom:'1px solid rgba(125, 218, 255, 1.0)',}} onChange={(e)=>{this.setState({fax_no:e.target.value})}} value={this.state.fax_no} placeholder="fax no."/>
								</div>
								<div style={{display:'flex',flexDirection:'row',margin:2}}>
									<div className='mousehover' onClick={()=>{this.setState({isbyemail:!this.state.isbyemail})}} style={{marginLeft:5,marginRight:5,width:26,height:26,borderRadius:13,border:'1px solid rgba(125,218,255,.7)',display:'flex',justifyContent:'center',alignItems:'center'}}>
										<div style={{width:16,height:16,borderRadius:8,backgroundColor:this.state.isbyemail?'rgba(0, 218, 195, 1.0)':'transparent'}}></div>
									</div>
									<div style={{color:'white',fontSize:18,width:110}}> By E-Mail </div>
									<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:18,width:170,borderBottom:'1px solid rgba(125, 218, 255, 1.0)'}} onChange={(e)=>{this.setState({email_addr:e.target.value})}} value={this.state.email_addr} placeholder="email"/>
								</div>
								<div style={{display:'flex',flexDirection:'row',margin:2}} >
									<div className='mousehover' onClick={()=>{this.setState({isbymail:!this.state.isbymail})}} style={{marginLeft:5,marginRight:5,width:26,height:26,borderRadius:13,border:'1px solid rgba(125,218,255,.7)',display:'flex',justifyContent:'center',alignItems:'center'}}>
										<div style={{width:16,height:16,borderRadius:8,backgroundColor:this.state.isbymail?'rgba(0, 218, 195, 1.0)':'transparent'}}></div>
									</div>
									<div style={{color:'white',fontSize:18}}> By Mail </div>
								</div>
							</div>
						</div>
						<div style={{display:'flex',marginLeft:20,marginTop:30,flexDirection:'row'}}>
							<div style={{color:'white',fontSize:18,}}>Attn:</div>
							<div style={{color:'rgba(125, 218, 255, 1.0)',fontSize:18}}>{this.state.towhom}</div>
						</div>
						<div style={{display:'flex',marginLeft:20,marginTop:30,flexDirection:'row'}}>
							<div style={{color:'white',fontSize:18}}>Dear Sir,</div>
						</div>
						<div style={{display:'flex',marginLeft:20,marginTop:30,flexDirection:'row'}}>
							<div style={{color:'white',fontSize:18,fontWeight:'bold'}}>Re:</div>
							<textarea style={{width:"100%",height:100,backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:18,borderBottom:'1px solid rgba(125, 218, 255, 1.0)',}}
							onChange={(e)=>{this.setState({quotation_no:e.target.value})}} value={this.state.quotation_no} placeholder="Quotation No."></textarea>

						</div>
						<div style={{display:'flex',marginLeft:20,marginTop:30,flexDirection:'row',marginRight:20}}>
							<textarea style={{width:"100%",height:100,backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:18,borderBottom:'1px solid rgba(125, 218, 255, 1.0)',}}
							onChange={(e)=>{this.setState({text_A:e.target.value})}} value={this.state.text_A} placeholder="text"></textarea>
						</div>
						<div style={{marginLeft:20,marginTop:30,marginRight:20}}>
							<textarea
							style={{width:"100%",height:100,backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:18,borderBottom:'1px solid rgba(125, 218, 255, 1.0)',}} 
							onChange={(e)=>{this.setState({detail:e.target.value})}} 
							value={this.state.detail}  placeholder="details"></textarea>
						</div>
						<div style={{display:'flex',marginLeft:20,marginTop:30,flexDirection:'row',marginRight:20}}>
							<textarea style={{width:"100%",height:100,backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:18,borderBottom:'1px solid rgba(125, 218, 255, 1.0)',}}
							onChange={(e)=>{this.setState({text_AA:e.target.value})}} value={this.state.text_AA} placeholder="text"></textarea>
						</div>
						
						{(this.state.cover_type==="2 Pages"||this.state.cover_type==="3 Pages"||this.state.cover_type==="4 Pages"||this.state.cover_type==="5 Pages")&&
							<div style={{width:'100%',color:'grey',borderBottom:'1px dashed grey',marginTop:5}}>
								Page 2 starts here
							</div>
						}
						<div style={{marginLeft:20,marginTop:30,marginRight:20}}>
							<textarea style={{width:"100%",height:100,backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:18,borderBottom:'1px solid rgba(125, 218, 255, 1.0)',}} 
							onChange={(e)=>{this.setState({text_B:e.target.value})}} value={this.state.text_B} placeholder="text"></textarea>
						</div>
						{(this.state.cover_type==="3 Pages"||this.state.cover_type==="4 Pages"||this.state.cover_type==="5 Pages")&&
							<div style={{width:'100%'}}>
								<div style={{width:'100%',color:'grey',borderBottom:'1px dashed grey',marginTop:5}}>
									Page 3 starts here
								</div>
								<div style={{marginLeft:20,marginTop:30,marginRight:20}}>
									<textarea style={{width:"100%",height:100,backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:18,borderBottom:'1px solid rgba(125, 218, 255, 1.0)',}} 
									onChange={(e)=>{this.setState({text_C:e.target.value})}} value={this.state.text_C||''} placeholder="Page 3 Text"></textarea>
								</div>
							</div>
						}
						{(this.state.cover_type==="4 Pages"||this.state.cover_type==="5 Pages")&&
							<div style={{width:'100%'}}>
								<div style={{width:'100%',color:'grey',borderBottom:'1px dashed grey',marginTop:5}}>
									Page 4 starts here
								</div>
								<div style={{marginLeft:20,marginTop:30,marginRight:20}}>
									<textarea style={{width:"100%",height:100,backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:18,borderBottom:'1px solid rgba(125, 218, 255, 1.0)',}} 
									onChange={(e)=>{this.setState({text_D:e.target.value})}} value={this.state.text_D||''} placeholder="Page 4 Text"></textarea>
								</div>
							</div>
						}
						{(this.state.cover_type==="5 Pages")&&
							<div style={{width:'100%'}}>
								<div style={{width:'100%',color:'grey',borderBottom:'1px dashed grey',marginTop:5}}>
									Page 5 starts here
								</div>
								<div style={{marginLeft:20,marginTop:30,marginRight:20}}>
									<textarea style={{width:"100%",height:100,backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:18,borderBottom:'1px solid rgba(125, 218, 255, 1.0)',}} 
									onChange={(e)=>{this.setState({text_E:e.target.value})}} value={this.state.text_E||''} placeholder="Page 5 Text"></textarea>
								</div>
							</div>
						}
						<div style={{marginLeft:20,marginTop:30,marginRight:20}}>
							<div style={{color:'white',fontSize:18}}>Yours faithfully, </div>
							<div style={{color:'white',fontSize:18}}>{this.state.company_header}</div>
						</div>

						<div style={{width:'100%', flexDirection:'row'}}>
							<StampView photo={this.state.stamp} setphoto={(stamp)=>{this.setState({stamp})}}/>
							<div style={{flex:1}}></div>
						</div>

						<div style={{marginLeft:20,marginTop:30,marginRight:20}}>
							<input style={{width:"100%",backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:18,borderBottom:'1px solid rgba(125, 218, 255, 1.0)'}} 
							onChange={(e)=>{this.setState({staff_name:e.target.value})}} value={this.state.staff_name}  placeholder="name"/>
							<input style={{width:"100%",backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:18,borderBottom:'1px solid rgba(125, 218, 255, 1.0)'}} 
							onChange={(e)=>{this.setState({staff_title:e.target.value})}} value={this.state.staff_title}  placeholder="title"/>
							{/*<div style={{color:'white',fontSize:18}}>{this.state.staff_name}</div>
							<div style={{color:'white',fontSize:18}}>{this.state.staff_title}</div>*/}
						</div>
						<div style={{marginLeft:20,marginTop:30,marginRight:20}}>
							<textarea onChange={(e)=>{this.setState({remarks:e.target.value})}} 
							style={{width:"100%",height:100,backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:18,borderBottom:'1px solid rgba(125, 218, 255, 1.0)'}} 
							value={this.state.remarks}  placeholder="remarks"></textarea>
						</div>
						<div style={{width:'100%',height:300}}></div>
					</div>}
					{this.state.tab_index!==1||<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
						{this.state.field_service_photo?
							<img src={this.state.field_service_photo} style={{maxWidth:'100%'}} alt='field_service_photo'/>:
							<div style={{width:'80%',height:'80%',border:'2px solid white'}}>
							</div>
						}
						<form className='mousehover' style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:10,backgroundColor:'#7DDAFF',padding:10,borderRadius:5,flexDirection:'row'}}>
							<img src={picture_icon} style={{width:30,height:30,marginRight:20}} alt='picture_icon'/> 
							<input className="fileInput" type="file" onChange={(e)=>this.handleImageChange(e)}></input>
						</form>
					</div>}
					
					{this.state.tab_index<=1||<div style={{width:'100%',}}>
						<div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
							<div className='mousehover' onClick={this.handleMoveForm(-1)} style={{backgroundColor:"purple",padding:3,borderRadius:5}}>
								<div style={{color:'white',fontSize:16,fontWeight:'bold'}}>Move Left</div>
							</div>
							<div className='mousehover' onClick={this.handleMoveForm(1)} style={{backgroundColor:"purple",padding:3,borderRadius:5}}>
								<div style={{color:'white',fontSize:16,fontWeight:'bold'}}>Move Right</div>
							</div>
						</div>
			        	{this.state.forms[this.state.tab_index-2].definition.map((item,index)=>
			        		<div key={index} style={{width:'100%'}}>
								{render_preview(item,this.state.forms[this.state.tab_index-2].definition,index,null,this.state.project,this.props.control.usertype)}
			        		</div>
			        	)}
						<div style={{width:'100%',height:200}}></div>
				    </div>}
				    
				</div>
				{this.state.isSelectCoverDraft && <div style={{position:'fixed',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(0,0,0,.5)',display:'flex',justifyContent:'center',alignItems:'center'}}>
					<div style={{borderRadius:5,border:'2px solid white',padding:5,display:'flex',justifyContent:'center',alignItems:'row',backgroundColor:'#448DD7'}}>
						<div style={{padding:20,flex:1,marginBottom:20}}>
							<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>Cover Letter:</div>
							<Dropdown
								controlClassName='dropdown_control'
								placeholderClassName='dropdown_placeholder'
								options={this.state.cover_list} 
								onChange={(e)=>{
									for (let i in this.state.cover_list)
										if(this.state.cover_list[i].cover_id===e.value)
											this.setState({cover_index:i})
								}} 
								value={this.state.cover_list.length>this.state.cover_index?`-${this.state.cover_list[this.state.cover_index].cover_id}`:'----'} 
								placeholder="Select an option"
							/>
							<div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:30}}>
								<div className='mousehover center_row' style={{marginLeft:2,padding:5,backgroundColor:'rgba(150, 154, 150, 1.0)',borderRadius:5}} onClick={()=>{this.setState({isSelectCoverDraft:false})}}>
									<div style={{color: "white",fontSize: 16,fontWeight:"bold",marginLeft:2,marginRight:2}}>Cancel</div>
								</div>
								<div className='mousehover center_row' style={{marginLeft:2,padding:5,backgroundColor:'rgba(230, 80, 70, 1.0)',borderRadius:5}} onClick={this.deleteCover}>
									<div style={{color: "white",fontSize: 16,fontWeight:"bold",marginLeft:2,marginRight:2}}>Delete</div>
								</div>
								<div className='mousehover center_row' style={{marginLeft:2,padding:5,backgroundColor:'rgba(120, 210, 120, 1.0)',borderRadius:5}} onClick={this.handleLoadCover}>
									<div style={{color: "white",fontSize: 16,fontWeight:"bold",marginLeft:2,marginRight:2}}>Load</div>
								</div>
							</div>
						</div>	
					</div>
				</div>}
				{!this.state.isloading||<LoadingPage/>}
			</div>
		)
	}
}


export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(FillReportPage)