import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import React from "react"
import { connect } from 'react-redux';
import '../App.css';
class ProjectView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			project:this.props.project||'Select an option',
		}
	}
	componentDidMount(){
		
	}
	handle_select=(e)=>{
		this.setState({project:e.value})
		if(this.props.update){
			this.props.update({project:e.value})
		}
	}
	render(){
		return(
			<div style={{display:'flex',flexDirection:'column'}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>Project:</div>
				<Dropdown 
					controlClassName='dropdown_control'
 					placeholderClassName='dropdown_placeholder'
 					options={this.props.control.project_list} onChange={this.handle_select} value={`-${this.state.project}`} placeholder="Select an option"
 				/>
			</div>
		);
	}	
}

export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(ProjectView)
			