import React from "react"
import { connect } from 'react-redux';
import {
	setpageindex,
	setcategory,
	Login,
	setmenu,
	setpassWordNeeded
} from '../actions/Actions'
import {
	get_form_definition,
	delete_form_definition,
} from '../api'
import LoadingPage from './LoadingPage'

import menu from "./../assets/images/menu.png"
import forms_icon from "./../assets/images/forms.png"
import search_icon from "./../assets/images/search_white.png"
import ot_icon from "./../assets/images/ot.png"
import mg_icon from "./../assets/images/mg.png"
import fume_cabunet from "./../assets/images/fume_cabunet.png"
import auto_door from "./../assets/images/auto_door.png"
import cn_form from "./../assets/images/cn_form.png"
import other from "./../assets/images/other.png"
import add_icon from "./../assets/images/add_icon.png"
import edit_icon from "./../assets/images/edit_icon.png"
import refresh from "./../assets/images/refresh.png"
import trash_icon from "./../assets/images/trash_icon.png"


class FormsManagementPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isloading:true,
			scroll_refreshing: false,
			item_list:[],item_index:-1,
			ispopup:false,
			text_search:''
		}
	}

	componentDidMount() {
		this.get_form_definition_by_category=this.get_form_definition_by_category.bind(this)
		this.get_form_definition_by_category();
	}
	componentWillUnmount() {
	}
	delete_form=async(form_id)=>{
		if (this.state.isloading)return;
		this.setState({isloading:true});
		let result=await delete_form_definition(this.props.control.token,form_id)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));
			return
		}
		if(result!==null&&result.result!==undefined&&result.result!=='fail'){
			this.get_form_definition_by_category(this.props.control.category,true)
			this.setState({item_index:-1,ispopup:false});
		}
		this.setState({isloading:false});
	}

	get_form_definition_by_category=async(cat=this.props.control.category,force=true)=>{
		if (!force&&this.state.isloading)return;
		this.setState({scroll_refreshing: true,isloading:true});
		let result=await get_form_definition(this.props.control.token,cat)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));
			return
		}
		if(result!==null&&result.result!==undefined&&result.result!=='fail'){
			this.setState({item_list:result.result})
		}
		this.setState({scroll_refreshing: false,isloading:false});
	}
	handle_category_button=(cat)=>{
		
		this.props.dispatch(setcategory(cat));
		this.get_form_definition_by_category(cat)
		this.setState({item_index:-1})
	}
	render() {
		let control=this.props.control

		if(control.passWordNeeded){
			return(<PasswordPage
					dispatch={this.props.dispatch}
				/>)
		}

		return(

			<div style={{width:'100%',height:'100%',overflowY: 'scroll'}}>
				<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
					<div style={{backgroundColor:'#007EA7',borderRadius:5,marginRight:2,width:'80%',height:46,marginLeft:10,display:'flex',flexDirection:'row',alignItems:'center'}}>
						{true||<img onClick={()=>{this.props.dispatch(setmenu(true))}} className='mousehover' src={menu} style={{marginLeft:10,marginRight:10,width:20,height:20}} alt='menu'/>}
						<img src={search_icon} style={{marginLeft:10,marginRight:10,width:30,height:30}} alt='forms'/>
						<input style={{ backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)', color:"#98E2FF",width:'95%',fontSize: 19,marginLeft:5}} value={this.state.text_search} onChange={(e)=>{this.setState({text_search:e.target.value})}} placeholder="Search for Form...."/>
					</div>
				</div>
				<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
					<div style={{display:'flex',flexDirection:'row',width:'80%',alignItems:'center',justifyContent:'space-between'}}>
						<div className='center_row'>
							<img src={forms_icon} style={{marginLeft:10,marginRight:10,width:47,height:47}} alt='forms'/>
							<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",}}>Forms</div>
							<div className='mousehover' onClick={()=>{this.props.dispatch(setpageindex(2))}} style={{display:'flex',flexDirection:'row',backgroundColor:'#7DDAFF',borderRadius:5,marginLeft:20,height:40,paddingLeft:20,paddingRight:20,justifyContent:'center',alignItems:'center'}}>
								<img src={add_icon} style={{width:17,height:17}} alt='add_icon'/>
								<div style={{color: "white",fontSize: 14,fontWeight: "bold",marginLeft:5}}>ADD</div>
							</div>
							<div className={this.state.item_index>=0?'mousehover':''} 
								onClick={()=>{
								let index=this.state.item_index
								if(index>=0&&index<this.state.item_list.length){
									this.props.dispatch(setpageindex(2,this.state.item_list[index]))
								}
							}} style={{display:'flex',flexDirection:'row',backgroundColor:'#44D7B6',borderRadius:5,marginLeft:20,height:40,paddingLeft:20,paddingRight:20,justifyContent:'center',alignItems:'center',opacity:this.state.item_index>=0?1:.3}}>
								<img src={edit_icon} style={{width:17,height:17}} alt='edit_icon'/>	
								<div style={{color: "white",fontSize: 14,fontWeight: "bold",marginLeft:5}}>Edit</div>
							</div>
							<div className={this.state.item_index>=0?'mousehover':''} onClick={()=>{
								let index=this.state.item_index
								if(index>=0&&index<this.state.item_list.length){
									this.setState({ispopup:true})
								 }
							}} style={{display:'flex',flexDirection:'row',backgroundColor:'#FF4F4F',borderRadius:5,marginLeft:20,height:40,paddingLeft:20,paddingRight:20,justifyContent:'center',alignItems:'center',opacity:this.state.item_index>=0?1:.3}}>
								<img src={trash_icon} style={{width:17,height:17}} alt='trash_icon'/>	
								<div style={{color: "white",fontSize: 14,fontWeight: "bold",marginLeft:5}}>Delete</div>
							</div>
						</div>
						
						<div onClick={()=>{this.get_form_definition_by_category();}}style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
							<img className='mousehover' src={refresh} style={{width:20,height:20}} alt='refresh'/>
						</div>
					</div>
				</div>
				<div className='center_row' style={{width:'100%',justifyContent:'center',marginTop:20}}>
					<img className='mousehover' onClick={()=>{this.handle_category_button('OT')}} src={ot_icon} style={{marginLeft:20,marginRight:10,width:88,height:88,opacity:control.category==='OT'?1:.3}} alt='cata_icon'/>
					<img className='mousehover' onClick={()=>{this.handle_category_button('MG')}} src={mg_icon} style={{marginLeft:20,marginRight:10,width:88,height:88,opacity:control.category==='MG'?1:.3}} alt='cata_icon'/>
					<img className='mousehover' onClick={()=>{this.handle_category_button('FC')}} src={fume_cabunet} style={{marginLeft:20,marginRight:10,width:88,height:88,opacity:control.category==='FC'?1:.3}} alt='cata_icon'/>
					<img className='mousehover' onClick={()=>{this.handle_category_button('AD')}} src={auto_door} style={{marginLeft:20,marginRight:10,width:88,height:88,opacity:control.category==='AD'?1:.3}} alt='cata_icon'/>
					<img className='mousehover' onClick={()=>{this.handle_category_button('CF')}} src={cn_form} style={{marginLeft:20,marginRight:10,width:88,height:88,opacity:control.category==='CF'?1:.3}} alt='cata_icon'/>
					<img className='mousehover' onClick={()=>{this.handle_category_button('OR')}} src={other} style={{marginLeft:20,marginRight:10,width:88,height:88,opacity:control.category==='OR'?1:.3}} alt='cata_icon'/>
				</div>
				{this.state.item_list.map((item,index)=>(
					<div  key={index} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center'}}>
						{!this.state.text_search===''||!item.form_id.toLowerCase().includes(this.state.text_search.toLowerCase())||<div className='mousehover' onClick={()=>{
							if(index===this.state.item_index)
								this.setState({item_index:-1});
							else
								this.setState({item_index:index});
						}} style={{display:'flex',flexDirection:'row',width:'80%',borderBottom:"1px solid rgba(0, 126, 167, 1.0)",borderTop:"1px solid rgba(0, 126, 167, 1.0)",padding:10,justifyContent:'space-between',alignItems:'center'}}>
							<div className='center_row'>
								<div style={{width:26,height:26,borderRadius:13,border:'1px solid rgba(125,218,255,.7)',display:'flex',justifyContent:'center',alignItems:'center'}}>
									<div style={{width:20,height:20,borderRadius:10,backgroundColor:this.state.item_index===index?'rgba(0, 218, 195, 1.0)':'transparent'}}></div>
								</div>
								<div style={{marginLeft:20,color: "#ffffff",fontSize: 30,fontWeight: "bold",}}>{item.form_id}</div>
							</div>
							<div style={{color: "#00DAC3",fontSize: 14,fontWeight: "bold"}}>{item.description}</div>
						</div>}
					</div>
				))}
				<div style={{width:'100%',minWidth:'100%',height:'200px',minHeight:'200px'}}/>
				
				{!this.state.isloading||<LoadingPage/>}
				{!this.state.ispopup||<div style={{backgroundColor:'#003459',position:'absolute',top:0,bottom:0,left:0,right:0,opacity:.5}}></div>}
				{!this.state.ispopup||<div style={{display:'flex',flexDirection:'column',position:'absolute',top: window.innerHeight/2-100,left: window.innerWidth/2-150,width:300,height:200,padding:20,backgroundColor:'rgba(0, 66, 113, 1.0)',alignItems:'center',borderRadius:15,border:'2px solid white',}}>
					<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",borderBottom:'2px solid white'}}>Confirm?</div>
					<div style={{display:'flex',width:'100%',flexDirection:'row',alignItems:'center',flex:1,justifyContent:'center',height:'100%'}}>
						<div className='mousehover' onClick={()=>{this.delete_form(this.state.item_list[this.state.item_index].form_id)}}
							style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
							<div style={{color: "rgba(68, 215, 182, 1.0)",fontSize: 24,fontWeight: "bold",}}>YES</div>
						</div>
						<div className='mousehover' onClick={()=>{this.setState({ispopup:false})}}
							style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
							<div style={{color: "red",fontSize: 24,fontWeight: "bold",}}>NO</div>
						</div>
					</div>
				</div>}

			</div>
		);
	}
}


function PasswordPage({dispatch}){
	const [password,setPassword]=React.useState('')
	const handleConfirm=()=>{
		if(password==='Mgi@26015082'){
			dispatch(setpassWordNeeded(false))
		}else{
			alert('Incorrect Password')
		}
	}
	return(
		<div style={{width:'100%',height:'100%',overflowY: 'scroll'}}>
			<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
				<div style={{backgroundColor:'#007EA7',borderRadius:5,marginRight:2,width:'80%',height:46,marginLeft:10,display:'flex',flexDirection:'row',alignItems:'center'}}>
					
				</div>
			</div>
			<div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
				<div style={{display:'flex',flexDirection:'row',width:'80%',alignItems:'center',justifyContent:'space-between'}}>
					<div className='center_row'>
						<img src={forms_icon} style={{marginLeft:10,marginRight:10,width:47,height:47}} alt='forms'/>
						<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",}}>Forms</div>
					</div>
				</div>

				<div style={{color: "white",fontSize: 24,fontWeight: "bold",marginTop:64}}>Enter Password</div>

				<div style={{display:'flex',width:'80%',height:'75%',flexDirection:'column',justifyContent:'start',alignItems:'start',marginTop:64}}>
					<div style={{fontSize:26,color:'white',fontWeight:700}}>Password:</div>
					<input style={{width:'90%',backgroundColor:"white",fontSize:20,marginTop:24,borderRadius:16,padding:16}} placeholder='Password' onChange={(e)=>{setPassword(e.target.value)}} value={password}/>
					<div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>
						<div className='mousehover' onClick={handleConfirm}
							style={{display:'flex',padding:'12px 64px 12px 64px',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
							<div style={{color: "rgba(68, 215, 182, 1.0)",fontSize: 24,fontWeight: "bold",}}>Confirm</div>
						</div>
					</div>
				</div>
			</div>

			

			
			<div style={{width:'100%',minWidth:'100%',height:'200px',minHeight:'200px'}}/>
		</div>
	)
}




export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(FormsManagementPage)