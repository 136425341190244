//import { Buffer } from 'buffer'
export function formatAMPM(date){
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'pm' : 'am';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0'+minutes : minutes;
	var strTime = hours + ':' + minutes + ' ' + ampm;
	return strTime;
}
export function formatDate(date){
	const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
	return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()}`
}
export function formatDate2(date){
	const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
}

export function toBase64(buff){
  return buff.toString('base64');
}

function swap(items, leftIndex, rightIndex){
	var temp = items[leftIndex];
	items[leftIndex] = items[rightIndex];
	items[rightIndex] = temp;
}
function partition(items, left, right,field) {
	var pivot = items[Math.floor((right + left) / 2)][field], //middle element
	i = left, //left pointer
	j = right; //right pointer
	while (i <= j) {
		while (items[i][field] < pivot) {
		i++;
	}
	while (items[j][field] > pivot) {
		j--;
	}
	if (i <= j) {
		swap(items, i, j); //sawpping two elements
			i++;
			j--;
		}
	}
	return i;
}
const quickSort_ = (items, left, right, field) =>{
	var index;
	if (items.length > 1) {
		index = partition(items, left, right,field); //index returned from partition
		if (left < index - 1) { //more elements on the left side of the pivot
			quickSort_(items, left, index - 1,field);
		}
		if (index < right) { //more elements on the right side of the pivot
			quickSort_(items, index, right,field);
		}
	}
	return items;
}

export var quickSort = quickSort_
