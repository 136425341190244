import React from "react"
import '../App.css';

export default class LoadingPage extends React.Component {
	render(){
		return(
			<div className="abcdf" style={{height: window.innerHeight,justifyContent:'center',alignItems:'center'}}>
				<div style={{justifyContent:'center',alignItems:'center', position:'absolute',top:0,bottom:0,left:0,right:0}}>
					<div className="loader">Loading...</div>
					<div style={{backgroundColor:'#003459',position:'absolute',top:0,bottom:0,left:0,right:0,opacity:.7}}></div>
				</div>
			</div>
		);
	}
}