import React from "react"
import Dropdown from 'react-dropdown'
import { connect } from 'react-redux';
import {
	setpageindex,Login
} from '../actions/Actions'
import {
	approve_form,get_categories_list//submit_form
} from '../api'

import {render_preview} from './RenderForm';
import {formatAMPM,formatDate}from '../util'
import back_icon from "./../assets/images/back.png"
import complete_icon from "./../assets/images/complete.png"
import preview_icon from "./../assets/images/preview.png"
import camera_cion from "./../assets/images/photo-camera.png"
import LoadingPage from './LoadingPage'
class ViewFormPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			categories_list:{},
			project_reverse_dict:{},
	
			isloading:false,
			tab_index:0,
			project:'Project Name (Click to Choose)',

			title:'Form Title (Click to edit)',
			description:'Form Description (Click to edit)',
			in_photo:null,
			in_time:null,
			out_photo:null,
			out_time:null,
			mode:0,//0:take_photo, 1:inspect_photo 2:fill_form 3:take_outphoto
			parse_form:[],

			photoView:0,
			user_category:'',
		}
	}

	async componentDidMount() {
		let fd=this.props.control.form_definition
		if(fd!==null)
			this.setState({
				_id:fd._id,
				title:fd.form_id,
				description:fd.description,
				form_description:fd.form_description,
				location:fd.location,
				position:fd.position,
				type_serial:fd.type_serial,
				project:fd.project,
				parse_form:fd.definition,
				in_photo:fd.in_photo,
				in_time:new Date(fd.in_time),
				out_photo:fd.out_photo,
				out_time:new Date(fd.out_time),
				user_category:fd.user_category,
				approved:fd.approved,
			})
		this.get_categories_list_(true)
		let p_dict={}
		for (let i of this.props.control.project_list){
			p_dict[i.value]=i._id
		}
		this.setState({project_reverse_dict:p_dict})
	}

	update=(data)=>{
		this.setState(data)
	}
	get_categories_list_=async(force=false)=>{
		if(!force&&this.state.isloading)return
		this.setState({isloading:true})
		let result= await get_categories_list(this.props.control.token)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}else if(result===undefined||result===null){alert('Get Category List error');this.setState({isloading:false});return}
		else if(result.result!==undefined&&result.result!=='fail'){
			let dict={}
			for (let i of result.result){
				if(dict[i.project_id]===undefined){
					dict[i.project_id]=[{...i,value:i.category}]
				}else{
					dict[i.project_id].push({...i,value:i.category})
				}
			}
			this.setState({categories_list:dict})
		}
		this.setState({isloading:false})

	}
	approveform=async()=>{
		if(this.state.project==='Project Name (Click to edit)'||!this.state.project){
			alert('Please choose a project');
			return
		}
		if(!this.state.user_category){
			alert('Please choose a category');
			return
		}
		if (this.state.isloading)return;
			this.setState({isloading:true})

		let obj={
			_id:this.state._id,
			form_id:this.state.title,
			description:this.state.description,
			form_description:this.state.form_description,
			location:this.state.location,
			position:this.state.position,
			type_serial:this.state.type_serial,
			project:this.state.project,
			definition:this.state.parse_form,
			user_category:this.state.user_category||'',
		}
		let result=await approve_form(this.props.control.token,obj)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));
			return
		}
		if(result===undefined||result===null){
			this.setState({error_message:'Approve Form Error',isloading:false});
			return
		}else if(result.result!=='success'){
			this.setState({error_message:result.message,isloading:false});
			return
		}else if(result.result==='success'){
			this.props.dispatch(setpageindex(5));
			return
		}
	}
	getCategoryDisplay=()=>{
		let res=this.state.user_category||
			(this.state.project==='Project Name (Click to edit)'
				?'Select Project Below'
				:((this.state.categories_list[this.state.project_reverse_dict[this.state.project]]===undefined||this.state.categories_list[this.state.project_reverse_dict[this.state.project]].length===0)
					?'No available category'
					:'Select Category'
				)
			)
		return '- '+res
	}

	
	render() {
		let width=window.innerWidth
		return(
			<div style={{width:'100%',height:'100%',overflowY: 'scroll'}}>
				<div className='center_row'>
					<div className='mousehover center_row' style={{padding:10,width:'20%'}} 
						onClick={()=>{
							if(this.props.control.page_index===6.1)
								this.props.dispatch(setpageindex(0))
							else
								this.props.dispatch(setpageindex(5))
						}}
					>
						<img src={back_icon} style={{width:17,height:17}} alt='back'/>
						<div style={{marginLeft:10, fontSize:25,color:'white',fontWeight:'bold'}}>Back</div>
					</div>
					<div  style={{width:'60%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
						<div className='center_row' style={{opacity:.3}}>
							<img src={preview_icon} style={{width:17,height:17}} alt='preview'/>
							<div style={{color:'white',fontWeight:'bold',fontSize:20,marginLeft:20}}>Preview Mode</div>
						</div>
						<div style={{color:"white",fontSize: 22,fontWeight: "bold",marginTop:10}}>{this.state.title}</div>
						<div style={{color:"#00DAC3",fontSize: 14,fontWeight: "bold",marginTop:10}}>{this.state.description}</div>
						<div style={{marginTop:10,display:'flex',flexDirection:"row",color:"#00DAC3",fontSize: 14,fontWeight: "bold",}}>
							Description:&nbsp;
							<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid #00DAC3',color:"#00DAC3",fontSize: 14,fontWeight: "bold",flex:1}}  onChange={(e)=>{
								this.setState({form_description:e.target.value})
							}} value={this.state.form_description===undefined?'':this.state.form_description} placeholder="Description"/>
						</div>
						<div style={{display:'flex',flexDirection:'row',padding:5,flex:1,marginBottom:2}}>
							<div style={{color:'#00DAC3',textAlign:'left',fontSize: 14,fontWeight: "bold",marginTop:7,flex:1}}>Category:&nbsp;</div>
							<Dropdown
								controlClassName='dropdown_control'
								placeholderClassName='dropdown_placeholder'
								options={this.state.project_reverse_dict[this.state.project]===undefined?[]:this.state.categories_list[this.state.project_reverse_dict[this.state.project]]||[]}
								onChange={(e)=>{
									this.setState({user_category:e.value})
								}} value={this.getCategoryDisplay()} 
								placeholder="Select an option"
							/>
						</div>
					</div>
					{this.state.approved||<div style={{display:'flex',width:'25%',padding:10,justifyContent:'flex-end'}}>
						<div className='mousehover center_row' style={{width:130,padding:10,backgroundColor:'rgba(37, 229, 154, 1.0)',borderRadius:5,marginRight:10,}} onClick={this.approveform}>
							<img src={complete_icon} style={{width:17,height:17}} alt='complete'/>
							<div style={{color: "white",fontSize: 20,fontWeight:"bold",marginLeft:10}}>Complete</div>
						</div>
					</div>}
				</div>
				<div style={{display:'flex',flexDirection:'row',height:45,width:'100%',alignItems:'flex-end',justifyContent:'center',marginTop:10}}>
					{this.state.parse_form.map((item,index)=>(
						<div key={index} style={{display:'flex',alignItems:'center',justifyContent:'center', height:'100%',marginLeft:3,borderTopLeftRadius:10,borderTopRightRadius:10 ,
							backgroundColor:this.state.tab_index===index?'#44CDD7':'rgba(0, 66, 113, 1.0)',
							width:this.state.tab_index===index?(this.state.parse_form.length===1?width:((width-100)/this.state.parse_form.length+100)):((width-100)/this.state.parse_form.length)
						}} onClick={()=>{this.setState({tab_index:index})}}>
							<div style={{textAlign:'center',width:'80%',fontSize: 25,fontWeight: "bold",color:this.state.tab_index===index?"#0052FF":'rgba(125, 218, 255, 1.0)',}}>{item.name}</div>
						</div>
					))}
				</div>
				<div style={{height:'70%',width:'100%',overflowY: 'scroll',backgroundColor:'rgba(5, 102, 141, 1.0)'}}>
					{render_preview([],this.state.parse_form,this.state.tab_index,this.update,this.state.project,this.props.control.usertype)}
				</div>
				<div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
					<div className='mousehover' onClick={()=>{this.setState({photoView:2})}} style={{display:'flex',flexDirection:'row',padding:10,backgroundColor:'rgba(0, 218, 195, 1.0)',borderRadius:10,marginTop:10,marginLeft:20}}>
						<img src={camera_cion} style={{width:30,height:30}} alt='icon'/>
						<div style={{fontSize:20,fontWeight:'bold',marginLeft:12,color:'white'}}>Inspection Photo</div>
					</div>
				</div>
				{this.state.photoView<=0||<div style={{display:'flex',position:'absolute',top:0,bottom:0,right:0,left:0,backgroundColor:'black',opacity:.85}}></div>}
				{this.state.photoView<=0||
					<div onClick={()=>{this.setState({photoView:0})}} style={{display:'flex',flexDirection:'column',position:'absolute',top:0,bottom:0,right:0,left:0,alignItems:'center',justifyContent:'center'}}>
						<div style={{color:'rgba(125, 218, 255, 1.0)',fontSize:20}}>End Inspection</div>
						<div style={{color:'rgba(125, 218, 255, 1.0)',fontSize:20}}>Time/ Date</div>
						<div style={{marginTop:20,color:'white',fontSize:20}}>
							{`${formatAMPM(this.state.out_time)} ${formatDate(this.state.out_time)}`}
						</div>
						<img style={{maxHeight:'90%',maxWidth:'100%'}} src={this.state.out_photo} alt='out_photo'/>
					</div>
				}
				{!this.state.isloading||<LoadingPage/>}
			</div>
		);
	}	






}

export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(ViewFormPage)
