export default function reducer(state={
	a:[],
	b:{},
	c:false
},action) {
	//const newState = {...state}
	switch(action.type){
		case "ChangeName":{
			state={...state, name:action.payload}
			break;
		}
		case "ChangeAge":{
			state={...state, age:action.payload}
			break;
		}
		case "ERR":{
			throw new Error("AHHHH");
		}
		default:{
			;
		}
	}

	return state
}