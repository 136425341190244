//import { Text, TextInput, StyleSheet, Image, View, Dimensions, TouchableOpacity} from "react-native"
import React from "react"
import { connect } from 'react-redux';
import LoadingPage from './LoadingPage'
import {
	Login,setpageindex
} from '../actions/Actions'
import {
	login_api
} from '../api'

import logo from "./../assets/images/logo.png"
import xeye from "./../assets/images/xeye.png"
import eye from "./../assets/images/eye.png"
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import txt from '../patchnotes.txt'

class LoginPage extends React.Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};
	constructor(props) {
		super(props);
		const { cookies } = props;
		this.state = {
			isloading:false,
			isremember:false,
			issecure:true,
			username:cookies.get('MGI_username') || '',password:cookies.get('MGI_password') || '',
			iswrongpw:false,
			error_message:'Invalid account/password',
			height:0,width:0,
			patchnotes:'',
		}
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount=() =>{
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		this.setPatchNotes()  
	}
	setPatchNotes=()=>{
		fetch(txt)
		.then((r) => r.text())
		.then(text  => {
			this.setState({patchnotes:text})
		}) 
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}
	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}
	login=async ()=>{
		if(this.state.username===''){this.setState({iswrongpw:true,error_message:'Username is Empty'});return}
		if(this.state.password===''){this.setState({iswrongpw:true,error_message:'Password is Empty'});return}
		if(this.state.username.length<5||this.state.password.length<5){this.setState({iswrongpw:true,error_message:'Invalid account/password'});return}
		if(this.state.isloading)return
		this.setState({isloading:true})
		let cred={
			username:this.state.username,
			password:this.state.password,
			login_type:'web',
			version:this.props.control.version
		}
		let res=await login_api(cred)
		if(res===null){this.setState({iswrongpw:true,error_message:'Invalid account/password',isloading:false});return}
		if(res.result==='fail'){this.setState({iswrongpw:true,error_message:res.message||'Error',isloading:false});return}
		let token=res.token
		let usertype=res.type
		if(usertype!=='admin'){this.setState({iswrongpw:true,error_message:'Editor accounts are not allowed to login via web browser. Please login with iPad.',isloading:false});return}
		this.setState({isloading:false})
		if(token){
			this.props.dispatch(Login(true,token,usertype))
			this.props.dispatch(setpageindex(0))
			const { cookies } = this.props;
			cookies.set('MGI_username', this.state.username, { path: '/' });
			cookies.set('MGI_password', this.state.password, { path: '/' });
		}
	}
	render() {
		let control=this.props.control;
		return(
			<div style={{height:this.state.height}} className="mainPageView">
				<img src={logo} style={{width:168,height:133}} alt='logo'/>
				<div style={{fontSize:36,fontWeight:'bold',color:'white',padding:10,borderBottom:"3px solid white"}}>E REPORT SYSTEM</div>			
				<div style={{width:302,alignItems:'flex-start',paddingTop:20}}>
					<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",fontStyle: "normal",}}> Welcome Back,</div>
					<div style={{color: "white",fontSize: 14,fontStyle: "normal",}}>Please Login to your account</div>
				</div>
				<div style={{width:316,height:120,border:"3px solid white",borderRadius:10,marginTop:20}}>
					<div style={{width:316,height:60,borderBottom:"1px solid white"}}>
						<div style={{color:"#03ADE4",fontSize: 17,marginLeft:5,marginTop:2}}>User Name</div>
						<input style={{ backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)', color:"white",width:270,fontSize: 19,marginLeft:5,marginTop:2}} value={this.state.username} onChange={(e)=>{this.setState({username:e.target.value})}} placeholder="account"/>
					</div>
					<div style={{width:316,height:60}}>
						<div style={{display:'flex',width:316,flexDirection:'row',justifyContent:'flex-start'}}>
							<div style={{width:270}}>
								<div style={{color:"#03ADE4",width:270,fontSize: 17,marginLeft:5,marginTop:2}}>Password</div>
								<input type={this.state.issecure?"password":"text"} style={{ backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)', color:"white",width:270,fontSize: 19,marginLeft:5,marginTop:2}} value={this.state.password} onChange={(e)=>{this.setState({password:e.target.value})}} placeholder="password"/>
							</div>
							<img className='mousehover' src={this.state.issecure?xeye:eye} style={{marginTop:20,width:28,height:32}} onClick={()=>{this.setState({issecure:!this.state.issecure})}} alt='eye'/>
						</div>
					</div>
					<div style={{color:"red",fontSize: 20,opacity:this.state.iswrongpw?1:0}}>{this.state.error_message}</div>
					<div style={{display:'flex',alignItems:'center',justifyContent:'center',width:316,height:60}}>
						<div className='mousehover' style={{display:'flex',alignItems:'center',justifyContent:'center',height:44,width:146,borderRadius:3,backgroundColor:'#79E8ED',marginTop:30}} onClick={this.login}>
							<div style={{color: "white",fontSize: 22,fontWeight: "bold"}}>
								LOGIN
							</div>
						</div>
					</div>
				</div>
				<div className='mousehover' onClick={()=>{this.setState({ispopup:true})}} style={{position:'absolute',bottom:0,left:0,color:'white',marginLeft:10}}>Version {control.version}</div>
				{!this.state.isloading||<LoadingPage/>}
				{!this.state.ispopup||<div style={{backgroundColor:'#003459',position:'absolute',top:0,bottom:0,left:0,right:0,opacity:.5}}></div>}
				{!this.state.ispopup||<div className='mousehover' onClick={()=>{this.setState({ispopup:false})}} style={{display:'flex',flexDirection:'column',position:'absolute',top: '10%',left: '10%',width:'80%',height:'80%',padding:20,backgroundColor:'rgba(0, 66, 113, 1.0)',alignItems:'center',borderRadius:15,border:'2px solid white',overflowY: 'scroll'}}>
					<div style={{display:'flex',width:'90%',borderBottom:'2px solid #79E8ED'}}>
						<div style={{fontSize:20,fontWeight:"bold",color:'white'}}>Patch notes</div>
					</div>
					<div style={{display:'flex',width:'90%',flexDirection:'row',alignItems:'center',marginTop:10}}>
						<div style={{color:'white'}}><pre>{this.state.patchnotes}</pre></div>
					</div>
				</div>}
			</div>		
		);
	}
}

export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(withCookies(LoginPage))




