import React from "react"


export default class FormulaView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isloading:false,
			isAutoUpdate:true
		}
	}
	componentDidMount = async() => {
		let item=this.props.data
		let temp=item.answer
		if(item.formula){
			temp=Math.round(this.get_answer(item.formula)*1000)/1000
			this.props.setanswer(temp)
			this.update_interval=setInterval(()=>{this.update_formula()}, 2000);
		}
	}
	componentWillUnmount(){
	   clearInterval(this.update_interval);
	}
	update_formula(){
		if(!this.state.isAutoUpdate)return
		let item=this.props.data
		let temp=item.answer
		temp=Math.round(this.get_answer(item.formula)*1000)/1000
		this.props.setanswer(temp)
	}
	get_answer(formula){

		if(formula.includes('+')){
			let temp=formula.split('+')
			let ans=null
			for(let i in temp){
				ans=ans+(isNaN(parseFloat(this.get_answer(temp[i]),10))?0:parseFloat(this.get_answer(temp[i]),10))
			}
			return ans
		}
		if(formula.includes('/')){
			let temp=formula.split('/')
			let ans=isNaN(parseFloat(this.get_answer(temp[0]),10))?0:parseFloat(this.get_answer(temp[0]),10)
			for(let i=1;i<temp.length;i++){
				ans=ans/(isNaN(parseFloat(this.get_answer(temp[i]),10))?1:parseFloat(this.get_answer(temp[i]),10))
			}
			return ans
		}
		if(formula.includes('*')){
			let temp=formula.split('*')
			let ans=isNaN(parseFloat(this.get_answer(temp[0]),10))?1:parseFloat(this.get_answer(temp[0]),10)
			for(let i=1;i<temp.length;i++){
				ans=ans*(isNaN(parseFloat(this.get_answer(temp[i]),10))?1:parseFloat(this.get_answer(temp[i]),10))
			}
			return ans
		}
		if(formula.startsWith('tableaverage')){
			let t=formula.split('tableaverage')[1].split('[')
			if(t.length<2)return 0
			let id=t[1].split(']')[0]
			let fd=this.props.form
			for(let f in fd){
				let form = fd[f].form
				if(form)
					for(let i in form)
						for(let j in form[i]){
							if(form[i][j].id!==undefined){
								if(form[i][j].id===id){
									if(form[i][j].type==='table'){
										let ans=0
										let count=0
										for (let m in form[i][j].answer)
											for(let n in form[i][j].answer[m])
												if(form[i][j].answer[m][n]===''||form[i][j].answer[m][n]===null||form[i][j].answer[m][n]===undefined);
												else if(!isNaN(form[i][j].answer[m][n])){
													count+=1
													ans+=parseFloat(form[i][j].answer[m][n],10)
												}
										return count===0?0:ans/count
									}else return form[i][j].answer
								}
							}
						}
			}
			return id
		}
		
		let list=formula.split('[')
		if(list.length<2)return formula
		let id=list[1].split(']')[0]
		let fd=this.props.form
		for(let f in fd){
			let form = fd[f].form
			if(form)
				for(let i in form)
					for(let j in form[i]){
						if(form[i][j].id!==undefined){
							if(form[i][j].id===id){
								if(form[i][j].type==='table'){
									if (list.length===4){
										return form[i][j].answer[list[2].split(']')[0]][list[3].split(']')[0]]
									}else return null
								}else return form[i][j].answer
							}
						}
					}
		}
		return id
	}

	render(){
		let item = this.props.data
		return(
			<div style={{flex:1,margin:20, padding:10}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{item.question}</div>
				<div style={{display:'flex',marginTop:3,width:'100%',flexDirection:'row',alignItems:'flex-end',borderBottom:'2px solid white',justifyContent:'space-between'}}>
				{!item.prefix||<div style={{marginRight:5, color:'white',fontSize:item.fixsamesize?18:13,textAlign:'right'}}>{item.prefix}&nbsp;</div>}
				<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1)',fontSize:18,minWidth:'80%'}} 
					maxLength={item.max===undefined?null:item.max>0?parseInt(item.max):null}
					placeholder={item.placeholder===undefined?null:item.placeholder}
					onChange={(e)=>{
						let value=e.target.value
						if(value==='')this.setState({isAutoUpdate:true})
						else this.setState({isAutoUpdate:false})
						let temp=item.answer
						temp=value.replace(/[^\d.-]/g,'');
						this.props.setanswer(temp)
					}} value={(item.answer!==undefined)?`${item.answer}`:''}
				/>
				{!item.postfix||<div style={{color:'white',fontSize:item.fixsamesize?18:13,textAlign:'right'}}>{item.postfix}</div>}
				</div>
				{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Ans:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
			</div>
		);
	}
}
