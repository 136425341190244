import React from "react"
//import {Image, View, Dimensions, TouchableOpacity,Text,TextInput} from "react-native"
import {
} from '../actions/Actions'


export default class TableView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isloading:false,
		}
	}
	componentDidMount = async() => {
		let item=this.props.data
		let temp=item.answer
		let hasFormula=false
		for(let i in item.column){
			if(item.column[i].type.startsWith('formula:')){
				temp[0][i]=Math.round(this.get_answer(item.column[i].type.split('formula:')[1].replace(/\s/g, ''))*1000)/1000
				hasFormula=true
			}
		}
		if(hasFormula){
			this.props.setanswer(temp)
			this.update_interval=setInterval(()=>{this.update_formula()}, 2000);
		}
	}
	componentWillUnmount(){
	   clearInterval(this.update_interval);
	}
	update_formula(){
		let item=this.props.data
		let temp=item.answer
		for(let i in item.column){
			if(item.column[i].type.startsWith('formula:')){
				temp[0][i]=Math.round(this.get_answer(item.column[i].type.split('formula:')[1].replace(/\s/g, ''))*1000)/1000
			}
		}
		this.props.setanswer(temp)
	}
	get_answer(formula){

		if(formula.includes('sqrt')){
			let temp=formula.split('sqrt')
			let ans=isNaN(parseFloat(this.get_answer(temp[1]),10))?1:parseFloat(this.get_answer(temp[1]),10)
			return Math.sqrt(ans)
		}
		if(formula.includes('+')){
			let temp=formula.split('+')
			let ans=null
			for(let i in temp){
				ans=ans+(isNaN(parseFloat(this.get_answer(temp[i]),10))?0:parseFloat(this.get_answer(temp[i]),10))
			}
			return ans
		}
		if(formula.includes('/')){
			let temp=formula.split('/')
			let ans=isNaN(parseFloat(this.get_answer(temp[0]),10))?0:parseFloat(this.get_answer(temp[0]),10)
			for(let i=1;i<temp.length;i++){
				ans=ans/(isNaN(parseFloat(this.get_answer(temp[i]),10))?1:parseFloat(this.get_answer(temp[i]),10))
			}
			return ans
		}
		if(formula.includes('*')){
			let temp=formula.split('*')
			let ans=isNaN(parseFloat(this.get_answer(temp[0]),10))?1:parseFloat(this.get_answer(temp[0]),10)
			for(let i=1;i<temp.length;i++){
				ans=ans*(isNaN(parseFloat(this.get_answer(temp[i]),10))?1:parseFloat(this.get_answer(temp[i]),10))
			}
			return ans
		}

		if(formula.startsWith('min')){
			let t=formula.split('min')[1].split('[')
			
			let min=0
			let form = this.props.form
			let o=0
			while(o+3<t.length){
				let id=t[o+1].split(']')[0]
				if(form)
					for(let i in form)
						for(let j in form[i]){
							if(form[i][j].id!==undefined){
								if(form[i][j].id==id){
									if(form[i][j].type=='table'){
										let ans = form[i][j].answer[t[o+2].split(']')[0]][t[o+3].split(']')[0]]
										ans=isNaN(parseFloat(ans,10))?0:parseFloat(ans,10)
										if(ans<min)
											min=ans
										if(o===0)
											min=ans
									}else {
										let ans = form[i][j].answer
										ans=isNaN(parseFloat(ans,10))?0:parseFloat(ans,10)
										if(ans<min)
											min=ans
										if(o===0)
											min=ans
									}
								}
							}
						}
				o+=3
			}
			return min
		}

		if(formula.startsWith('max')){
			let t=formula.split('max')[1].split('[')
			
			let max=0
			let form = this.props.form
			let o=0
			while(o+3<t.length){
				let id=t[o+1].split(']')[0]
				if(form)
					for(let i in form)
						for(let j in form[i]){
							if(form[i][j].id!==undefined){
								if(form[i][j].id==id){
									if(form[i][j].type=='table'){
										let ans = form[i][j].answer[t[o+2].split(']')[0]][t[o+3].split(']')[0]]
										ans=isNaN(parseFloat(ans,10))?0:parseFloat(ans,10)
										if(ans>max)
											max=ans
										if(o===0)
											max=ans
									}else {
										let ans = form[i][j].answer
										ans=isNaN(parseFloat(ans,10))?0:parseFloat(ans,10)
										if(ans>max)
											max=ans
										if(o===0)
											max=ans
									}
								}
							}
						}
				o+=3
			}
			return max
		}

		if(formula.startsWith('tableaverage')){
			let t=formula.split('tableaverage')[1].split('[')
			if(t.length<2)return 0
			let id=t[1].split(']')[0]
			let form = this.props.form
			if(form)
				for(let i in form)
					for(let j in form[i]){
						if(form[i][j].id!==undefined){
							if(form[i][j].id==id){
								if(form[i][j].type=='table'){
									let ans=0
									let count=0
									for (let m in form[i][j].answer)
										for(let n in form[i][j].answer[m])
											if(form[i][j].answer[m][n]===''||form[i][j].answer[m][n]===null||form[i][j].answer[m][n]===undefined);
											else if(!isNaN(form[i][j].answer[m][n])){
												count+=1
												ans+=parseFloat(form[i][j].answer[m][n],10)
											}
									return ans/count
								}else return form[i][j].answer
							}
						}
					}
			
			return id
		}


		let list=formula.split('[')
		if(list.length<2)return formula
		let id=list[1].split(']')[0]
		if(this.props.form)
			for(let i in this.props.form)
				for(let j in this.props.form[i]){
					if(this.props.form[i][j].id!==undefined){
						if(this.props.form[i][j].id===id){
							if(this.props.form[i][j].type==='table'){
								if (list.length===4){
									return this.props.form[i][j].answer[list[2].split(']')[0]][list[3].split(']')[0]]
								}else return null
							}else return this.props.form[i][j].answer
						}
					}
				}
		else {
			return formula
		}
		return id
	}

	flexSum = function(arr){
		let result=0
		for (let i of arr)
			result+=i.flex||1
		//console.log(result)
		return result
	}

	render(){
		let item = this.props.data
		return(
			<div style={{flex:1,marginLeft:20,marginRight:20,paddingLeft:10, paddingRight:10}}>
				{!item.question||<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",}}>{item.question}</div>}
				{item.noheader||<div style={{display:'flex',width:'100%',flexDirection:'row'}}>
					{item.column.map((col,index)=>
						<div key={index} style={{color:'white',boxSizing:'border-box',width:`${col.flex*100/this.flexSum(item.column)}%`,border:'1px solid white',
							borderTopWidth: item.mergeTop===undefined ? '1px':item.mergeTop[index]?'0':'1px',
							borderBottomWidth:item.mergeBottom===undefined?'1px':item.mergeBottom[index]?'0':'1px' ,
							borderLeftWidth:item.mergeLeft===undefined?'1px':item.mergeLeft[index]?'0':'1px' ,
							borderRightWidth:item.mergeRight===undefined?'1px':item.mergeRight[index]?'0':'1px',
							display:'flex',
							flexDirection:'row',
							justifyContent:(item.justifyContent!==undefined&&item.justifyContent[index])?item.justifyContent[index]:'center',
							alignItems:(item.alignItems!==undefined&&item.alignItems[index])?item.justifyContent[index]:'center'
						}}>																			
							<div>{col.value}</div>
						</div>)}
				</div>}
				{item.answer.map((row,row_index)=>
					<div key={row_index} style={{display:'flex',width:'100%',flexDirection:'row'}}>
						{item.column.map((col,col_index)=>
							<div key={col_index} style={{display:'flex',padding:1,justifyContent:'center',alignItems:'flex-start',boxSizing:'border-box',width:`${col.flex*100/this.flexSum(item.column)}%`,border:'1px solid white',
								borderTopWidth:item.mergeTop===undefined?'1px':item.mergeTop[col_index]?'0':'1px',
								borderBottomWidth:item.mergeBottom===undefined?'1px':item.mergeBottom[col_index]?'0':'1px',
								borderLeftWidth:item.mergeLeft===undefined?'1px':item.mergeLeft[col_index]?'0':'1px',
								borderRightWidth:item.mergeRight===undefined?'1px':item.mergeRight[col_index]?'0':'1px',
							}}>
								{item.column[col_index].type==='textinput'?
									<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1)',width:'100%',fontSize:15,textAlign:'center'}} onChange={(e)=>{
										if(item.isLocked)return
										let value=e.target.value
										let temp=item.answer
										temp[row_index][col_index]=value;
										this.props.setanswer(temp)
					    			}} value={item.answer[row_index][col_index]===undefined?'':item.answer[row_index][col_index]===null?'':item.answer[row_index][col_index]}/>:
					    		item.column[col_index].type==='textnumber'?
									<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1)',width:'100%',fontSize:15,textAlign:'center'}} onChange={(e)=>{
										if(item.isLocked)return
										let value=e.target.value
										let temp=item.answer
										temp[row_index][col_index]=value.replace(/[^\d.-]/g,'');
										this.props.setanswer(temp)
					    			}} value={item.answer[row_index][col_index]===undefined?'':item.answer[row_index][col_index]===null?'':item.answer[row_index][col_index]}/>:
					    		item.column[col_index].type==='textinteger'?
									<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1)',width:'100%',fontSize:15,textAlign:'center'}} onChange={(e)=>{
										if(item.isLocked)return
										let value=e.target.value
										let temp=item.answer
										temp[row_index][col_index]=value.replace(/[^0-9]/g, '');
										this.props.setanswer(temp)
					    			}} type = "number" value={item.answer[row_index][col_index]===undefined?'':item.answer[row_index][col_index]===null?'':item.answer[row_index][col_index]}/>:
					    		item.column[col_index].type.startsWith('check_setformula')?
					    			<div className='mousehover' style={{display:'flex',marginTop:5,marginBottom:5,width:22,height:22,borderRadius:11,border:'1px solid rgba(125,218,255,.7)',displat:'flex',justifyContent:'center',alignItems:'center'}} 
					    			onClick={()=>{
					    				if(item.isLocked)return
					    				if(!item.answer[row_index][col_index]){
					    					let t=item.column[col_index].type.split('{')
											if(t.length>=2){
												let id=t[1].split('}')[0]
												let form = this.props.form
												if(form){
													for(let i in form)
														for(let j in form[i]){
															if(form[i][j].id!==undefined){
																if(form[i][j].id===id){
																	if(form[i][j].type==='table'){
																		if (t.length===3){
																			let len=item.column[col_index].type.split('}').length
																			let formula_s=item.column[col_index].type.split('}')[len-1]
																			if(formula_s.split('&&&')[row_index]){
																				let formula=formula_s.split('&&&')[row_index]
																				form[i][j].column[t[2].split('}')[0]].type=`formula:${formula}`
																			}
																		}
																	}
																}
															}
														}
													this.props.setform(form)
												}
											}
					    				}
					    				let temp=item.answer
					    				for (let i in item.answer)
											if(i!==row_index)
												item.answer[i][0]=false
					    				if(temp[row_index][col_index])
					    					temp[row_index][col_index]=false
					    				else
					    					temp[row_index][col_index]=true
					    				this.props.setanswer(temp)
					    			}}>
										<div style={{width:18,height:18,borderRadius:9,backgroundColor:item.answer[row_index][col_index]?'rgba(0, 218, 195, 1.0)':'transparent'}}></div>
									</div>:
								item.column[col_index].type==='check'?
					    			<div className='mousehover' style={{display:'flex',marginTop:5,marginBottom:5,width:22,height:22,borderRadius:11,border:'1px solid rgba(125,218,255,.7)',displat:'flex',justifyContent:'center',alignItems:'center'}} 
					    			onClick={()=>{
					    				if(item.isLocked)return
					    				let temp=item.answer
					    				if(temp[row_index][col_index])
					    					temp[row_index][col_index]=false
					    				else
					    					temp[row_index][col_index]=true
					    				this.props.setanswer(temp)
					    			}}>
										<div style={{width:18,height:18,borderRadius:9,backgroundColor:item.answer[row_index][col_index]?'rgba(0, 218, 195, 1.0)':'transparent'}}></div>
									</div>:
								item.column[col_index].type==='passfailna'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='pass')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='pass';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='pass'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='pass'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> Pass </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='fail')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='fail';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='fail'?'red':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='fail'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> Fail </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='na')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='na';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='na'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='na'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> NA </div>
										</div>
									</div>:
								item.column[col_index].type==='合格不合格不適用'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='合格')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='合格';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='合格'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='合格'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 合格 </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='不合格')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='不合格';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='不合格'?'red':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='不合格'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 不合格 </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='不適用')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='不適用';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='不適用'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='不適用'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 不適用 </div>
										</div>
									</div>:
								item.column[col_index].type==='已調整不用調整不適用'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='已調整')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='已調整';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='已調整'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='已調整'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 已調整 </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='不用調整')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='不用調整';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='不用調整'?'red':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='不用調整'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 不用調整 </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='不適用')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='不適用';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='不適用'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='不適用'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 不適用 </div>
										</div>
									</div>:
								item.column[col_index].type==='已補充不用補充'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='已補充')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='已補充';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='已補充'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='已補充'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 已補充 </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='不用補充')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='不用補充';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='不用補充'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='不用補充'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 不用補充 </div>
										</div>
									</div>:
								item.column[col_index].type==='已調整不用調整'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='已調整')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='已調整';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='已調整'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='已調整'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 已調整 </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='不用調整')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='不用調整';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='不用調整'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='不用調整'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 不用調整 </div>
										</div>
									</div>:
								item.column[col_index].type==='已更換不用更換'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='已更換')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='已更換';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='已更換'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='已更換'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 已更換 </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='不用更換')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='不用更換';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='不用更換'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='不用更換'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 不用更換 </div>
										</div>
									</div>:
								item.column[col_index].type==='okna'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='ok')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='ok';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='ok'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='ok'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> OK </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='na')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='na';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='na'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='na'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> NA </div>
										</div>
									</div>:
								item.column[col_index].type==='220V380V'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='220V')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='220V';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='220V'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='220V'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 220V </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='380V')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='380V';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='380V'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='380V'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> 380V </div>
										</div>
									</div>:
								item.column[col_index].type==='yesminimalno'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='yes')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='yes';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='yes'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='yes'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> Yes </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='minimal')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='minimal';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='minimal'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='minimal'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> Minimal </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='no')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='no';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='no'?'red':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='no'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> No </div>
										</div>
									</div>:
								item.column[col_index].type==='yesno'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='yes')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='yes';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='yes'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='yes'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> Yes </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='no')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='no';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='no'?'red':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='no'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> No </div>
										</div>
									</div>:
								item.column[col_index].type.startsWith('dynamicyesno:')?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let str=item.column[col_index].type.split('dynamicyesno:')[1]
											let list=str.split('[')
											let x=6
											while(x<list.length){
												if(this.props.form)
													for(let i in this.props.form)
														for(let j in this.props.form[i]){
															if(this.props.form[i][j].id!==undefined){
																if(this.props.form[i][j].id==list[x-5].split(']')[0]){
																	if(this.props.form[i][j].type=='table'){
																		this.props.form[i][j].column[list[x-4].split(']')[0]].type=list[x-3].split(']')[0]
																		for (let k=0;k<this.props.form[i][j].noofrow;k++)
																			this.props.form[i][j].answer[k][list[x-4].split(']')[0]]=list[x-2].split(']')[0]
																	}else{
																		this.props.form[i][j].type=list[x-3].split(']')[0]
																		this.props.form[i][j].answer=list[x-2].split(']')[0]
																	}
																}
															}
														}
												x+=6
											}
											let temp=item.answer
											if(temp[row_index][col_index]==='yes')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='yes';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='yes'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='yes'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> Yes </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let str=item.column[col_index].type.split('dynamicyesno:')[1]
											let list=str.split('[')
											let x=6
											while(x<list.length){
												if(this.props.form)
													for(let i in this.props.form)
														for(let j in this.props.form[i]){
															if(this.props.form[i][j].id!==undefined){
																if(this.props.form[i][j].id==list[x-5].split(']')[0]){
																	if(this.props.form[i][j].type=='table'){
																		this.props.form[i][j].column[list[x-4].split(']')[0]].type=list[x-1].split(']')[0]
																		for (let k=0;k<this.props.form[i][j].noofrow;k++)
																			this.props.form[i][j].answer[k][list[x-4].split(']')[0]]=list[x].split(']')[0]
																	}else{
																		this.props.form[i][j].type=list[x-1].split(']')[0]
																		this.props.form[i][j].answer=list[x].split(']')[0]
																	}
																}
															}
														}
												x+=6
											}
											let temp=item.answer
											if(temp[row_index][col_index]==='no')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='no';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='no'?'red':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='no'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> No </div>
										</div>
									</div>:
								(item.column[col_index].type==='passfail'||item.column[col_index].type==='passfailcheck')?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='pass')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='pass';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='pass'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='pass'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> Pass </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='fail')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='fail';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='fail'?'red':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='fail'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> Fail </div>
										</div>
									</div>:
								item.column[col_index].type==='na'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='na')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='na';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='na'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='na'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> NA </div>
										</div>
									</div>:
								item.column[col_index].type==='done'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='done')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='done';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='done'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='done'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> Done </div>
										</div>
									</div>:
								item.column[col_index].type==='notinstalled'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer

											if(item.lock){
												let form = this.props.form
												for(let id of item.lock){
													
													if(form){
														for(let i in form){
															for(let j in form[i]){
																if(form[i][j].id!==undefined){
																	if(form[i][j].id===id){
																		if(temp[row_index][col_index]==='notinstalled'){
																			form[i][j].isLocked=false
																		}
																		else{
																			form[i][j].isLocked=true
																		}
																		if(form[i][j].type==='table'){
																			for (let m in form[i][j].answer)
																				for(let n in form[i][j].answer[m]){
																					if(form[i][j].column&&form[i][j].column[n]&&form[i][j].column[n].type!=='text')
																						form[i][j].answer[m][n]=''
																				}
																		}
																	}
																}
															}
														}
													}
												}
												this.props.setform(form)
											}
											
											if(temp[row_index][col_index]==='notinstalled')
												temp[row_index][col_index]='';
											else{
												temp[row_index][col_index]='notinstalled';
											}
											this.props.setanswer(temp)

						
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='notinstalled'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='notinstalled'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> Not Installed </div>
										</div>
									</div>:
								item.column[col_index].type==='barpsikpa'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='Bar')
												temp[row_index][col_index]='Psi';
											else if(temp[row_index][col_index]==='Psi')
												temp[row_index][col_index]='Kpa';
											else 
												temp[row_index][col_index]='Bar';
											this.props.setanswer(temp)
										}}
										style={{display:'flex',backgroundColor:'rgba(125, 218, 255, 1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:'white',fontSize:10}}>  {item.answer[row_index][col_index]===undefined?'':item.answer[row_index][col_index]} </div>
										</div>
									</div>:
									item.column[col_index].type==='donena'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='done')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='done';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='done'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='done'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> Done </div>
										</div>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='na')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='na';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='na'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='na'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> NA </div>
										</div>
									</div>:
									item.column[col_index].type==='twinsingle'?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='twin')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='twin';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='twin'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30}}>
											<div style={{color:item.answer[row_index][col_index]==='twin'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> Twin </div>
										</div>
										<div onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]==='single')
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]='single';
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]==='single'?'red':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]==='single'?'white':'rgba(0,126,167,1.0)',fontSize:10}}> Single </div>
										</div>
									</div>:
									['合格','不合格','不適用','已通知','完成','CW','HW','CA','VAC','N2','DI','TG','LPG','O2','CO2','H2','HE','NH3','MA4','N20','SA7','50O250N20'].includes(item.column[col_index].type)?
									<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
										<div className='mousehover' onClick={()=>{
											if(item.isLocked)return
											let temp=item.answer
											if(temp[row_index][col_index]===item.column[col_index].type)
												temp[row_index][col_index]='';
											else
												temp[row_index][col_index]=item.column[col_index].type;
											this.props.setanswer(temp)
										}}
											style={{display:'flex',backgroundColor:item.answer[row_index][col_index]===item.column[col_index].type?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',flex:1,height:30,marginLeft:1}}>
											<div style={{color:item.answer[row_index][col_index]===item.column[col_index].type?'white':'rgba(0,126,167,1.0)',fontSize:10}}> {item.column[col_index].type==='50O250N20'?'O2/N2O':item.column[col_index].type} </div>
										</div>
									</div>:
									item.column[col_index].type.startsWith('formula:')?
									<div style={{color:'white',fontSize:10,width:'100%',display:'flex',
										justifyContent:(item.justifyContent!==undefined&&item.justifyContent[col_index])?item.justifyContent[col_index]:'flex-start',
										alignItems:(item.alignItems!==undefined&&item.alignItems[col_index])?item.justifyContent[col_index]:'center'
									}}>{item.answer[row_index][col_index]}</div>:
									<div style={{height:'100%',color:'white',width:'100%',display:'flex',
										justifyContent:(item.justifyContent!==undefined&&item.justifyContent[col_index])?item.justifyContent[col_index]:'flex-start',
										alignItems:(item.alignItems!==undefined&&item.alignItems[col_index])?item.justifyContent[col_index]:'center'
									}}>{item.answer[row_index][col_index]}</div>
								}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

