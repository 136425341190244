import React from "react"
import StampView from './StampView'
import ProjectView from './ProjectView'
import PhotoView from './PhotoView'
import TableView from './TableView'
import FormulaView from './FormulaView'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import {formatDate}from '../util'

let render_question=(quest,q_index,item,index,parse_form,tab_index,update,project,usertype='user')=>{
	switch(item.type) {
		case 'multiplechoice':
			return (
				<div key={index} style={{display:'flex',flexDirection:'column',flex:1,margin:20, padding:10	}}>
					<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{item.question}</div>
					<Dropdown
						controlClassName='dropdown_control'
						placeholderClassName='dropdown_placeholder'
						options={item.choices} onChange={(e)=>{
							if(item.isLocked)return
							let value=e.value
							if(update){
								let temp=parse_form
								temp[tab_index].form[q_index][index].answer=value;
								update({parse_form:temp})
							}
						}} value={`-${item.answer||''}`} placeholder="Select an option"
					/>
					{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Ans:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
				</div>)
		case 'question_number':
			;
		case 'question_integer':
			;
		case 'question_text':
			return (<div key={index} style={{flex:1,margin:20, padding:10}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{item.question}</div>
				<div style={{display:'flex',marginTop:3,width:'100%',flexDirection:'row',alignItems:'flex-end',borderBottom:'2px solid white',justifyContent:'space-between'}}>
					{!item.prefix||<div style={{marginRight:5, color:'white',fontSize:item.fixsamesize?18:13,textAlign:'right'}}>{item.prefix}&nbsp;</div>}
					<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1)',fontSize:18,width:'80%',minWidth:'80%'}} 
						maxLength={item.max===undefined?null:item.max>0?parseInt(item.max):null}
						placeholder={item.placeholder===undefined?null:item.placeholder}
						onChange={(e)=>{
							if(item.isLocked)return
							let value=e.target.value
							if(item.type==='question_integer')value=value.replace(/[^0-9]/g, '');
							else if(item.type==='question_number')value=value.replace(/[^\d.-]/g,'');
							if(update){
								let temp=parse_form
								temp[tab_index].form[q_index][index].answer=value;
								let update_obj={parse_form:temp}
								if(item.question.replace(/\s/g, '')==='Location:'||item.question.replace(/\s/g, '')==='Location'||item.question.replace(/\s/g, '')=='醫院名稱:'||item.question.replace(/\s/g, '')=='醫院名稱'){
									update_obj.location=value
								}else if(item.question.replace(/\s/g, '')==='Position:'||item.question.replace(/\s/g, '')==='Position'){
									update_obj.position=value
								}else if(item.question.replace(/\s/g, '')==='Type/SerialNo.:'||item.question.replace(/\s/g, '')==='Type/SerialNo.'){
									update_obj.type_serial=value
								}
								update(update_obj)
							}
							
						}} value={(item.answer!==undefined)?item.answer:''}
					/>
				{!item.postfix||<div style={{color:'white',fontSize:item.fixsamesize?18:13,textAlign:'right'}}>{item.postfix}</div>}
				</div>
				{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Ans:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
			</div>);
		case 'project':
			return (
				<div key={index} style={{flex:1,margin:20, padding:10}}>
					<ProjectView update={update} question={item.question} project={project}/>
				</div>)
		case 'yesno':
			return (<div key={index} style={{flex:1,margin:20, padding:10}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{item.question}</div>
				<div style={{display:'flex',flexDirection:'row'}}>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='yes')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='yes';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='yes'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='yes'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> Yes </div>
					</div>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='no')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='no';
							
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='no'?'red':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='no'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> No </div>
					</div>
				</div>
				{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Ans:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
			</div>);
		case 'yesnona':
			return (<div key={index} style={{flex:1,margin:20, padding:10}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{item.question}</div>
				<div style={{display:'flex',flexDirection:'row'}}>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='yes')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='yes';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='yes'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='yes'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> Yes </div>
					</div>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='no')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='no';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='no'?'red':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='no'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> No </div>
					</div>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='na')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='na';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='na'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='na'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> NA </div>
					</div>
				</div>
				{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Ans:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
			</div>);
		case 'passfail':
			return (<div key={index} style={{flex:1,margin:20, padding:10}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{item.question}</div>
				<div style={{display:'flex',flexDirection:'row'}}>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='pass')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='pass';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='pass'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='pass'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> Pass </div>
					</div>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='fail')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='fail';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='fail'?'red':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='fail'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> Fail </div>
					</div>
				</div>
				{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Ans:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
			</div>);
		case 'passfailna':
			return (<div key={index} style={{flex:1,margin:20, padding:10}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{item.question}</div>
				<div style={{display:'flex',flexDirection:'row'}}>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='pass')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='pass';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='pass'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='pass'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> Pass </div>
					</div>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='fail')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='fail';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='fail'?'red':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='fail'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> Fail </div>
					</div>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='na')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='na';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='na'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='na'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> NA </div>
					</div>
				</div>
				{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Ans:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
			</div>);
		case '合格不合格不適用':
			return (<div key={index} style={{flex:1,margin:20, padding:10}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{item.question}</div>
				<div style={{display:'flex',flexDirection:'row'}}>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='合格')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='合格';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='合格'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='合格'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> 合格 </div>
					</div>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='不合格')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='不合格';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='不合格'?'red':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='不合格'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> 不合格 </div>
					</div>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='不適用')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='不適用';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='不適用'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='不適用'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> 不適用 </div>
					</div>
				</div>
				{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Ans:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
			</div>);
		case '已補充不用補充':
			return (<div key={index} style={{flex:1,margin:20, padding:10}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{item.question}</div>
				<div style={{display:'flex',flexDirection:'row'}}>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='已補充')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='已補充';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='已補充'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='已補充'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> 已補充 </div>
					</div>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='不用補充')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='不用補充';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='不用補充'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='不用補充'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> 不用補充 </div>
					</div>
				</div>
				{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Ans:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
			</div>);
		case 'donena':
			return (<div key={index} style={{flex:1,margin:20, padding:10}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{item.question}</div>
				<div style={{display:'flex',flexDirection:'row'}}>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='done')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='done';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='done'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='done'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> Done </div>
					</div>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='na')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='na';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='na'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='na'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> NA </div>
					</div>
				</div>
				{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Ans:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
			</div>);
		case '已更換不用更換':
			return (<div key={index} style={{flex:1,margin:20, padding:10}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{item.question}</div>
				<div style={{display:'flex',flexDirection:'row'}}>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='已更換')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='已更換';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='已更換'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='已更換'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> 已更換 </div>
					</div>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='不用更換')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='不用更換';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='不用更換'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='不用更換'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> 不用更換 </div>
					</div>
				</div>
				{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Ans:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
			</div>);
		case 'okna':
			return (<div key={index} style={{flex:1,margin:20, padding:10}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{item.question}</div>
				<div style={{display:'flex',flexDirection:'row'}}>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='ok')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='ok';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='ok'?'rgba(68, 215, 182, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='ok'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> OK </div>
					</div>
					<div className='mousehover' onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form;
							if(temp[tab_index].form[q_index][index].answer==='na')
								temp[tab_index].form[q_index][index].answer=''
							else
								temp[tab_index].form[q_index][index].answer='na';
							update({parse_form:temp});
						}
					}} style={{display:'flex',backgroundColor:item.answer==='na'?'rgba(125, 218, 255, 1.0)':'rgba(0,95,126,1.0)',alignItems:'center',justifyContent:'center',marginTop:10,width:80,height:40,marginLeft:2}}>
						<div style={{color:item.answer==='na'?'white':'rgba(0,126,167,1.0)',fontSize:20}}> NA </div>
					</div>
				</div>
				{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Ans:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
			</div>);
		case 'text':
			return (<div key={index} style={{flex:1,margin:20, padding:10,marginTop:0,marginBottom:0}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",}}>{item.question}</div>
			</div>);
		case 'contract_visit':
			return (<div key={index} style={{flex:1,margin:20, padding:10}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>Contract Visit:</div>
				<div style={{display:'flex',marginTop:3,width:'100%',flexDirection:'row',alignItems:'flex-end',borderBottom:'2px solid white'}}>
					<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1)',fontSize:18,flex:3,textAlign:'right'}} 
						placeholder={item.placeholder===undefined?null:item.placeholder}
						type = "number"
						onChange={(e)=>{
							let value=e.target.value
							value=value.replace(/[^0-9]/g, '');
							if(update){
								let temp=parse_form
								temp[tab_index].form[q_index][index].answer[0]=value;
								update({parse_form:temp})
							}
						}} value={(item.answer[0]!==undefined)?item.answer[0]:''}
						maxLength = "5"
					/>
					<div style={{color:'white',fontSize:18,flex:1,textAlign:'center'}}>/</div>
					<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1)',fontSize:18,flex:3}} 
						placeholder={item.placeholder===undefined?null:item.placeholder}
						type = "number"
						onChange={(e)=>{
							let value=e.target.value
							value=value.replace(/[^0-9]/g, '');
							if(update){
								let temp=parse_form
								temp[tab_index].form[q_index][index].answer[1]=value;
								update({parse_form:temp})
							}
						}} value={(item.answer[1]!==undefined)?item.answer[1]:''}
						maxLength = "5"
					/>
				</div>
				{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Ans:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer[0]}/{item.pre_answer[1]}</div></div>}
			</div>);
		case 'stamp':
			return (<StampView key={index} photo={item.answer} setphoto={(photo)=>{
				if(update){
					let temp=parse_form;
					temp[tab_index].form[q_index][index].answer=photo;
					update({parse_form:temp});
				}
			}}/>);
		case 'date':
			if(usertype==='admin')
				return (<div key={index} style={{flex:1,margin:20, padding:10}}>
					<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{(item.question===undefined)?'Date:':item.question}</div>
					<div style={{marginTop:3,width:'100%',flexDirection:'row',alignItems:'flex-end',borderBottom:'2px solid white',}}>
						<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1)',fontSize:18,minWidth:'80%'}} 
							maxLength={10}
							placeholder={'Date....'}
							onChange={(e)=>{
								if(item.isLocked)return
								let value=e.target.value
								if(update){
									let temp=parse_form
									temp[tab_index].form[q_index][index].answer=value;
									update({parse_form:temp})
								}
								
							}} value={(item.answer!==undefined)?item.answer.substring(0, 10):''}
						/>
						{(item.answer===undefined||item.answer==='')&&<div>&nbsp;</div>}
					</div>
					{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Date:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
		    	</div>)
			else return (<div className='mousehover' key={index} style={{flex:1,margin:20, padding:10}} onClick={()=>{
						if(update){
							if(item.isLocked)return
							let temp=parse_form
							temp[tab_index].form[q_index][index].answer=new Date();
							update({parse_form:temp})
						}
					}}>
				<div style={{width:'100%',borderBottom:'2px solid white'}}>
					<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{(item.question===undefined)?'Date:':item.question}</div>
					<div style={{width:'100%',marginTop:3,color:'rgba(125, 218, 255, 1)',fontSize:18,flex:1}} >
						{(item.answer!==undefined)?`${formatDate(new Date(item.answer))}`:' '}
					</div>
					{(item.answer===undefined||item.answer==='')&&<div>&nbsp;</div>}
				</div>
				{!item.pre_answer||<div style={{color:'#a3f7bf',fontSize:18,textAlign:'left',display:'flex',flexDirection:'row'}}>Pre Date:&nbsp;<div style={{fontWeight:'bold'}}>{item.pre_answer}</div></div>}
			</div>);
		case 'photo':
			return(<PhotoView key={index} data={item}/>);
		case 'multipleoptions':
			return (<div key={index} style={{flex:1,margin:20, padding:10,marginTop:0,marginBottom:0}}>
				<div style={{color:'white',textAlign:'left',fontSize: 20,fontWeight: "bold",marginTop:7}}>{item.question}</div>
				<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
				{item.choices.map((ITEM,INDEX)=>(
					<div className='mousehover' key={INDEX} style={{display:'flex',flexDirection:'row',margin:10}} onClick={()=>{
						if(item.isLocked)return
						if(update){
							let temp=parse_form;

							if(item.lock){
								for(let id of item.lock){
									let fd=temp
									for(let f in fd){
										let form = fd[f].form
										if(form)
											for(let i in form)
												for(let j in form[i]){
													if(form[i][j].id!==undefined){
														if(form[i][j].id===id){
															if(temp[tab_index].form[q_index][index].answer===undefined||temp[tab_index].form[q_index][index].answer[INDEX]===false)
																form[i][j].isLocked=true
															else
																form[i][j].isLocked=false
															if(form[i][j].type==='table'){
																for (let m in form[i][j].answer)
																	for(let n in form[i][j].answer[m]){
																		if(form[i][j].column&&form[i][j].column[n]&&form[i][j].column[n].type!=='text')
																			form[i][j].answer[m][n]=''
																	}
															}
														}
													}
												}
									}
								}
							}


							if(temp[tab_index].form[q_index][index].answer===undefined){
								temp[tab_index].form[q_index][index].answer=Array(item.choices.length).fill(false)
								temp[tab_index].form[q_index][index].answer[INDEX]=true;
							}else temp[tab_index].form[q_index][index].answer[INDEX]=!temp[tab_index].form[q_index][index].answer[INDEX];
							update({parse_form:temp});
						}
					}}>
						<div style={{display:'flex',marginLeft:20,marginRight:20,width:26,height:26,borderRadius:13,border:'1px solid rgba(125,218,255,.7)',justifyContent:'center',alignItems:'center'}}>
							<div style={{width:20,height:20,borderRadius:10,backgroundColor:item.answer===undefined?'transparent':item.answer[INDEX]?'rgba(0, 218, 195, 1.0)':'transparent'}}></div>
						</div>
						<div style={{color:'rgba(125, 218, 255, 1)',fontSize:18}}> {ITEM.value} </div>
					</div>
				))}
				</div>
			</div>);
		case 'table':
			return(
				<TableView key={index} form={parse_form?parse_form[tab_index].form:null} data={item} setanswer={(answer)=>{
					if(update){
						if(item.isLocked)return
						let temp=parse_form;
						temp[tab_index].form[q_index][index].answer=answer;
						update({parse_form:temp});
					}
				}} setform={(form)=>{
					if(update){
						let temp=parse_form;
						temp[tab_index].form=form
						update({parse_form:temp});
					}
				}}/>
			);
		case 'formula':
			return(
				<FormulaView key={index} tab_index={tab_index} form={parse_form} data={item} setanswer={(answer)=>{
					if(update){
						if(item.isLocked)return
						let temp=parse_form;
						temp[tab_index].form[q_index][index].answer=answer;
						update({parse_form:temp});
					}
				}}/>
			);
		default:
	    	return null;
	}
}






function parse(definition){
	if(definition===undefined||definition.length===0)return[]
	let LINES=definition.split('LINE')
	let result=[]
	for (let k in LINES){
		let questions= LINES[k].split('BREAK')
		let array=[]
		for (let i in questions){
			let obj={}
			let lines=questions[i].replace(/\r?\n|\r/g,'').split('#')
			for (let j in lines){
				if(lines[j].startsWith('type:')){
					obj.type=lines[j].split('type:')[1].replace(/\s/g, '');
					if(obj.type==='yesno')obj.answer='yes'
					else if(obj.type==='yesnona')obj.answer='yes'
					else if(obj.type==='passfail')obj.answer='pass'
					else if(obj.type==='passfailna')obj.answer='pass'
					else if(obj.type==='合格不合格不適用')obj.answer='合格'
					else if(obj.type==='已補充不用補充')obj.answer='已補充'
					else if(obj.type==='已更換不用更換')obj.answer='已更換'
					else if(obj.type==='donena')obj.answer='done'
					else if(obj.type==='okna')obj.answer='ok'
					else if(obj.type==='multipleoptions')obj.choices=[]
					else if(obj.type==='table'){
						obj.column=[]
						obj.noofrow=1
						obj.answer=[[]]
					}else if (obj.type==='contract_visit')obj.answer=[]
				}else if(lines[j].startsWith('question:')){
					obj.question=lines[j].split('question:')[1]
				}else if(lines[j].startsWith('choices:')){
					obj.choices=lines[j].split('@').map((x)=>{return {value:x}})
					obj.choices.shift()
				}else if(lines[j].startsWith('placeholder:')){
					obj.placeholder=lines[j].split('placeholder:')[1]
				}else if(lines[j].startsWith('min:')){
					obj.min=lines[j].split('min:')[1]
				}else if(lines[j].startsWith('max:')){
					obj.max=lines[j].split('max:')[1]
				}else if(lines[j].startsWith('prefix:')){
					obj.prefix=lines[j].split('prefix:')[1]
				}else if(lines[j].startsWith('postfix:')){
					obj.postfix=lines[j].split('postfix:')[1]
				}else if(lines[j].startsWith('remarkenable')){
					obj.remarkenable=true
				}else if(lines[j].startsWith('noheader')){
					obj.noheader=true
				}else if(lines[j].startsWith('default:')){
					if(obj.type==='table'){
						let temp=lines[j].split('default:')[1].split('@')
						temp.shift()
						for (let i in temp){
							if(i<obj.answer.length){
								obj.answer[i]=temp[i].split('$')
								obj.answer[i].shift()
							}
							
						}
					}else if(obj.type!=='multipleoptions')
						obj.answer=lines[j].split('default:')[1]
				}else if(lines[j].startsWith('fixsamesize')){
					obj.fixsamesize=true
				}else if(lines[j].startsWith('column:')){
					obj.column=lines[j].split('@').map((x)=>{return {value:x}})
					for(let col in obj.column){
						obj.column[col].type='text'
						obj.column[col].flex=1
						let temp=obj.column[col].value.split('$')
						obj.column[col].value=temp[0]
						if(temp.length>1){
							let temp2=temp[1].split('%')
							obj.column[col].type=temp2[0].replace(' ','')
							if(temp2.length>1)
								obj.column[col].flex=parseInt(temp2[1],10)||1
						}
					}
					obj.column.shift()
				}else if(lines[j].startsWith('noofrow:')){
					obj.noofrow=parseInt(lines[j].split('noofrow:')[1],10)||0
					obj.answer=[...Array(obj.noofrow).keys()].map((x)=>{return []})
				}else if(lines[j].startsWith('photoid:')){
					obj.photoid=lines[j].split('photoid:')[1].replace(/\s/g, '');
				}else if(lines[j].startsWith('id:')){
					obj.id=lines[j].split('id:')[1].replace(/\s/g, '');
				}else if(lines[j].startsWith('formula:')){
					obj.formula=lines[j].split('formula:')[1].replace(/\s/g, '');
				}else if(lines[j].startsWith('roundup:')){
					obj.roundup=parseInt(lines[j].split('roundup:')[1].replace(/\s/g, ''),10)||3;
				}else if(lines[j].startsWith('mergeTop:')){
					let arr = lines[j].split('mergeTop:')[1].split(',')
					let temp=[]
					for (let i of arr)
						temp[parseInt(i,10)||0]=true
					obj.mergeTop=temp
				}else if(lines[j].startsWith('mergeBottom:')){
					let arr = lines[j].split('mergeBottom:')[1].split(',')
					let temp=[]
					for (let i of arr)
						temp[parseInt(i,10)||0]=true
					obj.mergeBottom=temp
				}else if(lines[j].startsWith('mergeLeft:')){
					let arr = lines[j].split('mergeLeft:')[1].split(',')
					let temp=[]
					for (let i of arr)
						temp[parseInt(i,10)||0]=true
					obj.mergeLeft=temp
				}else if(lines[j].startsWith('mergeRight:')){
					let arr = lines[j].split('mergeRight:')[1].split(',')
					let temp=[]
					for (let i of arr)
						temp[parseInt(i,10)||0]=true
					obj.mergeRight=temp
				}else if(lines[j].startsWith('alignItems:')){
					let arr = lines[j].split('alignItems:')[1].split(',')
					obj.alignItems=arr
				}else if(lines[j].startsWith('justifyContent:')){
					let arr = lines[j].split('justifyContent:')[1].split(',')
					obj.justifyContent=arr
				}else if(lines[j].startsWith('flex:')){
					obj.flex=parseInt(lines[j].split('flex:')[1],10)||1
				}else if(lines[j].startsWith('lock:')){
					let arr = lines[j].split('lock:')[1].split(',')
					obj.lock=arr
				}
			}
			if(obj.type!==undefined)
				array.push(obj)
		}
		if(array.length>0)
			result.push(array)
	}
	return result	
}
export function parse_form(tabs){
	let result=[]
	for (let i in tabs){
		result.push({name:tabs[i].name,form:parse(tabs[i].definition)})
	}
	return result
}


export function render_preview(definition,parse_form,tab_index,update,project,usertype='user'){
	var result
	if(parse_form!==undefined&&parse_form!==null&&parse_form.length>0){
		result=parse_form[tab_index].form
	}else
		result=parse(definition)
	return(
		<div style={{width:'100%'}}>
			{result.map((quest,q_index)=>(
				<div key={`${q_index}L`} style={{display:'flex',flexDirection:'row',width:'100%',}}>
					{quest.map((item,index)=>(
						<div key={index} style={{display:'flex',flex:item.flex===undefined?1:item.flex,flexDirection:'column'}}>
							{render_question(quest,q_index,item,index,parse_form,tab_index,update,project,usertype)}
							{!item.remarkenable||
								<div style={{flex:1,margin:20,padding:10}}>
									<div style={{fontSize:18,color:'rgba(125,218,255,0.8)'}}>Remarks:</div>
									<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',width:'100%', fontSize:18,color:'rgba(125,218,255,1)',borderBottom:'1px solid rgba(125,218,255,.6)'}}
										onChange={(e)=>{
											let value=e.target.value
											if(update){
												let temp=parse_form
												temp[tab_index].form[q_index][index].remarks=value;
												update({parse_form:temp})
											}
										}} value={update?parse_form[tab_index].form[q_index][index].remarks?parse_form[tab_index].form[q_index][index].remarks:'':' '}	
									/>
								</div>
							}
						</div>
					))}
				</div>
			))}
		</div>
	);
}
