import store from './store'

let control=store.getState().firstReducer
const server_ip=control.server_ip

export function login_api(cred){
	return new Promise(resolve => {
		fetch(`${server_ip}/api/login`, {
			method: 'POST',
			headers: {
		    	Accept: 'application/json',
		    	'Content-Type': 'application/json',
		    	isweb:'true',
			},
			body: JSON.stringify(cred),
		}).then((res,err)=>{
	  		if(err){
	  			console.log('err----------------'+err);
	  			resolve(null)
	  		}
	  		if(res) {return res;}
		}).then((response) => response.json())
		.then((responseJson) => {
			resolve(responseJson)
		}).catch((error) => {
		    console.log('server ERROR: '+ error);
		    resolve({result:'fail',message:'Check internet connection'})
		});
	});
}
function get_request(token,url){
	return new Promise(resolve => {
		fetch(`${server_ip}${url}`, {
			method: 'GET',
			headers: {
		    	Accept: 'application/json',
		    	'Content-Type': 'application/json',
		    	'Authorization':`${token}`,
		    	isweb:'true',
			},
		}).then((res,err)=>{
	  		if(err){
	  			console.log('err----------------'+err);
	  			resolve(null)
	  		}
			if(res) {return res;}
		}).then((response) => response.json())
		.then((responseJson) => {
			resolve(responseJson)
		}).catch((error) => {
		    console.log('server ERROR: '+ error);
		    resolve({result:'fail',message:'Check internet connection'})
		});
	});
}
function request(type,token,url,obj={}){
	return new Promise(resolve => {
		fetch(`${server_ip}${url}`, {
			method: type,
			headers: {
		    	Accept: 'application/json',
		    	'Content-Type': 'application/json',
		    	'Authorization':`${token}`,
		    	isweb:'true',
			},
			body: JSON.stringify(obj),
		}).then((res,err)=>{
	  		if(err){
	  			console.log('err----------------'+err);
	  			resolve(null)
	  		}
			if(res) {return res;}
		}).then((response) => response.json())
		.then((responseJson) => {
			resolve(responseJson)
		}).catch((error) => {
		    console.log('server ERROR: '+ error);
		    resolve({result:'fail',message:'Check internet connection'})
		});
	});
}
export function user_list(token){
	return get_request(token,'/api/users')
}
export function get_user_by_id(token,_id){
	return get_request(token,`/api/users/id/${_id}`)
}
export function activate_user_by_id(token,_id){
	return get_request(token,`/api/users/activate/${_id}`)
}
export function deactivate_user_by_id(token,_id){
	return get_request(token,`/api/users/deactivate/${_id}`)
}
export function update_user(token,user){
	return request('PUT',token,'/api/users',user)
}
export function register_user(token,user){
	return request('POST',token,'/api/register',user)
}
export function delete_user(token,_id){
	return request('DELETE',token,`/api/users/id/${_id}`)
}
export function create_form_definition(token,form){
	return request('POST',token,'/api/formdefinitions',form)
}
export function update_form_definition(token,form){
	return request('PUT',token,'/api/formdefinitions',form)
}
export function delete_form_definition(token,form_id){
	return request('DELETE',token,'/api/formdefinitions',{form_id:form_id})
}
export function get_form_definition(token,cat){
	return get_request(token,`/api/formdefinitions/${cat}`)
}
export function submit_form(token,form){
	return request('POST',token,'/api/forms',form)
}
export function get_submittedforms(token){
	return get_request(token,`/api/forms/submitted`)
}
export function get_submittedform_by_project(token,project){
	return get_request(token,`/api/forms/submitted/${project}`)
}
export function get_form_by_id(token,_id){
	return get_request(token,`/api/forms/id/${_id}`)
}
export function get_form_by_project(token,project){
	return get_request(token,`/api/forms/project/${project}`)
}
export function get_drafts_list(token){
	return get_request(token,`/api/drafts/list`)
}
export function get_drafts_by_id(token,_id){
	return get_request(token,`/api/drafts/id/${_id}`)
}
export function update_form(token,form){
	return request('PUT',token,'/api/forms',form)
}
export function delete_form(token,_id){
	return request('DELETE',token,`/api/forms/id/${_id}`)
}
export function get_stamp(token){
	return get_request(token,`/api/users/stamp`)
}
export function get_approved_forms(token){
	return get_request(token,`/api/forms/approved`)
}
export function get_approved_forms_by_project(token,project){
	return get_request(token,`/api/forms/approved/project/${project}`)
}
export function approve_form(token,form){
	return request('POST',token,'/api/forms/approved',form)
}
export function approve_form_batch(token,data){
	return request('POST',token,'/api/forms/approved/batch',data)
}
export function revise_form(token,form){
	return request('POST',token,'/api/forms/revise',form)
}

//projects api
export function get_projects_list(token){
	return get_request(token,'/api/projects')
}
export function update_project(token,project){
	return request('PUT',token,'/api/projects',project)
}
export function create_project(token,project){
	return request('POST',token,'/api/projects',project)
}
export function delete_project(token,_id){
	return request('DELETE',token,`/api/projects/id/${_id}`)
}
//photo apis
export function get_photos_by_id(token,_id){
	return get_request(token,`/api/photos/id/${_id}`)
}
export function get_photos_by_photoid(token,_id){
	return get_request(token,`/api/photos/photoid/${_id}`)
}
export function get_photos_list(token){
	return get_request(token,'/api/photos')
}
export function update_photo(token,photo){
	return request('PUT',token,'/api/photos',photo)
}
export function create_photo(token,photo){
	return request('POST',token,'/api/photos',photo)
}
export function delete_photo(token,_id){
	return request('DELETE',token,`/api/photos/id/${_id}`)
}
//report apis
export function get_reports_by_id(token,_id){
	return get_request(token,`/api/reports/id/${_id}`)
}
export function get_reports_list(token){
	return get_request(token,'/api/reports')
}
export function get_reports_by_project(token,project){
	return get_request(token,`/api/reports/project/${project}`)
}
export function create_reports(token,reports){
	return request('POST',token,'/api/reports',reports)
}
export function delete_reports(token,_id){
	return request('DELETE',token,`/api/reports/id/${_id}`)
}
export function reset_password(token,_id){
	return get_request(token,`/api/users/resetpassword/${_id}`)
}


export function convert_to_pdf(token,data){
	return request('POST',token,`/api/pdf/convertpdf`,data)
}

//categories api
export function get_categories_list(token){
	return get_request(token,'/api/categories')
}
export function update_category(token,data){
	return request('PUT',token,'/api/categories',data)
}
export function create_category(token,data){
	return request('POST',token,'/api/categories',data)
}
export function delete_category(token,_id){
	return request('DELETE',token,`/api/categories/${_id}`)
}

//covers api
export function get_covers_by_project_id(token,project_id){
	return get_request(token,`/api/covers/project/${project_id}`)
}
export function update_cover(token,data){
	return request('POST',token,`/api/covers`,data)
}
export function delete_cover(token,_id){
	return request('DELETE',token,`/api/covers/${_id}`)
}






