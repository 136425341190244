import React from "react"
import { connect } from 'react-redux';
import {
	setpageindex,Login
} from '../actions/Actions'
import {
	create_form_definition,update_form_definition,convert_to_pdf,get_photos_by_photoid
} from '../api'
import {render_preview,parse_form} from './RenderForm';
import LoadingPage from './LoadingPage';

import back_icon from "./../assets/images/back.png"
import edit_icon from "./../assets/images/edit_icon.png"
import complete_icon from "./../assets/images/complete.png"
import {formatDate2} from '../util'
import mgistamp from './mgistamp.json'
class CreateFormPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isloading:false,
			tab_index:0,
			error_message:null,

			title:'Form Title (Click to edit)',
			description:'Form Description (Click to edit)',
			tabs:[
				{name:'tab(Click to edit)',definition:
`
#type:question_text#question:Remarks: BREAK LINE
#type:question_text#question:T&C Equipment ID Number: BREAK LINE
				`},
				{name:'Signature',definition:'#type:text #question:StampLINE BREAK #type:stamp BREAK #type:stamp BREAK LINE'},
			]
		}
		
	}

	componentDidMount() {

		let fd=this.props.control.form_definition
		if(fd!==null)
			this.setState({
				title:fd.form_id,
				description:fd.description,
				tabs:fd.definition
			})
	}

	create_new_form =async()=>{
		if(this.state.description===''){
			this.setState({error_message:'Description is empty'});
			return
		} else if(this.state.title===''){
			this.setState({error_message:'Title is empty'});
			return
		}
		if (this.state.isloading)return;
			this.setState({isloading:true})
		let obj={
			form_id:this.state.title,
			description:this.state.description,
			category:this.props.control.category,
			definition:this.state.tabs
		}
		let result=await create_form_definition(this.props.control.token,obj)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));
			return
		}
		if(result===undefined||result===null){
			this.setState({error_message:'Create Form Error',isloading:false});
			return
		}else if(result.result!=='success'){
			this.setState({error_message:result.message,isloading:false});
			return
		}else if(result.result==='success'){
			this.props.dispatch(setpageindex(1));
			return
		}

	}
	update_form=async()=>{
		if(this.state.description===''){
			this.setState({error_message:'Description is empty'});
			return
		} else if(this.state.title===''){
			this.setState({error_message:'Title is empty'});
			return
		}
		let obj={
			form_id:this.state.title,
			description:this.state.description,
			category:this.props.control.category,
			definition:this.state.tabs
		}
		if (this.state.isloading)return;
			this.setState({isloading:true})
		let result=await update_form_definition(this.props.control.token,obj)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));
			return
		}
		if(result===undefined||result===null){
			this.setState({error_message:'Create Form Error',isloading:false});
			return
		}else if(result.result!=='success'){
			this.setState({error_message:result.message,isloading:false});
			return
		}else if(result.result==='success'){
			this.props.dispatch(setpageindex(1));
			return
		}
	}

	calculateWidth=(line,index)=>{
		let total=0
		for(let q of line)
			total+=q.flex||1
		return 100/total*(line[index].flex||1)
	}
	renderCheckBox=(ans,type)=>{
		return`<div style="margin-left:5px;display:flex;flex-direction:row;align-items:center;justify-content:center;">
			<div style="width:7px;min-width:7px;height:7px;min-height:7px; border: 1px solid black;padding:1px;padding-top:2px;display:flex;justify-content:center;align-items:center;">
				${(ans?ans.toLowerCase():'')===type.toLowerCase()?'√':'&nbsp;'}
			</div> 
		</div>
		<div style="margin-left:5px;">${
			type==='NA'
			?'N/A'
			:type==='notinstalled'
			?'Not Installed'
			: type==='50O250N20'
			? 'O2/N2O'
			:type
		}</div>`
	}

	createPDF=async ()=> {
		const pdf_Width=600
		const pdf_Height=850
		const right_margin='15px'
		this.setState({isloading:true})
		let htmlstring=''

		const pdfheight=794
		const font_S=12

		let forms=[{
			form_id:this.state.title,
			description:this.state.description,
			definition:parse_form(this.state.tabs)
		}]
		let page_number=0
		let total_page_number=0
		
		for(let i in forms){
			total_page_number+=1
			for(let tab in forms[i].definition){
				for (let line in forms[i].definition[tab].form){
					for (let question in forms[i].definition[tab].form[line]){
						let q= forms[i].definition[tab].form[line][question]
						switch(q.type){
							case 'pagebreak':{
								total_page_number+=1
								break;
							}
							default: {
								;
							}
						}
					}
				}
			}
		
			for(let i in forms){
				page_number+=1
				let header_html=()=> {return `<div style="width:100%;border-bottom: 2px solid black; padding-top:10px; margin-bottom:5px;">
					<div style="text-align: right;font-size: ${font_S*1.4}px; font-weight: bold; margin-top:10px;">MGI (Far East) Ltd </div>
					<div style="text-align: right;font-size: ${font_S*1.4}px; font-weight: bold; ">MGI Projects Ltd</div>
					<div style="text-align: right;font-size: ${font_S*1.4}px; font-weight: bold; ">MGI Service Ltd</div>
					<div style="text-align: left; font-size: ${font_S*1.4}px; font-weight: bold; " >FORM NO.: ${forms[i].form_id}</div>
					<div style=" display: flex; flex-direction: row; justify-content: space-between; width:100%; ">
						<div style="text-align: left; font-size:${font_S*1.4}px; font-weight: bold; ">${forms[i].description}</div>
						<div style="text-align: right;"> (Sheet ${page_number} of ${total_page_number} Sheet${total_page_number>1?'s':''})</div>
					</div>
				</div>`}
				let questions_html=''
				for(let tab in forms[i].definition){
					for (let line in forms[i].definition[tab].form){
						let line_html=''
						for (let question in forms[i].definition[tab].form[line]){
							let q= forms[i].definition[tab].form[line][question]
							switch(q.type){
								case "project":{
									//if(q.question) line_html+=`<div style="display: table-cell; width:${100/forms[i].definition[tab].form[line].length}%"><b>${q.question}</b>&nbsp;&nbsp;<p style="border-bottom: 1px solid black;display:inline;">${this.state.project}</p></div>&nbsp;&nbsp;&nbsp;&nbsp;`
									break
								}
								case "text":{
									line_html+=`<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;">${q.question==undefined?'<br/>':q.question}</div>`
									break
								}
								case "date":
								case "question_integer":
								case "question_number":
								case 'multiplechoice':
								case 'formula':
								case "question_text":{
									let html=''
									let prefix=''
									let postfix=''
									if(q.prefix!==undefined)
										prefix=`${q.prefix}&nbsp;`
									if(q.postfix!==undefined)
										postfix=`&nbsp;${q.postfix}`
									html+=`
									<div style="width:100%;padding-top:1px;display:flex;flex-direction:row;align-items:center;">
										<div style="display:inline;">${q.question?q.question+'&nbsp;&nbsp':'&nbsp;'}${prefix?`${prefix};&nbsp`:''}</div>
										<div style="flex:1;border-bottom: 1px solid black;display:inline;text-align:${q.justifyContent&&q.justifyContent[0]||'center'};margin-right:${postfix?'2px':right_margin};">${q.answer===undefined?'&nbsp;':(q.type==='date'?formatDate2(new Date(q.answer)):q.answer)}</div>
										<div style="display:inline;margin-right:${right_margin};">&nbsp;${postfix}</div>
									</div>`
									line_html+=`<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;">${html}</div>`
									break
								}
								case "stamp":{
									line_html+=`<div style="display: table-cell; position:relative; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;"><img src="${q.answer}" style="max-height:50px;position:absolute;top:-150px;left:135px;height:45px;z-index:-1;" alt=""></div>`
									//else line_html+=`<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;"></div>`
									break
								}
								case "contract_visit":{
									line_html+=`
										<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;padding-top:1px;">
											<div style="width:100%;padding-top:1px;display:flex;flex-direction:row;align-items:center;">
												Contract Visit:&nbsp;&nbsp;
												<div style="flex:1; border-bottom: 1px solid black; display:inline; text-align:center;margin-right:${right_margin};">
													${q.answer[0]===undefined ? '': q.answer[0] } 
													&nbsp;&nbsp;/&nbsp;&nbsp;
													${q.answer[1]===undefined?'':q.answer[1]}
												</div>
											</div>
										</div>`
									break
								}
								case "multipleoptions":{
									let html=''
									let choices=''
									for (let i in q.choices){
										choices+=this.renderCheckBox((q.answer?q.answer[i]:false)?q.choices[i].value:'',q.choices[i].value)
									}
									html+=`
										<div style="display:flex;width:100%;flex-direction:row;align-items:center;justify-content:flex-start;">
											${q.question}&nbsp;&nbsp;
											${choices}
										</div>`
									line_html+=`<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;padding-top:1px;">${html}</div>`
									break
								}
								case 'photo':{
									let result=await get_photos_by_photoid(this.props.control.token,q.photoid)
									let photo_base64=null
									if(result.message&&result.message=='Unauthorized'){
										this.props.dispatch(Login(false));alert('Session Expired!');return
									}
									if(result!=null&&result.result!==undefined&&result.result!=='fail'&&result.result.length>0){
										photo_base64=result.result[0].base64
									}
									if(photo_base64)line_html+=`<div style="position:relative;display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;"><div style="position:absolute;top:0;left:0;"><img src="${photo_base64}" style="width:100%;" alt="load photo error"></div></div>`
									break
								}
								case "table":{
									let table_html=''
									if(q.question) table_html+=`<div style="width:100%; align-items: center; ">${q.question}</div>`
									
									if(q.column.length>0) {
										let column_html=''
										let total_flex=0;
										for (let col in q.column){
											if(q.column[col].flex!==undefined)total_flex+=q.column[col].flex
											else total_flex+=1
										}
										if(!q.noheader){
											for (let col in q.column){
												column_html+=`<div style="position: relative; display: table-cell; text-align: ${(q.justifyContent!==undefined && q.justifyContent[col]) ? q.justifyContent[col]:'center'} ;vertical-align: middle; width:${100*q.column[col].flex/total_flex}%; min-width:${100*q.column[col].flex/total_flex}%; ">
													<div style="position:absolute; top:0;left:0;right:0;bottom:0;border-style: solid;
														border-width: ${q.mergeTop===undefined?'1px':q.mergeTop[col]?'0':'1px'}  ${q.mergeRight===undefined?'1px':q.mergeRight[col]?'0':'1px'} ${q.mergeBottom===undefined?'1px':q.mergeBottom[col]?'0':'1px'} ${q.mergeLeft===undefined?'1px':q.mergeLeft[col]?'0':'1px'};
													">
													</div>
													<div style="padding:2px">
														${q.column[col].value===''?'&nbsp;':q.column[col].value}
													</div>
												</div>`
											}
											table_html+=`<div style="display: table; table-layout: fixed; width:${100-forms[i].definition[tab].form[line].length}%; min-width:${100-forms[i].definition[tab].form[line].length}%;min-height:12px;">${column_html}</div>`
										}
										for (let z=0;z<q.noofrow;z++){
											column_html=''
											for (let col in q.column){
												
												column_html+=`
													<div style="position: relative; font-size: ${font_S}px; display: table-cell;  text-align: ${(q.justifyContent!==undefined && q.justifyContent[col]) ? q.justifyContent[col]:'left'}; vertical-align: middle; width:${100*q.column[col].flex/total_flex}%; min-width:${100*q.column[col].flex/total_flex}%; ">
														<div style="position:absolute; top:0;left:0;right:0;bottom:0;border-style: solid;
															border-width: ${q.mergeTop===undefined?'1px':q.mergeTop[col]?'0':'1px'}  ${q.mergeRight===undefined?'1px':q.mergeRight[col]?'0':'1px'} ${q.mergeBottom===undefined?'1px':q.mergeBottom[col]?'0':'1px'} ${q.mergeLeft===undefined?'1px':q.mergeLeft[col]?'0':'1px'};
														">
														</div>
														<div style="padding:2px">
															${												
																(q.column[col].type==='check'||q.column[col].type.startsWith('check_setformula'))
																? (q.answer[z][col]?
																	`<div style="display:flex;flex-direction:row;align-items:center;justify-content:center;">
																	<div style="width:7px;min-width:7px;height:7px;min-height:7px; border: 1px solid black;padding:1px;padding-top:2px;display:flex;justify-content:center;align-items:center;">
																		√
																	</div>
																</div>`
																:`<div style="display:flex;flex-direction:row;align-items:center;justify-content:center;">
																	<div style="width:7px;min-width:7px;height:7px;min-height:7px; border: 1px solid black;padding:1px;padding-top:2px;display:flex;justify-content:center;align-items:center;">
																	</div>
																</div>`)
																: (q.column[col].type==='yesno'||q.column[col].type==='yesnona'||q.column[col].type==='done'
																	||q.column[col].type==='na'||q.column[col].type==='notinstalled'||q.column[col].type==='ok'
																	||q.column[col].type==='pass'||q.column[col].type==='passfail'||q.column[col].type==='passfailna'
																	||q.column[col].type==='twinsingle'||q.column[col].type==='donena'
																	||q.column[col].type==='yesminimalno'
																	||q.column[col].type==='合格不合格不適用'||q.column[col].type==='合格不合格'||q.column[col].type==='已更換不用更換'||q.column[col].type==='已補充不用補充'||q.column[col].type==='已調整不用調整'||q.column[col].type==='已調整不用調整不適用'
																	||['合格','不合格','不適用','已通知','完成','CW','HW','CA','VAC','N2','DI','TG','LPG','O2','CO2','H2','HE','NH3','MA4','N20','SA7','50O250N20'].includes(q.column[col].type))
																? ` <div style="display:flex;width:100%;flex-direction:row;align-items:center;justify-content:${(q.justifyContent!==undefined && q.justifyContent[col]) ? q.justifyContent[col]:'flex-start'};">
																	${q.column[col].type.includes('合格')?this.renderCheckBox(q.answer[z][col],'合格'):''}
																	${q.column[col].type.includes('不合格')?this.renderCheckBox(q.answer[z][col],'不合格'):''}
																	${q.column[col].type.includes('已更換')?this.renderCheckBox(q.answer[z][col],'已更換'):''}
																	${q.column[col].type.includes('不用更換')?this.renderCheckBox(q.answer[z][col],'不用更換'):''}
																	${q.column[col].type.includes('已補充')?this.renderCheckBox(q.answer[z][col],'已補充'):''}
																	${q.column[col].type.includes('不用補充')?this.renderCheckBox(q.answer[z][col],'不用補充'):''}
																	${q.column[col].type.includes('已調整')?this.renderCheckBox(q.answer[z][col],'已調整'):''}
																	${q.column[col].type.includes('不用調整')?this.renderCheckBox(q.answer[z][col],'不用調整'):''}
																	${q.column[col].type.includes('不適用')?this.renderCheckBox(q.answer[z][col],'不適用'):''}
																	${q.column[col].type.includes('yes')?this.renderCheckBox(q.answer[z][col],'Yes'):''}
																	${q.column[col].type.includes('twin')?this.renderCheckBox(q.answer[z][col],'Twin'):''}
																	${q.column[col].type.includes('pass')?this.renderCheckBox(q.answer[z][col],'Pass'):''}
																	${q.column[col].type.includes('ok')?this.renderCheckBox(q.answer[z][col],'OK'):''}
																	${q.column[col].type.includes('notinstalled')?this.renderCheckBox(q.answer[z][col],'notinstalled'):''}
																	${q.column[col].type.includes('done')?this.renderCheckBox(q.answer[z][col],'Done'):''}
																	${q.column[col].type.includes('fail')?this.renderCheckBox(q.answer[z][col],'Fail'):''}
																	${q.column[col].type.includes('single')?this.renderCheckBox(q.answer[z][col],'Single'):''}
																	${q.column[col].type.includes('minimal')?this.renderCheckBox(q.answer[z][col],'Minimal'):''}
																	${(q.column[col].type.includes('no')&&q.column[col].type!=='notinstalled')?this.renderCheckBox(q.answer[z][col],'No'):''}
																	${q.column[col].type.includes('na')?this.renderCheckBox(q.answer[z][col],'NA'):''}
																	${['合格','不合格','不適用','已通知','完成','CW','HW','CA','VAC','N2','DI','TG','LPG','O2','CO2','H2','HE','NH3','MA4','N20','SA7','50O250N20'].includes(q.column[col].type)
																		? this.renderCheckBox(q.answer[z][col]?q.column[col].type:'',q.column[col].type)
																		:''
																	}
																</div>`
																: q.column[col].type==='passfailcheck'
																? (q.answer[z][col]==='pass' ? '√' : q.answer[z][col]==='fail' ? '⨉' : '')
																: q.column[col].type==='okna'
																? (q.answer[z][col]==='ok'?'OK':q.answer[z][col]==='na'?'N/A':q.answer[z][col])||'&nbsp;'
																: q.answer[z][col]||'&nbsp;'														
															}
														</div>
													</div>`
											}
											table_html+=`<div style="display: table;table-layout: fixed; width:${100-forms[i].definition[tab].form[line].length}%; min-width:${100-forms[i].definition[tab].form[line].length}%;min-height:12px;">${column_html}</div>`
										}
										
									}
									line_html+=`<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%; min-width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;">${table_html}</div>`
									break
								}
								case 'pagebreak':{
									questions_html+=`<div style="display: table; width:100%;">${line_html}</div>`
									let form_html=`${header_html()}${questions_html}`
									htmlstring+=`<div style="page-break-inside:avoid !important; height:100%; min-height: ${pdfheight}px;width:100%;flex:1;font-size: ${font_S}px;"><div style="padding:40;padding-top:1;padding-bottom:1;">${form_html}</div></div>`
									questions_html='';
									line_html=''

									page_number+=1
									break;
								}
								case 'yesno':
								case 'yesnona':
								case 'passfail':
								case 'passfailna':
								case 'donena':
								case 'okna':
								case 'donena':{
									
									line_html+=`<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%; font-size: ${font_S}px; padding:1px;">
										<div style="width:100%;padding-top:1px;display:flex;flex-direction:row;align-items:center;">
											${q.question?q.question.replace(/  +/g, ' '):''}
											&nbsp;&nbsp;
											<div style="display:flex;width:100%;flex-direction:row;align-items:center;justify-content:flex-start};">
												${q.type.includes('yes')?this.renderCheckBox(q.answer,'Yes'):''}
												${q.type.includes('pass')?this.renderCheckBox(q.answer,'Pass'):''}
												${q.type.includes('ok')?this.renderCheckBox(q.answer,'OK'):''}
												${q.type.includes('notinstalled')?this.renderCheckBox(q.answer,'notinstalled'):''}
												${q.type.includes('done')?this.renderCheckBox(q.answer,'Done'):''}
												${q.type.includes('fail')?this.renderCheckBox(q.answer,'Fail'):''}
												${q.type.includes('minimal')?this.renderCheckBox(q.answer,'Minimal'):''}
												${(q.type.includes('no')&&q.type!=='notinstalled')?this.renderCheckBox(q.answer,'No'):''}
												${q.type.includes('na')?this.renderCheckBox(q.answer,'NA'):''}
											</div>
										</div>
									</div>`
									
									break;
								}
								default: {
									if(q.question) 
										line_html+=`
											<div style="display: table-cell; width:${this.calculateWidth(forms[i].definition[tab].form[line],question)}%;padding-top:1px;">
												<div style=" width:100%; padding-top:1px; display:flex; flex-direction:row;align-items:center; ">
													<p style="display:inline;">${q.question===undefined?'':q.question}&nbsp;&nbsp;&nbsp;&nbsp;</p>
													<div style="flex:1; display:inline; text-align:center;margin-right:${right_margin};">
														${q.answer===undefined?'':q.answer}
													</div>
												</div>
											</div>`
								}
							}
						}
						if(line_html.length>0)

							questions_html+=`<div style="display: table; width:100%;margin-top:0; margin-bottom:0; font-size: ${font_S}px; ">${line_html}</div>`
					}
					questions_html+=``
				}
				let form_html=`${header_html()}${questions_html}`
				let stamp_html=`<img src="${mgistamp.base64}" style="position:relative;width:40px;top:-140px;left:78%;" alt="load header error">`
				htmlstring+=`<div style="page-break-inside:avoid !important; height:100%; min-height: ${pdfheight}px;width:100%;flex:1;font-size: ${font_S}px;"><div style="padding:40;padding-top:1;padding-bottom:1;">${form_html}${stamp_html}</div></div>`
			}
		}
		let pdf_api_call= await convert_to_pdf(this.props.control.token,{html:`<HTML><BODY>${htmlstring}</BODY></HTML>`,form_ids:[]})
		if(pdf_api_call!==undefined)
			window.open(`${this.props.control.server_ip}/pdf/temp/${pdf_api_call.result}`, "_blank")
		else alert('PDF Error!')
		this.setState({isloading:false});
	}



	render() {
		let width=window.innerWidth*.9
		let tabs=this.state.tabs
		return(<div style={{width:'100%',height:'100%',overflowY: 'scroll',}}>
			<div className='center_row'>
				<div className='mousehover center_row' style={{padding:10,width:'20%'}} onClick={()=>this.props.dispatch(setpageindex(1))}>
					<img src={back_icon} style={{width:17,height:17}} alt='back'/>
					<div style={{marginLeft:10, fontSize:25,color:'white',fontWeight:'bold'}}>Back</div>
				</div>
				<div  style={{width:'60%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
					<div className='center_row' style={{opacity:.3}}>
						<img src={edit_icon} style={{width:17,height:17}} alt='edit'/>
						<div style={{color:'white',fontWeight:'bold',fontSize:20,marginLeft:20}}>Add Mode</div>
					</div>
					<input style={{textAlign:'center',backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)', color:"white",fontSize: 22,fontWeight: "bold",marginTop:10,width:'100%'}} value={this.state.title} onChange={(e)=>{this.setState({title:e.target.value})}} placeholder="Form Title"/>
					<input style={{ textAlign:'center',backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)', color:"#00DAC3",fontSize: 18,fontWeight: "bold",marginTop:10,width:'100%'}} value={this.state.description} onChange={(e)=>{this.setState({description:e.target.value})}} placeholder="Form Description"/>	
				</div>
				<div style={{display:'flex',width:'25%',padding:10,justifyContent:'flex-end',flexDirection:'row'}}>
					<div className='mousehover center_row' style={{width:130,padding:10,backgroundColor:'rgba(37, 229, 154, 1.0)',borderRadius:5,marginRight:10,display:'flex',justifyContent:'center',alignItems:'center'}} 
						onClick={()=>{
							if(this.props.control.form_definition===null)
								this.create_new_form()
							else this.update_form()
						}}>
						<img src={complete_icon} style={{width:17,height:17}} alt='Save'/>
						<div style={{color: "white",fontSize: 20,fontWeight:"bold",marginLeft:10}}>Save</div>
					</div>
					<div className='mousehover center_row' style={{width:100,padding:10,backgroundColor:'rgba(37, 229, 254, 1.0)',borderRadius:5,marginRight:10,}} onClick={()=>{this.createPDF()}} >
						<div style={{color: "white",fontSize: 20,fontWeight:"bold",marginLeft:2}}>Preview</div>
					</div>
				</div>
			</div>
			<div style={{display:'flex',flexDirection:'row',minHeight:60,width:'100%',alignItems:'flex-end',justifyContent:'center',marginTop:10}}>
				<div className='moushover' onClick={()=>{
					let tabs=this.state.tabs
					if(tabs.length>1){
						tabs.pop()
						if(this.state.tab_index>=tabs.length)
							this.setState({tabs:tabs,tab_index:tabs.length-1})
						else
							this.setState(tabs)
					}
				}} style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'white',width:40,height:40,borderRadius:20,margin:20}}>
					<div style={{color:"#003459",fontSize: 30,fontWeight: "bold"}}>-</div>
				</div>
				{this.state.tabs.map((item,index)=>(
					<div key={index} className='mousehover' style={{display:'flex',alignItems:'center',justifyContent:'center', height:'90%',marginLeft:3,borderTopLeftRadius:10,borderTopRightRadius:10 ,
						backgroundColor:this.state.tab_index===index?'#44CDD7':'rgba(0, 66, 113, 1.0)',
						width:this.state.tab_index===index?((width-100)/(tabs.length+2)+100):((width-100)/(tabs.length+2))
					}} onClick={()=>{this.setState({tab_index:index})}}>
						<input style={{textAlign:'center', backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)', color:this.state.tab_index===index?"#0052FF":'rgba(125, 218, 255, 1.0)',fontSize: 22,fontWeight: "bold",marginTop:10,width:'100%'}} 
						value={item.name} onChange={(e)=>{
							let tabs=this.state.tabs
							tabs[index].name=e.target.value
							this.setState({tabs:tabs})
						}} placeholder="Tab name"/>
					</div>
				))}
				<div className='moushover' onClick={()=>{
					let tabs=this.state.tabs
					if(tabs.length<=10){
						tabs.push({name:'new tab',definition:""})
						this.setState({tabs:tabs,tab_index:(tabs.length-1)})
					}
				}} style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'white',width:40,height:40,borderRadius:20,margin:20}}>
					<div style={{color:"#003459",fontSize: 30,fontWeight: "bold"}}>+</div>
				</div>
			</div>
			<div style={{display:'flex',flexDirection:'row',width:'100%',height:30,justifyContent:'center',borderTop:'2px solid rgba(5, 102, 141, 1.0)',borderBottom:'2px solid rgba(5, 102, 141, 1.0)',}}>
				<div style={{marginLeft:30,fontSize: 25,fontWeight: "bold",color:'rgba(5, 102, 141, 1.0)'}}>MARKDOWN</div>
			</div>
			<div style={{width:'100%',height:'35%',overflowY: 'scroll',display:'flex',flexDirection:'row'}}>
				<div style={{width:'5%',backgroundColor:'rgba(0, 66, 113, 1.0)',display:"flex",flexDirection:'column'}}>
					{[...Array(150).keys()].map((item,index)=>(
						<div key={index} style={{display:'flex',color:'rgba(125, 218, 255, 1.0)',justifyContent:'center',fontSize: 15,fontWeight: "bold",width:'100%',marginTop:0,marginBottom:0,paddingTop:0,paddingBottom:0}}>
							{index}
						</div>
					))}
				</div>
				<textarea style={{width:'95%',height:'100%',backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',color:'white',textAlign:'left',fontSize: 15,fontWeight: "bold",marginTop:0,marginBottom:0,paddingTop:0,paddingBottom:0}} 
					onChange={(e)=>{
						let tabs=this.state.tabs
						tabs[this.state.tab_index].definition=e.target.value
						this.setState(tabs)
					}} value={this.state.tabs[this.state.tab_index].definition} placeholder="form definition(tab to edit)"></textarea>
			</div>
			<div style={{display:'flex',flexDirection:'row',width:'100%',height:30,justifyContent:'center',borderTop:'2px solid rgba(5, 102, 141, 1.0)',borderBottom:'2px solid rgba(5, 102, 141, 1.0)',}}>
				<div style={{marginLeft:30,fontSize: 25,fontWeight: "bold",color:'rgba(5, 102, 141, 1.0)'}}>PREVIEW</div>
			</div>
			<div style={{width:'100%',height:'35%',overflowY: 'scroll',display:'flex',flexDirection:'row'}}>
				{render_preview(this.state.tabs[this.state.tab_index].definition,null,null,null,null)}
			</div>
			{!this.state.isloading||<LoadingPage/>}
		</div>);
	}
}	

export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(CreateFormPage)
