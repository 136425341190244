import React from "react"
import { connect } from 'react-redux';
import {
	Login,setprojectlist
} from '../actions/Actions'
import {
	get_projects_list,update_project,create_project,delete_project
} from '../api'
import LoadingPage from './LoadingPage'
import add_icon from "./../assets/images/add_icon.png"
import refresh from "./../assets/images/refresh.png"
import more_icon from "./../assets/images/more.png"
import search_icon from "./../assets/images/search_white.png"
import photo_white from './../assets/images/client_white.png'
import up_icon from "./../assets/images/up.png"

class ProjectListPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isloading:true,
			scroll_refreshing:false,
			isaddmode:false,

			new_project:'',
			new_client_id:'',
			new_client_address:'',
			new_client_contact:'',
			new_quotation_info:'',

			text_search:''
		}
	}

	componentDidMount=async()=> {
		this.get_projects(true)
	}
	get_projects=async(force=false)=>{
		if(!force&&this.state.isloading)return
		this.setState({isloading:true})
		let result= await get_projects_list(this.props.control.token)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}else if(result===undefined||result===null){alert('Get project error');this.setState({isloading:false});return}
		else if(result.result!==undefined&&result.result!=='fail'){
			let dict={}
			for(let i of result.result){
				dict[i._id]=i
			}
			this.props.dispatch(setprojectlist(result.result.map((x)=>{return {...x,value:x.project_id,_id:x._id}}),dict))
		}
		this.setState({isloading:false})
	}
	updateproject=async(proj)=>{
		if(proj.value===''){alert('project name is empty');return;}
		if(proj.client_id===''){alert('client name is empty');return;}
		if(proj.client_address===''){alert('client address is empty');return;}
		if(proj.client_contact===''){alert('client contact is empty');return;}
		if(proj.quotation_info===''){alert('quotation info is empty');return;}
		if(this.state.isloading)return
		this.setState({isloading:true})
		let result= await update_project(this.props.control.token,proj)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));
			alert('Session Expired!')
			return
		}else if(result===undefined||result===null){alert('Insert project error');this.setState({isloading:false});return}
		else if(result.result!=='success'){alert(result.message);this.setState({isloading:false});return}
		else if(result.result!==undefined&&result.result!=='fail'){
			this.get_projects(true)
		}
		this.setState({isloading:false})
	}
	createproject=async()=>{
		if(this.state.new_project===''){alert('project name is empty');return;}
		if(this.state.new_client_id===''){alert('client name is empty');return;}
		if(this.state.new_client_address===''){alert('client address is empty');return;}
		if(this.state.new_client_contact===''){alert('client contact is empty');return;}
		if(this.state.new_quotation_info===''){alert('quotation info is empty');return;}
		if(this.state.isloading)return
		this.setState({isloading:true})
		let result= await create_project(this.props.control.token,{
			client_id:this.state.new_client_id,
			project_id:this.state.new_project,
			client_address:this.state.new_client_address,
			client_contact:this.state.new_client_contact,
			quotation_info:this.state.new_quotation_info,
		})
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}else if(result===undefined||result===null){alert('Add project error');this.setState({isloading:false});return}
		else if(result.result!=='success'){alert(result.message);this.setState({isloading:false});return}
		else if(result.result!==undefined&&result.result!=='fail'){
			this.get_projects(true)
			this.setState({new_project:'',new_client_id:'',new_client_address:'',new_client_contact:'',new_quotation_info:'',isaddmode:false})
		}
		this.setState({isloading:false})
	}
	deleteproject=async(_id)=>{
		if(this.state.isloading)return
		this.setState({isloading:true})
		let result= await delete_project(this.props.control.token,_id)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}else if(result===undefined||result===null){alert('Delete project error');this.setState({isloading:false});return}
		else if(result.result!=='success'){alert(result.message);this.setState({isloading:false});return}
		else if(result.result!==undefined&&result.result!=='fail'){
			this.get_projects(true)
			this.setState({ispopup:false})
		}
		this.setState({isloading:false})
	}

	hanele_refresh=()=>{
		this.get_projects()
	}
	render(){
		let control=this.props.control
		return(<div style={{width:'100%',height:'100%',color:'white',overflowY: 'scroll'}}>
			<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
				<div style={{backgroundColor:'#007EA7',borderRadius:5,marginRight:2,width:'80%',height:46,marginLeft:10,display:'flex',flexDirection:'row',alignItems:'center'}}>
					<img src={search_icon} style={{marginLeft:10,marginRight:10,width:30,height:30}} alt='search'/>
					<input style={{ backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)', color:"#98E2FF",width:'95%',fontSize: 19,marginLeft:5}} value={this.state.text_search} onChange={(e)=>{this.setState({text_search:e.target.value})}} placeholder="Search for project...."/>
				</div>
			</div>
			<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
				<div style={{display:'flex',flexDirection:'row',width:'80%',alignItems:'center',justifyContent:'space-between'}}>
					<div className='center_row'>
						<img src={photo_white} style={{marginLeft:10,marginRight:10,width:47,height:47}} alt='photo_white'/>
						<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",}}>Project Management</div>
						<div className='mousehover' onClick={()=>{this.setState({isaddmode:!this.state.isaddmode})}} style={{display:'flex',flexDirection:'row',backgroundColor:'#7DDAFF',borderRadius:5,marginLeft:20,height:40,paddingLeft:20,paddingRight:20,justifyContent:'center',alignItems:'center'}}>
							<img src={add_icon} style={{width:17,height:17}} alt='add_icon'/>
							<div style={{color: "white",fontSize: 14,fontWeight: "bold",marginLeft:5}}>ADD</div>
						</div>
					</div>
					
					<div onClick={this.hanele_refresh} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
						<img className='mousehover' src={refresh} style={{width:20,height:20}} alt='refresh'/>
					</div>
				</div>
			</div>
			{!this.state.isaddmode||<div style={{display:'flex',marginTop:100,width:'100%',justifyContent:'center',alignItems:'center'}}>
				<div style={{display:'flex',flexDirection:'column',width:'90%',justifyContent:'center',alignItems:'center'}}>
					<div style={{marginTop:10,marginBottom:10,borderBottomWidth:1,borderColor:'white',width:'100%',alignItems:'center'}}>
						<div style={{color:'white',fontSize:30,fontWeight:'bold'}}>New Project</div>
					</div>
					<div style={{display:'flex',width:'100%',flexDirection:'row',marginTop:10}}>
						<div style={{color:'white',fontSize:20,fontWeight:'bold',flex:1}}>Project:</div>
						<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,flex:1}}  onChange={(e)=>{this.setState({new_project:e.target.value})}} value={this.state.new_project} placeholder="Project"/>
					</div>
					<div style={{display:'flex',width:'100%',flexDirection:'row',marginTop:10}}>
						<div style={{color:'white',fontSize:20,fontWeight:'bold',flex:1}}>Client Name:</div>
						<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,flex:1}}  onChange={(e)=>{this.setState({new_client_id:e.target.value})}} value={this.state.new_client_id} placeholder="Client Name"/>
					</div>
					<div style={{display:'flex',width:'100%',flexDirection:'row',marginTop:10}}>
						<div style={{color:'white',fontSize:20,fontWeight:'bold',flex:1}}>Client Address:</div>
						<textarea rows={5} style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,flex:1}} onChange={(e)=>{this.setState({new_client_address:e.target.value})}}value={this.state.new_client_address} placeholder="Address"></textarea>
					</div>
					<div style={{display:'flex',width:'100%',flexDirection:'row',marginTop:10}}>
						<div style={{color:'white',fontSize:20,fontWeight:'bold',flex:1}}>Contact Person:  </div>
						<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,flex:1}}  onChange={(e)=>{this.setState({new_client_contact:e.target.value})}} value={this.state.new_client_contact} placeholder="Contact Person"/>
					</div>
					<div style={{display:'flex',width:'100%',flexDirection:'row',marginTop:10}}>
						<div style={{color:'white',fontSize:20,fontWeight:'bold',flex:1}}>Quotation Info.:</div>
						<textarea rows={5} style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,flex:1}} onChange={(e)=>{this.setState({new_quotation_info:e.target.value})}}value={this.state.new_quotation_info} placeholder="Quotation Info."></textarea>
					</div>
					<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center'}}>
						<div className='mousehover' style={{display:'flex',margin:10,width:150,height:60,justifyContent:'center',alignItems:'center',borderRadius:10,backgroundColor:'#C7E9F7',fontSize:30,color:'#004271'}} onClick={()=>{
							this.setState({isaddmode:false})
						}}>
							Cancel
						</div>
						<div className='mousehover' style={{display:'flex',margin:10,width:150,height:60,justifyContent:'center',alignItems:'center',borderRadius:10,fontSize:30,color:'#004271',backgroundColor:'#7DDAFF'}} onClick={()=>{
							this.createproject()
						}}>
							Save
						</div>
					</div>
				</div>
			</div>}
			{this.isaddmode||<div>{control.project_list.map((proj,proj_index)=>(
				<div key={proj_index} style={{width:'100%'}}>
					{!this.state.text_search===''||!proj.project_id.toLowerCase().includes(this.state.text_search.toLowerCase())||<div  key={proj_index} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',justifyContent:'center'}}>
						<div className='mousehover' onClick={()=>{
							let pro_list=control.project_list
							pro_list[proj_index].isselected=!pro_list[proj_index].isselected
							this.props.dispatch(setprojectlist(pro_list))
						}} style={{display:'flex',flexDirection:'row',width:'80%',borderBottom:"1px solid rgba(0, 126, 167, 1.0)",borderTop:"1px solid rgba(0, 126, 167, 1.0)",padding:10,alignItems:'center'}}>
							<div style={{color:'rgba(68,204,215,1.0)',fontSize:20,fontWeight:'bold',width:'95%'}}>{proj.project_id}</div>						 		
							{!proj.isselected?<img src={more_icon} style={{width:17,height:17}} alt='more_icon'/>:
							<img src={up_icon} style={{width:17,height:17}} alt='up_icon'/>}
						</div>
						{!proj.isselected||
							<div style={{display:'flex',flexDirection:'column',width:'90%',marginTop:10,marginBottom:10,borderBottomWidth:'1px solid white',alignItems:'center',justifyContent:'center'}}>
								
								<div style={{display:'flex',width:'100%',flexDirection:'row',marginTop:10}}>
									<div style={{color:'white',fontSize:20,fontWeight:'bold',flex:1}}>Project:</div>
									<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,flex:1}}  onChange={(e)=>{
										let pro_list=control.project_list
										pro_list[proj_index].project_id=e.target.value
										this.props.dispatch(setprojectlist(pro_list))
									}} value={proj.project_id} placeholder="Project"/>
								</div>
								<div style={{display:'flex',width:'100%',flexDirection:'row',marginTop:10}}>
									<div style={{color:'white',fontSize:20,fontWeight:'bold',flex:1}}>Client Name:</div>
									<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,flex:1}}  onChange={(e)=>{
										let pro_list=control.project_list
										pro_list[proj_index].client_id=e.target.value
										this.props.dispatch(setprojectlist(pro_list))
									}} value={proj.client_id} placeholder="Client Name"/>
								</div>
								<div style={{display:'flex',width:'100%',flexDirection:'row',marginTop:10}}>
									<div style={{color:'white',fontSize:20,fontWeight:'bold',flex:1}}>Client Address:</div>
									<textarea rows={5} style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,flex:1}} onChange={(e)=>{
										let pro_list=control.project_list
										pro_list[proj_index].client_address=e.target.value
										this.props.dispatch(setprojectlist(pro_list))
									}}value={proj.client_address} placeholder="Address"></textarea>
								</div>
								<div style={{display:'flex',width:'100%',flexDirection:'row',marginTop:10}}>
									<div style={{color:'white',fontSize:20,fontWeight:'bold',flex:1}}>Contact Person:  </div>
									<input style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,flex:1}}  onChange={(e)=>{
										let pro_list=control.project_list
										pro_list[proj_index].client_contact=e.target.value
										this.props.dispatch(setprojectlist(pro_list))
									}} value={proj.client_contact} placeholder="Contact Person"/>
								</div>
								<div style={{display:'flex',width:'100%',flexDirection:'row',marginTop:10}}>
									<div style={{color:'white',fontSize:20,fontWeight:'bold',flex:1}}>Quotation Info.:</div>
									<textarea rows={5} style={{backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,flex:1}} onChange={(e)=>{
										let pro_list=control.project_list
										pro_list[proj_index].quotation_info=e.target.value
										this.props.dispatch(setprojectlist(pro_list))
									}}value={proj.quotation_info} placeholder="Quotation Info."></textarea>
								</div>
								<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center'}}>
									<div className='mousehover' style={{display:'flex',margin:10,width:150,height:60,justifyContent:'center',alignItems:'center',borderRadius:10,backgroundColor:'#C7E9F7',fontSize:30,color:'#004271'}} onClick={()=>{
										this.updateproject(proj)
									}}>
										Save
									</div>
									<div className='mousehover' style={{display:'flex',margin:10,width:150,height:60,justifyContent:'center',alignItems:'center',borderRadius:10,fontSize:30,color:'#004271',backgroundColor:'#FF4F4F'}} onClick={()=>{
										this.setState({delete_id:proj._id,ispopup:true})
									}}>
										Delete
									</div>
								</div>
							</div>
						}
					</div>}
				</div>
			))}</div>}
			<div style={{minWidth:'100%',minHeight:'300px'}}/>
			{!this.state.isloading||<LoadingPage/>}
			{!this.state.ispopup||<div style={{backgroundColor:'#003459',position:'absolute',top:0,bottom:0,left:0,right:0,opacity:.5}}></div>}
			{!this.state.ispopup||<div style={{display:'flex',flexDirection:'column',position:'absolute',top: window.innerHeight/2-100,left: window.innerWidth/2-150,width:300,height:200,padding:20,backgroundColor:'rgba(0, 66, 113, 1.0)',alignItems:'center',borderRadius:15,border:'2px solid white',}}>
				<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",borderBottom:'2px solid white'}}>Confirm?</div>
				<div style={{display:'flex',width:'100%',flexDirection:'row',alignItems:'center',flex:1,justifyContent:'center',height:'100%'}}>
					<div className='mousehover' onClick={()=>{this.deleteproject(this.state.delete_id)}}
						style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
						<div style={{color: "rgba(68, 215, 182, 1.0)",fontSize: 24,fontWeight: "bold",}}>YES</div>
					</div>
					<div className='mousehover' onClick={()=>{this.setState({ispopup:false})}}
						style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
						<div style={{color: "red",fontSize: 24,fontWeight: "bold",}}>NO</div>
					</div>
				</div>
			</div>}
		</div>)
	}
}


export default connect((store)=>{
  return{
	control:store.firstReducer,
  }
})(ProjectListPage)



