import React from 'react';
import './App.css';
import {connect } from 'react-redux';
import LoginPage from './screens/LoginPage'
import MenuPage from './screens/MenuPage'
import MainPage from './screens/MainPage'

class App extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      isloading:false,
      height:0,width:0
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  render(){
    let control=this.props.control;
    if(!control.isLogin)
      return(
        <LoginPage/>
      );
    return(
      <div style={{height:this.state.height}}  className='mainView'>
        {!control.ismenu||<MenuPage/>}
        <MainPage/>
      </div>
    );
  }
}

export default connect((store)=>{
  return{
    control:store.firstReducer
  }
})(App)
