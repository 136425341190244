//import {Image, View, Dimensions, TouchableOpacity} from "react-native"
import React from "react"
import {get_stamp}from '../api'
import {
	Login,setstamp
} from '../actions/Actions'

import { connect } from 'react-redux';

class StampView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isloading:false
		}
	}
	componentDidMount=async()=> {
	}
	get_stamp_photo=async()=>{
		if(this.state.isloading)return
		this.setState({isloading:true})
		let result=await get_stamp(this.props.control.token)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));
			return
		}
		if(result!=null&&result.result!==undefined&&result.result!=='fail'&&result.result.length>0){
			this.props.dispatch(setstamp(result.result[0].stamp))
			this.props.setphoto(result.result[0].stamp)
		}
		this.setState({isloading:false})
	}
	handle_click=async ()=>{
		if(this.props.photo){
			this.props.setphoto(null);
			return
		}
		if(this.props.control.stamp==null) this.get_stamp_photo() 
		else this.props.setphoto(this.props.control.stamp)
	}
	render(){
		return(
			<div className='mousehover' style={{flex:1,margin:20, padding:10,marginTop:0,marginBottom:0}}>
				<div style={{width:'100%',minHeight:100,maxWidth:400,backgroundColor:'white',borderRadius:10}} onClick={this.handle_click}>
					{!this.props.photo||<img style={{height:null,width:'100%',borderRadius:10}} src={this.props.photo} alt='stamp'/>}
				</div>
			</div>
		);
	}
}
export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(StampView)



