import React from "react"
import { connect } from 'react-redux';
import {
	get_photos_by_photoid
} from '../api'
import {
	Login
} from '../actions/Actions'

class PhotoView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			uri:'',
			isloading:false
		}
	}
	componentDidMount=async()=> {
		this.getphoto(this.props.data.photoid)
	}
	componentWillReceiveProps(nextProps) {
		if (this.props.data.photoid !== nextProps.data.photoid) {
			this.getphoto(this.props.data.photoid);
		}
	}
	getphoto=async(_id)=>{
		if(this.state.isloading)return
		let result=await get_photos_by_photoid(this.props.control.token,_id)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}
		if(result!=null&&result.result!==undefined&&result.result!=='fail'&&result.result.length>0){
			this.setState({uri:result.result[0].base64})
			try{
				Image.getSize(result.result[0].base64, (width, height) => {
			        this.setState({ ratio: width/height });
			    })
			}catch{
				console.log('getphoto getSize err')
			}
		}else this.setState({uri:null})

			
	}
	render(){
		return(
			<div style={{flex:1,margin:10}}>
				{!this.state.uri||<img style={{height:undefined,width:'100%',aspectRatio:this.state.ratio}} src={this.state.uri} alt='form dl img'/>}
			</div>
		);
	}
}
export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(PhotoView)

