import React from "react"
import { connect } from 'react-redux';
import '../App.css';
import {
	Login,
	setprojectlist,
} from '../actions/Actions'
import {
	get_projects_list
} from '../api'
import CreateFormPage from './CreateFormPage'
import ViewFormPage from './ViewFormPage'
import UserListPage from './UserListPage'
import HomePage from './HomePage'
import FormsManagementPage from './FormsManagementPage'
import SubmittedFormPage from './SubmittedFormPage'
import ReportGeneratorPage from './ReportGeneratorPage'
import FillReportPage from './FillReportPage'
import ProjectListPage from './ProjectListPage'
import PhotoListPage from './PhotoListPage'
import CategoriesListPage from './CategoriesListPage'

class MainPage extends React.Component {
	componentDidMount() {
		this.get_projects()
	}
	componentWillUnmount() {
	}

	get_projects=async()=>{
		let result= await get_projects_list(this.props.control.token)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));
			return
		}
		if(result!==null&&result.result!==undefined&&result.result!=='fail'){
			let dict={}
			for(let i of result.result){
				dict[i._id]=i
			}
			this.props.dispatch(setprojectlist(result.result.map((x)=>{return {...x,value:x.project_id,_id:x._id}}),dict))
		}
	}


	render() {
		let page_index= this.props.control.page_index
		if(page_index===0||page_index===6.1)
			return(<HomePage/>);
		else if (page_index===1)
			return(<FormsManagementPage/>)
		else if (page_index===2)
			return(<CreateFormPage/>);
		else if (page_index===5)
			return (<SubmittedFormPage/>)
		else if (page_index===6)
			return(<ViewFormPage/>)
		else if(page_index===7)
			return(<UserListPage/>);
		else if (page_index===10||page_index===11.1)
			return(<ReportGeneratorPage/>)
		else if (page_index===11)
			return(<FillReportPage/>)
		else if(page_index===12)
			return(<ProjectListPage/>)
		else if(page_index===13)
			return(<PhotoListPage/>)
		else if(page_index===14)
			return(<CategoriesListPage/>)
					
		return(
			<div style={{width:'100%',height:'100%',color:'white'}}>
				Error
			</div>
		);
	}
}

export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(MainPage)