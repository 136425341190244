import React from "react"
import { connect } from 'react-redux';
import {
	setpageindex,Login,setprojectlist
} from '../actions/Actions'
import {
	get_approved_forms_by_project,
	revise_form,
	get_form_by_id,
	get_reports_by_project,
	get_reports_by_id,delete_reports,
} from '../api'
import {formatDate,quickSort}from '../util'
import LoadingPage from './LoadingPage'
import ViewPDFPage from './ViewPDFPage'

import search_icon from "./../assets/images/search_white.png"
import refresh from "./../assets/images/refresh.png"
import report_gen_green from "./../assets/images/report_gen_green.png"
import report_icon from "./../assets/images/report.png"
import more_icon from "./../assets/images/more.png"
import up_icon from "./../assets/images/up.png"
import Dropdown from 'react-dropdown'
import FillReportPage from './FillReportPage'
const MonthDict={
	0:'January',
	1:'February',
	2:'March',
	3:'April',
	4:'May',
	5:'June',
	6:'July',
	7:'August',
	8:'September',
	9:'October',
	10:'November',
	11:'December',
}
const SortDict={
	'Form ID (A-Z)':{field:'form_id'},
	'Form ID (Z-A)':{field:'form_id',isReverse:true,},
	'Submitted By (A-Z)':{field:'submitted_by'},
	'Submitted By (Z-A)':{field:'submitted_by',isReverse:true,},
	//'Category (A-Z)':{field:'category'},
	//'Category (Z-A)':{field:'category',isReverse:true,},
	'Last Edited (Asc)':{field:'last_update'},
	'Last Edited (Desc)':{field:'last_update',isReverse:true,},
	'Creation Time (Asc)':{field:'create_date'},
	'Creation Time (Desc)':{field:'create_date',isReverse:true,}
}
const sortOptions=[
	{value:'Form ID (A-Z)'},
	{value:'Form ID (Z-A)'},
	{value:'Submitted By (A-Z)'},
	{value:'Submitted By (Z-A)'},
	//{value:'Category (A-Z)'},
	//{value:'Category (Z-A)'},
	{value:'Last Edited (Asc)'},
	{value:'Last Edited (Desc)'},
	{value:'Creation Time (Asc)'},
	{value:'Creation Time (Desc)'}
]
class ReportGeneratorPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			SortType:'default',
			isloading:false,
			scroll_refreshing:false,
			item_list:{},
			selected_project:null,
			mode:0, //0:FORM 1:REPORT,
			item_index:-1,
			text_search:'',

			isAllform:false,
		}
	}

	componentDidMount=async()=> {
		let pro_list=this.props.control.project_list
		for (let i in pro_list)
			pro_list[i].isexpend=false
		this.props.dispatch(setprojectlist(pro_list))
	}
	sort=async (field)=>{
		this.setState({isloading:true});
		let list={...this.state.item_list}
		for (let proj of this.props.control.project_list){
			if(list[proj.value]!==undefined){
				let temp={}

				if(SortDict[field].field==='submitted_by'||SortDict[field].field==='form_id'){
					for (let year in list[proj.value])
						for (let q in list[proj.value][year].quarter)
							for (let c in list[proj.value][year].quarter[q].category){
								let List_=list[proj.value][year].quarter[q].category[c].list
								List_=quickSort(List_, 0, List_.length - 1,SortDict[field].field);
								if(SortDict[field].isReverse){
									List_.reverse()
								}
							}
									
				}else{
					let result=await get_approved_forms_by_project(this.props.control.token,proj.value)
					if(result.message&&result.message=='Unauthorized'){
						this.props.dispatch(Login(false));alert('Session Expired!');return
					} else if(result!=null&&result.result!==undefined&&result.result!=='fail'){
						result.result=quickSort(result.result, 0, result.result.length - 1,SortDict[field].field);
						if(SortDict[field].isReverse){
							result.result.reverse()
						}

						for (let i in result.result){
							let date = new Date(result.result[i].create_date)
							if(SortDict[field].field==='last_update')
								date = new Date(result.result[i].last_update)
							let quarter = MonthDict[Math.ceil((date.getMonth()))]||'error' 
							let category = result.result[i].user_category||'Uncategorized'
							let year = date.getFullYear()
							if(temp[`${year}`]===undefined){
								temp[`${year}`]={quarter:{}}
								if(list[proj.value]&&list[proj.value][`${year}`]&&list[proj.value][`${year}`].isexpend)
									temp[`${year}`].isexpend=true
							}
							if(temp[`${year}`].quarter[`${quarter}`]===undefined){
								temp[`${year}`].quarter[`${quarter}`]={category:{}}
								if(list[proj.value]&&list[proj.value][`${year}`]
									&&list[proj.value][`${year}`].quarter
									&&list[proj.value][`${year}`].quarter[`${quarter}`]
									&&list[proj.value][`${year}`].quarter[`${quarter}`].isexpend
								)
									temp[`${year}`].quarter[`${quarter}`].isexpend=true
							}
							if(temp[`${year}`].quarter[`${quarter}`].category[category]===undefined){
								temp[`${year}`].quarter[`${quarter}`].category[category]={list:[]}
								if(list[proj.value]&&list[proj.value][`${year}`]
									&&list[proj.value][`${year}`].quarter
									&&list[proj.value][`${year}`].quarter[`${quarter}`]
									&&list[proj.value][`${year}`].quarter[`${quarter}`].category
									&&list[proj.value][`${year}`].quarter[`${quarter}`].category[category]
									&&list[proj.value][`${year}`].quarter[`${quarter}`].category[category].isexpend
								)
									temp[`${year}`].quarter[`${quarter}`].category[category].isexpend=true
							}
							temp[`${year}`].quarter[`${quarter}`].category[category].list.push(result.result[i])
						}
					}
					list[proj.value]=temp
				}
			}
		}
		this.setState({SortType:field})
		
		this.setState({item_list:list,isloading:false})
	}
	get_all_approved_forms_by_project = async (proj,proj_index,force=false) =>{
		this.setState({isloading:true});
		let item_list={...this.state.item_list}
		if(item_list[proj.value]===undefined||force){
			item_list[proj.value]={}
			let result=await get_approved_forms_by_project(this.props.control.token,proj.value)
			if(result.message&&result.message=='Unauthorized'){
				this.props.dispatch(Login(false));alert('Session Expired!');return
			} else if(result!=null&&result.result!==undefined&&result.result!=='fail'){
				if(this.state.SortType&&SortDict[this.state.SortType]&&SortDict[this.state.SortType].field&&SortDict[this.state.SortType].field!=='submitted_by'&&SortDict[this.state.SortType].field!=='form_id'){
					result.result=quickSort(result.result, 0, result.result.length - 1,SortDict[this.state.SortType].field);
					if(SortDict[this.state.SortType].isReverse){
						result.result.reverse()
					}
				}
				for (let i in result.result){
					let date = new Date(result.result[i].create_date)
					let quarter = MonthDict[Math.ceil((date.getMonth()))]||'error' 
					let category = result.result[i].user_category||'Uncategorized'
					let year = date.getFullYear()
					if(item_list[proj.value][`${year}`]===undefined)
						item_list[proj.value][`${year}`]={quarter:{}}
					if(item_list[proj.value][`${year}`].quarter[`${quarter}`]===undefined)
						item_list[proj.value][`${year}`].quarter[`${quarter}`]={category:{}}
					if(item_list[proj.value][`${year}`].quarter[`${quarter}`].category[category]===undefined)
						item_list[proj.value][`${year}`].quarter[`${quarter}`].category[category]={list:[]}
					item_list[proj.value][`${year}`].quarter[`${quarter}`].category[category].list.push(result.result[i])
				}
				if(	this.state.SortType
					&&SortDict[this.state.SortType]
					&&SortDict[this.state.SortType].field
					&&(SortDict[this.state.SortType].field==='submitted_by'||SortDict[this.state.SortType].field==='form_id')){
					for (let year in item_list[proj.value])
						for (let q in item_list[proj.value][year].quarter)
							for (let c in item_list[proj.value][year].quarter[q].category){
								let List_=item_list[proj.value][year].quarter[q].category[c].list
								List_=quickSort(List_, 0, List_.length - 1,SortDict[this.state.SortType].field);
								if(SortDict[this.state.SortType].isReverse){
									List_.reverse()
								}
							}					
				}

				this.setState({item_list:item_list,isloading:false})
			} else {
				alert('fetch error!')
				this.setState({isloading:false})
			}
		}else this.setState({isloading:false});

		let pro_list=this.props.control.project_list
		pro_list[proj_index].isexpend=force?true:!pro_list[proj_index].isexpend
		this.props.dispatch(setprojectlist(pro_list))
	}
	
	get_all_reports_by_project=async(proj,proj_index,force=false)=>{
		this.setState({isloading:true});
		let item_list={...this.state.item_list}
		if(item_list[proj.value]===undefined||force){
			item_list[proj.value]={}
			let result=await get_reports_by_project(this.props.control.token,proj.value)
			if(result.message&&result.message=='Unauthorized'){
				this.props.dispatch(Login(false));alert('Session Expired!');return
			}else if(result!=null&&result.result!==undefined&&result.result!=='fail'){
				for (let i in result.result){
					let date = new Date(result.result[i].create_date)
					//let quarter = Math.ceil((date.getMonth()+1) / 3)
					let quarter = MonthDict[Math.ceil((date.getMonth()))]||'error' 
					let category = result.result[i].user_category||'Uncategorized'
					let year = date.getFullYear()
					if(item_list[proj.value][`${year}`]===undefined)
						item_list[proj.value][`${year}`]={quarter:{}}
					if(item_list[proj.value][`${year}`].quarter[`${quarter}`]===undefined)
						item_list[proj.value][`${year}`].quarter[`${quarter}`]={category:{}}
					if(item_list[proj.value][`${year}`].quarter[`${quarter}`].category[category]===undefined)
						item_list[proj.value][`${year}`].quarter[`${quarter}`].category[category]={list:[]}
					item_list[proj.value][`${year}`].quarter[`${quarter}`].category[category].list.push(result.result[i])
				} 
				this.setState({item_list:item_list,isloading:false})
			} else {
				alert('fetch error!')
				this.setState({isloading:false})
			}
		}else this.setState({isloading:false});

		let pro_list=[...this.props.control.project_list]
		pro_list[proj_index].isexpend=true
		this.props.dispatch(setprojectlist(pro_list))
	}
	get_reports_by_id_=async (_id)=>{
		if(this.state.isloading)return
		this.setState({isloading:true})
		let result=await get_reports_by_id(this.props.control.token,_id);
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}
		if(result!=null&&result.result!==undefined&&result.result!=='fail'){
			this.setState({pdfurl:result.result[0].base64,isPDFmode:false})
			let pdfWindow = window.open("")
			pdfWindow.document.write(`"<iframe width='100%' height='100%' src=${result.result[0].base64}></iframe>`)
			//window.location.href=result.result[0].base64
		}else alert('fetch error!')

		this.setState({isloading:false})
	}
	delete_reports_=async (_id)=>{
		if (this.state.isloading)return;
		this.setState({isloading:true})
		let result=await delete_reports(this.props.control.token,_id)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}
		if(result===undefined||result===null){alert('Delete Report Error')}
		else if(result.result!=='success'){alert(result.message)}
		else if(result.result==='success'){
			this.hanele_refresh()
		}this.setState({isloading:false,ispopup:false})
	}
	reviseform=async (item,proj,proj_index)=>{
		if (this.state.isloading)return;
		this.setState({isloading:true})
		let obj={
			_id:item._id,
			form_id:item.form_id,
		}
		let result=await revise_form(this.props.control.token,obj)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));
			return
		}
		if(result===undefined||result===null){
			this.setState({error_message:'Revise Form Error',isloading:false});
			return
		}else if(result.result!=='success'){
			this.setState({error_message:result.message,isloading:false});
			return
		}else if(result.result==='success'){
			this.get_all_approved_forms_by_project(proj,proj_index,true)
			//this.props.dispatch(setpageindex(5))
		}
	}
	get_forms=async ()=>{
		if (this.state.isloading)return;
		this.setState({isloading:true})

		let fetch_list=[]
		let list=this.state.item_list

		for (let proj in list)
			for (let year in list[proj])
				for (let q in list[proj][year].quarter)
					for (let c in list[proj][year].quarter[q].category)
						for(let form in list[proj][year].quarter[q].category[c].list)
							if(list[proj][year].quarter[q].category[c].list[form].isselected){
								let result=await get_form_by_id(this.props.control.token,list[proj][year].quarter[q].category[c].list[form]._id)
								if(result.message&&result.message=='Unauthorized'){
									alert('session expired')
									this.props.dispatch(Login(false));
									return
								}
								if(result!=null&&result.result!==undefined&&result.result.length>0&&result.result!=='fail'){
									fetch_list.push(result.result[0])
								}	
							}
		this.setState({isloading:false})
		this.props.dispatch(setpageindex(11.1,[...fetch_list]))
	}

	hanele_refresh=()=>{
		this.setState({item_list:{}})
		let pro_list=this.props.control.project_list
		for (let i in pro_list)
			pro_list[i].isexpend=false
		this.props.dispatch(setprojectlist(pro_list))
	}
	handle_view_pdf=(url)=>()=>{
		 window.open(url, "_blank")
	}
	render(){
		if(this.state.isPDFmode)
			return(<ViewPDFPage url={this.state.pdfurl}
				return={()=>{this.setState({isPDFmode:false})}}
			/>)
		return(
			<div style={{width:'100%',height:'100%',overflowY: 'auto'}}>
				<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
					<div style={{backgroundColor:'#007EA7',borderRadius:5,marginRight:2,width:'80%',height:46,marginLeft:10,display:'flex',flexDirection:'row',alignItems:'center'}}>
						<img src={search_icon} style={{marginLeft:10,marginRight:10,width:30,height:30}} alt='forms'/>
						<input style={{ backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)', color:"#98E2FF",width:'95%',fontSize: 19,marginLeft:5}} value={this.state.text_search} onChange={(e)=>{this.setState({text_search:e.target.value})}} placeholder="Search for Form...."/>
					</div>
				</div>
				<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20,marginBottom:20}}>
					<div className='center_row' style={{width:'50%'}}>
						<img src={report_gen_green} style={{marginLeft:10,marginRight:10,width:47,height:47}} alt='report_gen_green'/>
						<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",}}>Report Generator</div>
						{this.state.mode===1||
							<div className='mousehover' onClick={()=>{
								let list=this.state.item_list
								let project=null
								for (let proj in list)
									for (let year in list[proj])
										for (let q in list[proj][year].quarter)
											for (let c in list[proj][year].quarter[q].category)
												for (let form in list[proj][year].quarter[q].category[c].list)
													if(list[proj][year].quarter[q].category[c].list[form].isselected&&project==null)
														project=list[proj][year].quarter[q].category[c].list[form].project
													else if(list[proj][year].quarter[q].category[c].list[form].isselected&&project!=list[proj][year].quarter[q].category[c].list[form].project){
														alert('Please select from same project')
														return
													}
								if(project===null){
									alert('Please select at least one form');
									return
								}
								this.get_forms()
							}} style={{display:'flex',opacity:this.state.mode>=0?1:.3,flexDirection:'row',backgroundColor:'#7DDAFF',borderRadius:5,marginLeft:20,height:40,paddingLeft:20,paddingRight:20,justifyContent:'center',alignItems:'center'}}>
								<img src={report_icon} style={{width:17,height:17,marginRight:5}} alt='preview'/>
								<div style={{color:'white',fontSize:20,fontWeight:'bold'}}>Generate</div>
							</div>
						}
					</div>
					<div style={{display:'flex',flexDirection:'row',width:'15%',alignItems:'center',justifyContent:'flex-end'}}>
						<div className="mousehover" style={{marginLeft:5,marginRight:5,width:26,height:26,borderRadius:13,border:'1px solid rgba(125,218,255,.7)',borderWidth:1,justifyContent:'center',alignItems:'center',display:'flex'}} onClick={()=>{this.setState({isAllform:!this.state.isAllform})}}>
							<div style={{width:20,height:20,borderRadius:10,backgroundColor:this.state.isAllform?'rgba(68,204,215,1.0)':'transparent'}}></div>
						</div>
						<div style={{color: "white",fontSize: 14,fontWeight: "bold",marginLeft:5}}>Show All Forms</div>
					</div>
					<div  onClick={this.hanele_refresh}style={{display:'flex',flexDirection:'row',width:'15%',alignItems:'center',justifyContent:'flex-end'}}>
						<img className='mousehover' src={refresh} style={{width:20,height:20}} alt='refresh'/>
					</div>
				</div>
				<div style={{width:'100%',display:'flex',justifyContent:'center',marginBottom:10,flexDirection:'row'}}>
					<div style={{display:'flex',marginLeft:30,flexDirection:'row',marginTop:20,width:this.state.mode===0?'50%':'80%'}}>
						<div className='mousehover' style={{borderBottom:`3px solid ${this.state.mode===0?'rgba(68,204,215,1.0)':'rgba(5,102,141,1.0)'}`,padding:5}} onClick={()=>{this.setState({mode:0});this.hanele_refresh();}}>
							<div style={{color:this.state.mode===0?'rgba(68,204,215,1.0)':'rgba(5,102,141,1.0)',fontSize:24,fontWeight:'bold'}}>FORM</div>
						</div>
						<div style={{color:'rgba(5,102,141,1.0)',fontSize:24,fontWeight:'bold'}}>|</div>
						<div className='mousehover' style={{borderBottom:`3px solid ${this.state.mode===1?'rgba(68,204,215,1.0)':'rgba(5,102,141,1.0)'}`,padding:5}} onClick={()=>{this.setState({mode:1});this.hanele_refresh();}}>
							<div style={{color:this.state.mode===1?'rgba(68,204,215,1.0)':'rgba(5,102,141,1.0)',fontSize:24,fontWeight:'bold'}}>REPORT</div>
						</div>
					</div>
					{this.state.mode===0&&<div style={{width:'30%', display:'flex',flexDirection:'column'}}>
						<div style={{color:'white',textAlign:'left',fontSize: 12,fontWeight: "bold",marginTop:7}}>Sort by:</div>
						<Dropdown
							controlClassName='dropdown_control'
							placeholderClassName='dropdown_placeholder'
							options={sortOptions} onChange={(e)=>{
								let value=e.value
								this.sort(value)
							}} value={`- ${this.state.SortType}`} placeholder="Select an option"
						/>
					</div>}
				</div>
				<div style={{width:'100%',display:'flex',flex:1,height:'77%',overflowY:'auto',flexDirection:'column'}}>
					{this.props.control.project_list.map((proj,proj_index)=>(
						<div key={proj_index} style={{width:'100%'}}>
							{!this.state.text_search===''||!proj.value.toLowerCase().includes(this.state.text_search.toLowerCase())||<div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',justifyContent:'center'}}>
								<div className='mousehover' onClick={()=>{
									if (this.state.mode===0)
										this.get_all_approved_forms_by_project(proj,proj_index)
									else
										this.get_all_reports_by_project(proj,proj_index)
								}} style={{display:'flex',flexDirection:'row',width:'80%',borderBottom:"1px solid rgba(0, 126, 167, 1.0)",borderTop:"1px solid rgba(0, 126, 167, 1.0)",padding:10,alignItems:'center'}}>
									<div style={{color:'rgba(68,204,215,1.0)',fontSize:20,fontWeight:'bold',width:'95%'}}>{proj.value}</div>						 		
									{!proj.isexpend?<img src={more_icon} style={{width:17,height:17}} alt='more_icon'/>:
									<img src={up_icon} style={{width:17,height:17}} alt='up_icon'/>}
								</div>
								{!proj.isexpend||this.state.item_list[proj.value]===undefined||Object.keys(this.state.item_list[proj.value]).map( x => 
									<div key={x} style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
									 	<div className='mousehover' style={{display:'flex',flexDirection:'column',width:'80%',borderTop:'1px solid rgba(0, 126, 167, 1.0)',borderBottom:'1px solid rgba(0, 126, 167, 1.0)',paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10,alignItems:'center'}} onClick={()=>{
											let list=this.state.item_list
											list[proj.value][x].isexpend=!list[proj.value][x].isexpend
											this.setState({item_list:list})
										}}>
											<div style={{color: "#7DDAFF",fontSize: 20,fontWeight: "bold"}}>
												{this.state.item_list[proj.value][x].isexpend?"----------":''}&nbsp;{x}&nbsp;{this.state.item_list[proj.value][x].isexpend?"----------":''}
											</div>
										</div>
										{!this.state.item_list[proj.value][x].isexpend||Object.keys(this.state.item_list[proj.value][x].quarter).map( q => 
											<div key={q} style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
									 			<div className='mousehover' style={{display:'flex',flexDirection:'column',width:'80%',borderTop:'1px solid rgba(0, 126, 167, 1.0)',borderBottom:'1px solid rgba(0, 126, 167, 1.0)',paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10,alignItems:'center'}} onClick={()=>{
									 				let list=this.state.item_list
										 			list[proj.value][x].quarter[q].isexpend=!list[proj.value][x].quarter[q].isexpend
										 			this.setState({item_list:list})
									 			}}>
									 				<div style={{color: "white",fontSize: 20,fontWeight: "bold"}}>
										 				{this.state.item_list[proj.value][x].quarter[q].isexpend?"----------":''}&nbsp;{q}&nbsp;{this.state.item_list[proj.value][x].quarter[q].isexpend?"  ----------":''}
										 			</div>
									 			</div>

									 			{!this.state.item_list[proj.value][x].quarter[q].isexpend||Object.keys(this.state.item_list[proj.value][x].quarter[q].category).map( c => 
									 				<div key={c} style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
										 				<div className='mousehover' style={{display:'flex',flexDirection:'column',width:'80%',borderTop:'1px solid rgba(0, 126, 167, 1.0)',borderBottom:'1px solid rgba(0, 126, 167, 1.0)',paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10,alignItems:'center'}} onClick={()=>{
											 				let list={...this.state.item_list}
												 			list[proj.value][x].quarter[q].category[c].isexpend=!list[proj.value][x].quarter[q].category[c].isexpend
												 			this.setState({item_list:list})
											 			}}>
											 				<div style={{color: "rgba(169,250,180,.7)",fontSize: 20,fontWeight: "bold"}}>
												 				{this.state.item_list[proj.value][x].quarter[q].category[c].isexpend?"----------":''}&nbsp;{c}&nbsp;{this.state.item_list[proj.value][x].quarter[q].category[c].isexpend?"  ----------":''}
												 			</div>
											 			</div>
									 			

										 				{!this.state.item_list[proj.value][x].quarter[q].category[c].isexpend||this.state.item_list[proj.value][x].quarter[q].category[c].list.map((item,index)=>(
											 				<div key={index} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}>
												 				{this.state.mode===0?
												 					(!this.state.isAllform&&item.isgenerated)?<div></div>:
														 			<div className='center_row' style={{display:'flex',justifyContent:'space-between',flexDirection:'row',width:'80%',borderBottom:"1px solid rgba(0, 126, 167, 1.0)",borderTop:"1px solid rgba(0, 126, 167, 1.0)",padding:10,alignItems:'center'}}>
																		<div className="mousehover" style={{marginLeft:5,marginRight:5,width:26,height:26,borderRadius:13,border:'1px solid rgba(125,218,255,.7)',borderWidth:1,justifyContent:'center',alignItems:'center',display:'flex'}} 
																			onClick={()=>{
																				let list=this.state.item_list
																				list[proj.value][x].quarter[q].category[c].list[index].isselected=!item.isselected
																				list[proj.value][x].quarter[q].category[c].list[index].isexpend=false
																				this.setState({item_list:list})
																			}}
																		>
																			<div style={{width:20,height:20,borderRadius:10,backgroundColor:item.isselected?'rgba(68,204,215,1.0)':'transparent'}}></div>
																		</div>
																		<div style={{width:'80%'}}>
																			<div className='center_row' style={{width:'100%',justifyContent:'space-between',alignItems:'center'}}>
																				<div style={{color: "#ffffff",fontSize: 28,fontWeight: "bold",}}>{item.form_id}</div>
																				<div style={{color:"rgba(2, 172, 228, 1.0)",fontSize:14,fontWeight:"bold",marginLeft:30}}>
																					{(this.state.SortType==='Last Edited (Asc)'||this.state.SortType==='Last Edited (Desc)')?`Last Edited: ${formatDate(new Date(item.last_update))}`:`Test Date: ${formatDate(new Date(item.create_date))}`}
																				</div>
																			</div>
																			{item.form_description===null||item.form_description===undefined||<div style={{color: "#00DAC3",fontSize: 14,fontWeight: "bold"}}>Description: {item.form_description}</div>}
																			<div style={{display:'flex',width:'100%',flexDirection:'row',fontSize:16,fontWeight:'bold',color:'white',alignItems:'center'}}>
																				<div style={{display:'flex',flexDirection:'row',}}>Submitted by:&nbsp;<div style={{color:"rgba(125,218,255,1.0)",fontSize:20,fontWeight:"bold"}}>{item.submitted_by}</div>&nbsp;&nbsp;</div>
																				{item.location===null||item.location===undefined||item.location===''||<div style={{display:'flex',flexDirection:'row',color:"white",fontSize:17,fontWeight:"bold",marginLeft:30}}>Location:&nbsp;<div style={{color:"rgba(125,218,255,1.0)",fontSize:20,fontWeight:"bold"}}>{item.location}</div>&nbsp;&nbsp;</div>}
																				{item.position===null||item.position===undefined||item.position===''||<div style={{display:'flex',flexDirection:'row',color:"white",fontSize:17,fontWeight:"bold",marginLeft:30}}>Position:&nbsp;<div style={{color:"rgba(125,218,255,1.0)",fontSize:20,fontWeight:"bold"}}>{item.position}</div>&nbsp;&nbsp;</div>}
																				{item.type_serial===null||item.type_serial===undefined||item.type_serial===''||<div style={{display:'flex',flexDirection:'row',color:"white",fontSize:17,fontWeight:"bold",marginLeft:30}}>Type/ Serial No.:&nbsp;<div style={{color:"rgba(125,218,255,1.0)",fontSize:20,fontWeight:"bold"}}>{item.type_serial}</div>&nbsp;&nbsp;</div>}
																			</div>
																			{item.report_id&&<div style={{display:'flex',width:'100%',flexDirection:'row',alignItems:'center'}}>
																				<div style={{fontSize:16,fontWeight:'bold',color:'white'}}>Report ID:&nbsp;</div>
																				<div className='mousehover' onClick={this.handle_view_pdf(`${this.props.control.server_ip}/pdf/temp/${item.report_id}`)} style={{fontSize:12,fontWeight:'bold',color:'#00DAC3'}} > {item.report_id}</div>
																			</div>}
																		</div>
																		{item.isexpend?
																			<div style={{backgroundColor:'#C7E9F7',borderRadius:10,color:'#004271',fontSize:17}}>
																				<div onClick={()=>{
																					this.reviseform(item,proj,proj_index)
																				}} className='mousehover' style={{display:'flex',padding:7,justifyContent:'center',alignItems:'center'}}>
																					Revise
																				</div>
																				<div onClick={()=>{
																					let list=this.state.item_list
																					for(let i in list[proj.value][x].quarter[q].category[c].list)
																						list[proj.value][x].quarter[q].category[c].list[i].isexpend=false
																					this.setState({item_list:list})
																				}} className='mousehover' style={{display:'flex',backgroundColor:'#7DDAFF',padding:7,borderRadius:10,justifyContent:'center',alignItems:'center'}}>
																					Close
																				</div>
																			</div>:
																			<div className='mousehover' onClick={()=>{
																				let list=this.state.item_list
																				for(let i in list[proj.value][x].quarter[q].category[c].list)
																					list[proj.value][x].quarter[q].category[c].list[i].isexpend=false
																				list[proj.value][x].quarter[q].category[c].list[index].isexpend=true
																				this.setState({item_list:list})
																			}}
																			style={{color: "rgba(125,218,255,1.0)",fontSize:40,fontWeight:"bold"}}>
																				...
																			</div>
																		}
																	</div>
																:
																	<div className='center_row' style={{display:'flex',justifyContent:'space-between',flexDirection:'row',width:'80%',borderBottom:"1px solid rgba(0, 126, 167, 1.0)",borderTop:"1px solid rgba(0, 126, 167, 1.0)",padding:10,alignItems:'center'}}>
																		<div style={{width:'90%'}}>
																			<div style={{display:'flex',width:'100%',flexDirection:'row'}}>
																				<div style={{color: "rgba(125,218,255,1.0)",fontSize:20,fontWeight:"bold"}}>Project: {item.project_id}</div>
																			</div>
																			<div style={{display:'flex',width:'100%',flexDirection:'row'}}>
																				<div style={{color: "#ffffff",fontSize: 30,fontWeight: "bold",}}>{item.form_ids.map(x=>{return `${x.form_id} `})}</div>
																			</div>
																			<div style={{display:'flex',width:'100%',flexDirection:'row'}}>
																				<div style={{color:"rgba(2, 172, 228, 1.0)",fontSize:18,fontWeight:"bold"}}>Report Date: {formatDate(new Date(item.create_date))}</div>
																			</div>
																		</div>
																		{item.isexpend?
																			<div style={{backgroundColor:'#C7E9F7',borderRadius:10,color:'#004271',fontSize:17}}>
																				<div onClick={()=>{
																					this.get_reports_by_id_(item._id)
																				}} className='mousehover' style={{display:'flex',padding:7,justifyContent:'center',alignItems:'center'}}>
																					View
																				</div>
																				<div onClick={()=>{
																					this.setState({ispopup:true})
																				}} className='mousehover' style={{display:'flex',backgroundColor:'#7DDAFF',padding:7,borderRadius:10,justifyContent:'center',alignItems:'center'}}>
																					Delete
																				</div>
																				<div onClick={()=>{
																					let list=this.state.item_list
																					list[proj.value][x].quarter[q].category[c].list[index].isexpend=false
																					this.setState({item_list:list})
																				}} className='mousehover' style={{display:'flex',padding:7,justifyContent:'center',alignItems:'center'}}>
																					Close
																				</div>
																			</div>:
																			<div className='mousehover' onClick={()=>{
																				let list=this.state.item_list
																				for(let i in list[proj.value][x].quarter[q].category[c].list)
																					list[proj.value][x].quarter[q].category[c].list[i].isexpend=false
																				list[proj.value][x].quarter[q].category[c].list[index].isexpend=true
																				this.setState({item_list:list,selected_id:item._id})
																			}}
																			style={{color: "rgba(125,218,255,1.0)",fontSize:40,fontWeight:"bold"}}>
																				...
																			</div>
																		}
																	</div>
																}
															</div>
														))}
													</div>
												)}
											</div>
										)}
									</div>)
								}
							</div>}
						</div>
					))}
					<div style={{width:'100%',minWidth:'100%',height:'200px',minHeight:'200px'}}/>
				</div>
				
				{this.props.control.page_index===11.1&&<div style={{position:'absolute',top:0,bottom:0,left:0,right:0,backgroundColor:'#003459',opacity:.97}}>
					<FillReportPage/>
				</div>}
				{!this.state.isloading||<LoadingPage/>}
				{!this.state.ispopup||<div style={{backgroundColor:'#003459',position:'absolute',top:0,bottom:0,left:0,right:0,opacity:.5}}></div>}
				{!this.state.ispopup||<div style={{display:'flex',flexDirection:'column',position:'absolute',top: window.innerHeight/2-100,left: window.innerWidth/2-150,width:300,height:200,padding:20,backgroundColor:'rgba(0, 66, 113, 1.0)',alignItems:'center',borderRadius:15,border:'2px solid white',}}>
					<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",borderBottom:'2px solid white'}}>Confirm?</div>
					<div style={{display:'flex',width:'100%',flexDirection:'row',alignItems:'center',flex:1,justifyContent:'center',height:'100%'}}>
						<div className='mousehover' onClick={()=>{this.delete_reports_(this.state.selected_id)}}
							style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
							<div style={{color: "rgba(68, 215, 182, 1.0)",fontSize: 24,fontWeight: "bold",}}>YES</div>
						</div>
						<div className='mousehover' onClick={()=>{this.setState({ispopup:false})}}
							style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
							<div style={{color: "red",fontSize: 24,fontWeight: "bold",}}>NO</div>
						</div>
					</div>
				</div>}
			</div>
		);
	}

}


export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(ReportGeneratorPage)



