import React from "react"
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/entry.webpack';
import back_black from "./../assets/images/back_black.png"
import forward_black from "./../assets/images/forward_black.png"


export default class ViewPDFPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isloading:false,
			ispopup:false,
			pageNumber:1
		}
	}
	componentDidMount(){
		//console.log(this.props.url)
	}
	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	}
	render(){
		const { pageNumber, numPages } = this.state;
		return(
			<div style={{width:'100%',height:'100%',overflowY: 'scroll',backgroundColor: '#ecf0f1'}}>
				<div style={{display:'flex',width:'100%',alignItems:'center',flexDirection:'row'}}>
					<div className="mousehover" onClick={this.props.return} style={{display:'flex',padding:20,flexDirection:'row',alignItems:'center',width:'33%'}}>
						<img src={back_black} style={{width:30,height:30}} alt='back_black'/>
						<div style={{fontWeight:'bold',color:'black',fontSize:35}}> Back</div>
					</div>
					
					{this.props.generate===undefined||<div className="mousehover" onClick={()=>{this.setState({ispopup:true})}} style={{display:'flex',padding:20,flexDirection:'row',alignItems:'center',justifyContent:'flex-end',width:'33%'}}>
						<div style={{fontWeight:'bold',color:'black',fontSize:35}}> Generate Report</div>
						<img src={forward_black} style={{width:30,height:30}} alt='forward_black'/>
					</div>}
				</div>
				<div style={{width:'100%'}}>
					<Document
						file={this.props.url}
						onLoadSuccess={this.onDocumentLoadSuccess}
					>
						<Page pageNumber={pageNumber} />
					</Document>
					<p>Page {pageNumber} of {numPages}</p>
		        </div>
		        


				{!this.state.ispopup||<div style={{backgroundColor:'#003459',position:'absolute',top:0,bottom:0,left:0,right:0,opacity:.5}}></div>}
				{!this.state.ispopup||<div style={{display:'flex',flexDirection:'column',position:'absolute',top: window.innerHeight/2-100,left: window.innerWidth/2-150,width:300,height:200,padding:20,backgroundColor:'rgba(0, 66, 113, 1.0)',alignItems:'center',borderRadius:15,border:'2px solid white',}}>
					<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",borderBottom:'2px solid white'}}>Confirm?</div>
					<div style={{display:'flex',width:'100%',flexDirection:'row',alignItems:'center',flex:1,justifyContent:'center',height:'100%'}}>
						<div className='mousehover' onClick={()=>{this.props.generate();this.props.return()}}
							style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
							<div style={{color: "rgba(68, 215, 182, 1.0)",fontSize: 24,fontWeight: "bold",}}>YES</div>
						</div>
						<div className='mousehover' onClick={()=>{this.setState({ispopup:false})}}
							style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
							<div style={{color: "red",fontSize: 24,fontWeight: "bold",}}>NO</div>
						</div>
					</div>
				</div>}
			</div>
		);
	}
}
