import React from "react"
import { connect } from 'react-redux';
import {
	Login
} from '../actions/Actions'
import {
	get_photos_by_id,get_photos_list,update_photo,create_photo,delete_photo
} from '../api'

import LoadingPage from './LoadingPage'
import search_icon from "./../assets/images/search_white.png"
import photo_white from './../assets/images/photo_white.png'
import add_icon from "./../assets/images/add_icon.png"
import refresh from "./../assets/images/refresh.png"
import more_icon from "./../assets/images/more.png"
import picture_icon from "./../assets/images/picture.png"

class PhotoListPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isloading:true,
			scroll_refreshing:false,

			photo_list:[],
			photo_obj:{},
			editmode:false,
			photoindex:-1,
			
			photomode:false,
			cameramode:false,
			registermode:false,

			ratio:1,
			text_search:'',
		}
	}

	componentDidMount=async()=> {
		this.getphotolist(true)
	}
	handleImageChange(e) {
		e.preventDefault();
		let file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			let photo_obj=this.state.photo_obj
			photo_obj.base64=reader.result
			this.setState({
				photo_obj:photo_obj
			});
		};
	}
	getphotolist=async(force=false)=>{
		if(!force&&this.state.isloading)return
		this.setState({isloading:true})
		let result=await get_photos_list(this.props.control.token)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}
		if(result!=null&&result.result!==undefined&&result.result!=='fail'){
			this.setState({photo_list:result.result})
		}else alert('Fetch list error')
		this.setState({isloading:false})
	}
	getphoto=async(_id)=>{
		if(this.state.isloading)return
		this.setState({isloading:true})
		let result=await get_photos_by_id(this.props.control.token,_id)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}
		if(result!=null&&result.result!==undefined&&result.result!=='fail'&&result.result.length>0){
			this.setState({photo_obj:result.result[0]})
		}else alert('Fetch photo error')
		this.setState({isloading:false})
	}
	update_photo_=async()=>{
		if(this.state.photo_obj.base64===''||this.state.photo_obj.base64===undefined){
			alert('Please upload a photo');return
		}
		if(this.state.photo_obj.photo_id===''||this.state.photo_obj.photo_id===undefined){
			alert('Photo id is empty');return
		}
		if(this.state.photo_obj.photo_id.length<5){
			alert('Length of photo id must be at least 5');return
		}
		if(this.state.photo_obj.description===''||this.state.photo_obj.description===undefined){
			alert('Photo description is empty');return
		}
		if(!this.state.isloading){
			this.setState({isloading:true})
			let result=await update_photo(this.props.control.token,this.state.photo_obj)
			if(result.message&&result.message==='Unauthorized'){
				this.props.dispatch(Login(false));alert('Session Expired!');return
			}
			if(result===undefined||result===null)alert('update photo error 1')
			else if(result.result!=='success')alert(result.message)
			else if(result.result==='success'){
				this.getphotolist(true)
				this.setState({cameramode:false,editmode:false})
				return
			}else alert('update photo error')
			this.setState({isloading:false})
		}
	}
	delete_photo_by_id=async(_id)=>{
		if(!this.state.isloading){
			this.setState({isloading:true})
			let result=await delete_photo(this.props.control.token,_id)
			if(result.message&&result.message==='Unauthorized'){this.props.dispatch(Login(false));alert('Session Expired!');return}
			if(result===undefined||result===null)alert('delete photo error 1')
			else if(result.result!=='success')alert(result.message)
			else if(result.result==='success'){
				this.getphotolist(true)
				this.setState({editmode:false,ispopup:false,photo_obj:{},registermode:false})
				return
			}else alert('delete photo error')
			this.setState({isloading:false})
		}
	}
	create_photo_=async()=>{
		if(this.state.photo_obj.base64===''||this.state.photo_obj.base64===undefined){
			alert('Please upload a photo');return
		}
		if(this.state.photo_obj.photo_id===''||this.state.photo_obj.photo_id===undefined){
			alert('Photo id is empty');return
		}
		if(this.state.photo_obj.photo_id.length<5){
			alert('Length of photo id must be at least 5');return
		}
		if(this.state.photo_obj.description===''||this.state.photo_obj.description===undefined){
			alert('Photo description is empty');return
		}
		if(!this.state.isloading){
			this.setState({isloading:true})
			let result=await create_photo(this.props.control.token,this.state.photo_obj)
			if(result.message&&result.message==='Unauthorized'){this.props.dispatch(Login(false));alert('Session Expired!');return}
			if(result===undefined||result===null)alert('create photo error 1')
			else if(result.result!=='success')alert(result.message)
			else if(result.result==='success'){
				this.getphotolist(true)
				this.setState({cameramode:false,editmode:false,registermode:false})
				return
			}else alert('create photo error')
			this.setState({isloading:false})
		}
	}
	render(){
		return(<div style={{width:'100%',height:'100%',color:'white',overflowY: 'scroll'}}>
			<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
				<div style={{backgroundColor:'#007EA7',borderRadius:5,marginRight:2,width:'80%',height:46,marginLeft:10,display:'flex',flexDirection:'row',alignItems:'center'}}>
					<img src={search_icon} style={{marginLeft:10,marginRight:10,width:30,height:30}} alt='forms'/>
					<input style={{ backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)', color:"#98E2FF",width:'95%',fontSize: 19,marginLeft:5}} value={this.state.text_search} onChange={(e)=>{this.setState({text_search:e.target.value})}} placeholder="Search for Photo...."/>
				</div>
			</div>
			<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
				<div style={{display:'flex',flexDirection:'row',width:'80%',alignItems:'center',justifyContent:'space-between'}}>
					<div className='center_row'>
						<img src={photo_white} style={{marginLeft:10,marginRight:10,width:47,height:47}} alt='photo_white'/>
						<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",}}>Photo Management</div>
						<div className='mousehover' onClick={()=>{this.setState({registermode:true,photo_obj:{base64:'',description:'',photo_id:''}})}} style={{display:'flex',flexDirection:'row',backgroundColor:'#7DDAFF',borderRadius:5,marginLeft:20,height:40,paddingLeft:20,paddingRight:20,justifyContent:'center',alignItems:'center'}}>
							<img src={add_icon} style={{width:17,height:17}} alt='add_icon'/>
							<div style={{color: "white",fontSize: 14,fontWeight: "bold",marginLeft:5}}>ADD</div>
						</div>
					</div>
					
					<div onClick={()=>{this.getphotolist()}}style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
						<img className='mousehover' src={refresh} style={{width:20,height:20}} alt='refresh'/>
					</div>
				</div>
			</div>
			<div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:20,flexDirection:'column'}}>
				<div style={{display:'flex',flexDirection:'row',width:'90%',borderBottom:'2px solid #028090',paddingTop:10,paddingBottom:10}}>
					<div style={{width:'40%',color:'white',fontSize:20,fontWeight:'bold'}}>Photo ID</div>
					<div style={{width:'60%',color:'white',fontSize:20,fontWeight:'bold'}}>Description</div>
				</div>
				{this.state.photo_list.map((item,index)=>(
					<div key={index} style={{width:'90%'}}>
						{!this.state.text_search===''||!item.photo_id.toLowerCase().includes(this.state.text_search.toLowerCase())||<div style={{display:'flex',flexDirection:'row',width:'100%',borderBottom:'2px solid #028090',paddingTop:10,paddingBottom:10}}>
							<div style={{width:'40%',color:'white',fontSize:20}}>{item.photo_id}</div>
							<div style={{width:'60%',color:'white',fontSize:20}}>{item.description}</div>
							{item.isexpend?
								<div style={{backgroundColor:'#C7E9F7',borderRadius:10,color:'#004271',fontSize:17}}>
									<div onClick={()=>{
										let list=this.state.photo_list
										list[index].isexpend=false;
										this.getphoto(item._id);
										this.setState({photo_list:list,editmode:true,registermode:false,photoindex:index})
									}} className='mousehover' style={{display:'flex',padding:7,justifyContent:'center',alignItems:'center'}}>
										Edit
									</div>
									<div className='mousehover' style={{display:'flex',backgroundColor:'#7DDAFF',padding:7,borderRadius:10,justifyContent:'center',alignItems:'center'}}onClick={()=>{
										this.setState({ispopup:true,photoindex:index})
									}}>
										Delete
									</div>
									<div onClick={()=>{
										let list=this.state.photo_list
										for(let i in list)
											list[i].isexpend=false
										this.setState({photo_list:list})
									}} className='mousehover' style={{display:'flex',padding:7,justifyContent:'center',alignItems:'center'}}>
										Close
									</div>
								</div>:
								<img className='mousehover' onClick={()=>{
									let list=this.state.photo_list
									for(let i in list)
										list[i].isexpend=false
									list[index].isexpend=true
									this.setState({photo_list:list})
								}}  src={more_icon} style={{width:20,height:20}} alt='more_icon'/>
							}
						</div>}
					</div>
				))}
			</div>
			{(!this.state.editmode&&!this.state.registermode)||<div style={{backgroundColor:'#003459',position:'absolute',top:0,bottom:0,left:0,right:0,opacity:.9}}></div>}
			{(!this.state.editmode&&!this.state.registermode)||<div style={{position:'absolute',top:0,bottom:0,left:0,right:0,display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',overflowY: 'scroll'}}>
				<div style={{fontWeight:'bold',color:'#00DAC3',fontSize:40,margin:10}}>Photo Management</div>
				{this.state.photo_obj===null||this.state.photo_obj.base64?
					<img style={{maxHeight:'50%'}} src={this.state.photo_obj.base64} alt='img dln'/>:
					<div style={{width:300,height:300,border:'2px solid white'}}></div>
				}
				<form className='mousehover' style={{width:300,display:'flex',justifyContent:'center',alignItems:'center',marginTop:10,backgroundColor:'#7DDAFF',padding:10,borderRadius:5,flexDirection:'row'}}>
					<img src={picture_icon} style={{width:30,height:30,marginRight:20}} alt="picture_icon"/> 
					<input className="fileInput" type="file" onChange={(e)=>this.handleImageChange(e)}></input>
				</form>
				<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center'}}>
					<div style={{display:'flex',flex:1,margin:40,marginTop:10,marginRight:10,marginBottom:10,paddingBottom:20,borderBottomWidth:2,borderColor:'white',flexDirection:'row'}}>
						<div style={{color:'white',fontSize:20,width:'30%'}}>Photo ID</div>
						{this.state.registermode?<input style={{borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',backgroundColor: 'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,width:'65%',textAlign:'right'}} placeholder="Enter Photo ID here..." 
							onChange={(e)=>{
								let obj=this.state.photo_obj
								obj.photo_id=e.target.value
								this.setState({photo_obj:obj})
							}} value={this.state.photo_obj.photo_id===undefined?'':this.state.photo_obj.photo_id}/>:
							<div style={{color:'rgba(125, 218, 255, 1.0)',fontSize:20,width:'30%'}}>{this.state.photo_obj.photo_id}</div>
						}
					</div>
					<div style={{display:'flex',flex:1,margin:40,marginTop:10,marginBottom:10,marginLeft:10,paddingBottom:20,borderBottomWidth:2,borderColor:'white',flexDirection:'row'}}>
						<div style={{color:'white',fontSize:20,width:'30%'}}>Description</div>
						<input style={{borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',backgroundColor: 'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,width:'65%',textAlign:'right'}} placeholder="Enter Description Here..." 
							onChange={(e)=>{
								let obj=this.state.photo_obj
								obj.description=e.target.value
								this.setState({photo_obj:obj})
							}} value={this.state.photo_obj.description===undefined?'':this.state.photo_obj.description}/>
					</div>
				</div>
				<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center'}}>
					{this.state.registermode||<div className='mousehover' style={{display:'flex',margin:10,width:150,height:60,justifyContent:'center',alignItems:'center',borderRadius:10,fontSize:30,color:'#004271',backgroundColor:'#FF8B8B'}} onClick={()=>{this.setState({ispopup:true})}}>
						Delete
					</div>}
					<div className='mousehover' style={{display:'flex',margin:10,width:150,height:60,justifyContent:'center',alignItems:'center',borderRadius:10,backgroundColor:'#C7E9F7',fontSize:30,color:'#004271'}} onClick={()=>{this.setState({editmode:false,registermode:false})}}>
						Cancel
					</div>
					<div className='mousehover' style={{display:'flex',margin:10,width:150,height:60,justifyContent:'center',alignItems:'center',borderRadius:10,fontSize:30,color:'#004271',backgroundColor:'#7DDAFF'}} onClick={()=>{
						if(this.state.registermode)this.create_photo_();
						else this.update_photo_()
					}}>
						Save
					</div>
				</div>
			</div>}
			{!this.state.isloading||<LoadingPage/>}
			{!this.state.ispopup||<div style={{backgroundColor:'#003459',position:'absolute',top:0,bottom:0,left:0,right:0,opacity:.5}}></div>}
			{!this.state.ispopup||<div style={{display:'flex',flexDirection:'column',position:'absolute',top: window.innerHeight/2-100,left: window.innerWidth/2-150,width:300,height:200,padding:20,backgroundColor:'rgba(0, 66, 113, 1.0)',alignItems:'center',borderRadius:15,border:'2px solid white',}}>
				<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",borderBottom:'2px solid white'}}>Confirm?</div>
				<div style={{display:'flex',width:'100%',flexDirection:'row',alignItems:'center',flex:1,justifyContent:'center',height:'100%'}}>
					<div className='mousehover' onClick={()=>{this.delete_photo_by_id(this.state.photo_list[this.state.photoindex]._id)}}
						style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
						<div style={{color: "rgba(68, 215, 182, 1.0)",fontSize: 24,fontWeight: "bold",}}>YES</div>
					</div>
					<div className='mousehover' onClick={()=>{this.setState({ispopup:false})}}
						style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
						<div style={{color: "red",fontSize: 24,fontWeight: "bold",}}>NO</div>
					</div>
				</div>
			</div>}
		</div>)
	}
}


export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(PhotoListPage)



