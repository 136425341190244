import React from "react"
import { connect } from 'react-redux';
import {
	setpageindex,Login,setmenu
} from '../actions/Actions'

import cross from "./../assets/images/cross.png"
import forms_icon from "./../assets/images/forms_icon.png"
import submitted_icon from "./../assets/images/submitted_icon.png"
import report_gen_blue_icon from "./../assets/images/report_gen_blue_icon.png"
import user_icon_blue from "./../assets/images/user_icon_blue.png"
import client from "./../assets/images/client.png"
import photo_icon from "./../assets/images/photo.png"
import logout from "./../assets/images/logout.png"
import logo from "./../assets/images/logo.png"
import categories_icon from "./../assets/images/categories_icon.png"


class MenuPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	componentDidMount() {
	}
	render() {
		return(
			<div className='menu' style={{width:300,height:'100%'}}>
				<div onClick={()=>{this.props.dispatch(setpageindex(0))}} className='mousehover' style={{display:'flex',flexDirection:'column',alignItems:'center',position:'absolute',top:20}}>
					<img src={logo} style={{width:58,height:46}} alt='logo_icon'/>
					<div style={{fontSize:10,color:'white',fontWeight:'bold'}}>E REPORT SYSTEM</div>
				</div>
				{true||<div style={{display:'flex',flexDirection:'row',width:200,borderBottom:"3px solid white"}}>
					<div style={{color:'white',fontSize:20,fontWeight:'bold',width:170}}>MENU</div>
					<img onClick={()=>{this.props.dispatch(setmenu(false))}} className='mousehover' src={cross} style={{width:27,height:27}} alt='menu_icon'/>
				</div>}
				<div className='mousehover menu_row' onClick={()=>{this.props.dispatch(setpageindex(1))}}>
					<div className='menu_icon'>
						<img src={forms_icon} style={{width:23,height:23}} alt='menu_icon'/>
					</div>
					<div style={{color: "#7DDAFF",fontSize: 15,fontWeight: "bold",marginLeft:10}}>
						FORMS MANAGEMENT
					</div>
				</div>
				<div className='mousehover menu_row' onClick={()=>{this.props.dispatch(setpageindex(5))}}>
					<div className='menu_icon'>
						<img src={submitted_icon} style={{width:23,height:23}} alt='menu_icon'/>
					</div>
					<div style={{color: "#7DDAFF",fontSize: 15,fontWeight: "bold",marginLeft:10}}>
						SUBMITTED FORM
					</div>
				</div>
				<div className='mousehover menu_row' onClick={()=>{this.props.dispatch(setpageindex(10))}}>
					<div className='menu_icon'>
						<img src={report_gen_blue_icon} style={{width:23,height:23}} alt='menu_icon'/>
					</div>
					<div style={{color: "#7DDAFF",fontSize: 15,fontWeight: "bold",marginLeft:10}}>
						REPORT GENERATOR
					</div>
				</div>
				<div className='mousehover menu_row' onClick={()=>{this.props.dispatch(setpageindex(7))}}>
					<div className='menu_icon'>
						<img src={user_icon_blue} style={{width:23,height:23}} alt='menu_icon'/>
					</div>
					<div style={{color: "#7DDAFF",fontSize: 15,fontWeight: "bold",marginLeft:10}}>
						USER MANAGEMENT
					</div>
				</div>
				<div className='mousehover menu_row' onClick={()=>{this.props.dispatch(setpageindex(12))}}>
					<div className='menu_icon'>
						<img src={client} style={{width:23,height:23}} alt='menu_icon'/>
					</div>
					<div style={{color: "#7DDAFF",fontSize: 15,fontWeight: "bold",marginLeft:10}}>
						PROJECT MANAGEMENT
					</div>
				</div>
				<div className='mousehover menu_row' onClick={()=>{this.props.dispatch(setpageindex(13))}}>
					<div className='menu_icon'>
						<img src={photo_icon} style={{width:23,height:23}} alt='menu_icon'/>
					</div>
					<div style={{color: "#7DDAFF",fontSize: 15,fontWeight: "bold",marginLeft:10}}>
						PHOTO MANAGEMENT
					</div>
				</div>
				<div className='mousehover menu_row' onClick={()=>{this.props.dispatch(setpageindex(14))}}>
					<div className='menu_icon'>
						<img src={categories_icon} style={{width:23,height:23}} alt='menu_icon'/>
					</div>
					<div style={{color: "#7DDAFF",fontSize: 15,fontWeight: "bold",marginLeft:10}}>
						CATEGORY MANAGEMENT
					</div>
				</div>
				<div onClick={()=>{this.props.dispatch(Login(false))}} className='mousehover menu_row'>
					<div className='menu_icon'>
						<img src={logout} style={{width:23,height:23}} alt='menu_icon'/>
					</div>
					<div style={{color: "#7DDAFF",fontSize: 15,fontWeight: "bold",marginLeft:10}}>
						Log Out
					</div>
				</div>
			</div>
		);
	}
}

export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(MenuPage)