import React from "react"
import { connect } from 'react-redux';
import {
	Login
} from '../actions/Actions'
import {
	user_list,
	get_user_by_id,
	update_user,
	activate_user_by_id,
	deactivate_user_by_id,
	delete_user,
	register_user,
	reset_password
} from '../api'
import Dropdown from 'react-dropdown'
import LoadingPage from './LoadingPage'
import search_icon from "./../assets/images/search_white.png"
import user_management_purple from './../assets/images/user_management_purple.png'
import add_icon from "./../assets/images/add_icon.png"
import refresh from "./../assets/images/refresh.png"
import more_icon from "./../assets/images/more.png"
import picture_icon from "./../assets/images/picture.png"


class UserListPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isloading:true,
			scroll_refreshing:false,
			error_message:null,

			user_list:[],
			editmode:false,
			userindex:-1,
			user_obj:null,
			user_photo:null,
			stamp_photo:null,
			photomode:false,
			cameramode:false,
			registermode:false,

			text_search:''
		}
	}

	componentDidMount=async()=> {
		this.get_user_list(true)
	}
	handleStampChange(e){
		e.preventDefault();
		let file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			this.setState({
				stamp_photo: reader.result
			});
		};
	}
	handleImageChange(e) {
		e.preventDefault();
		let file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			this.setState({
				user_photo: reader.result
			});
		};
	}
	get_user_list=async(force=false)=>{
		if(!force&&this.state.isloading)return
		this.setState({isloading:true})
		let result=await user_list(this.props.control.token)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}
		if(result!=null&&result.result!==undefined&&result.result!=='fail'&&result.result.length>0){
			this.setState({user_list:result.result,error_message:null})
		}
		this.setState({isloading:false})
	}
	get_user_obj=async(_id)=>{
		if(this.state.isloading)return
		this.setState({isloading:true})
		let result=await get_user_by_id(this.props.control.token,_id)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}
		if(result!=null&&result.result!==undefined&&result.result!=='fail'&&result.result.length>0){
			this.setState({user_photo:result.result[0].photo,error_message:null,stamp_photo:result.result[0].stamp})
		}
		this.setState({isloading:false})
	}
	update_user_=async()=>{
		if(this.state.user_obj.email===''||this.state.user_obj.email===undefined){
			alert('email is empty');return
		}
		if(this.state.user_obj.position===''||this.state.user_obj.position===undefined){
			alert('position is empty');return
		}
		if(!this.state.user_obj.email.includes('@')&&this.state.user_obj.email.toLowerCase()!=='na'){
			alert('Incorrect email format');return
		}
		if(!this.state.isloading){
			this.setState({isloading:true})
			let obj={...this.state.user_obj,photo:this.state.user_photo,stamp:this.state.stamp_photo}
			let result=await update_user(this.props.control.token,obj)
			if(result.message&&result.message==='Unauthorized'){
				this.props.dispatch(Login(false));alert('Session Expired!');return
			}
			if(result===undefined||result===null){
				alert('update Form Error')
			}else if(result.result!=='success'){
				alert(result.message)
			}else if(result.result==='success'){
				this.get_user_list(true)
				this.setState({cameramode:false,editmode:false,isloading:false,error_message:null})
				return
			}else alert('update error')
			this.setState({isloading:false})
		}
	}
	act_deact_user=async(is_activate,_id)=>{
		if(!this.state.isloading){
			this.setState({isloading:true})
			let result
				if(is_activate)
					result=await activate_user_by_id(this.props.control.token,_id)
				else
					result=await deactivate_user_by_id(this.props.control.token,_id)
			if(result.message&&result.message==='Unauthorized'){
				this.props.dispatch(Login(false));alert('Session Expired!');return
			}
			if(result===undefined||result===null){
				alert('update Form Error');
			}else if(result.result!=='success'){
				alert(result.message)
			}else if(result.result==='success'){
				this.get_user_list(true)
				this.setState({isloading:false,error_message:null})
				return
			}else alert('activate error')
			this.setState({isloading:false})
		}
	}
	delete_user_by_id=async(_id)=>{
		if(!this.state.isloading){
			this.setState({isloading:true})
			let result=await delete_user(this.props.control.token,_id)
			if(result.message&&result.message==='Unauthorized'){
				this.props.dispatch(Login(false));alert('Session Expired!');return
			}
			if(result===undefined||result===null){
				alert('Delete User Error');
			}else if(result.result!=='success'){
				alert(result.message);
			}else if(result.result==='success'){
				this.get_user_list(true)
				this.setState({isloading:false,editmode:false,ispopup:false,userindex:-1,user_obj:null,user_photo:null,stamp_photo:null,registermode:false,error_message:null})
				return
			}else alert('delete error')
			this.setState({isloading:false})
		}
	}
	register_user_=async()=>{
		if(this.state.user_obj.email===''||this.state.user_obj.email===undefined){
			alert('email is empty');return
		}
		if(!this.state.user_obj.email.includes('@')&&this.state.user_obj.email.toLowerCase()!=='na'){
			alert('Incorrect email format');return
		}
		if(this.state.user_obj.username===''||this.state.user_obj.username===undefined){
			alert('username is empty');return
		}
		if(this.state.user_obj.username.length<5){
			alert('Username must have at least 5 characters');return
		}
		if(this.state.user_obj.position===''||this.state.user_obj.position===undefined){
			alert('position is empty');return
		}
		if(!this.state.isloading){
			this.setState({isloading:true})
			let obj={...this.state.user_obj,photo:this.state.user_photo,password:this.state.user_obj.username,stamp:this.state.stamp_photo}
			let result=await register_user(this.props.control.token,obj)
			if(result.message&&result.message==='Unauthorized'){
				this.props.dispatch(Login(false));alert('Session Expired!');return
			}
			if(result===undefined||result===null){
				alert('register user error');
			}else if(result.result!=='success'){
				alert(result.message)
			}else if(result.result==='success'){
				this.get_user_list(true)
				this.setState({cameramode:false,editmode:false,isloading:false,registermode:false,error_message:null})
				return
			}else alert('register error')
			this.setState({isloading:false})
		}
	}
	reset_password_=async (_id)=>{
		if(this.state.isloading)return
		this.setState({isloading:true})
		let result=await reset_password(this.props.control.token,_id)
		if(result.message&&result.message==='Unauthorized'){
			this.props.dispatch(Login(false));alert('Session Expired!');return
		}
		if(result===undefined||result===null){
			alert('reset password error');
		}else if(result.result!=='success'){
			alert(result.message)
		}else if(result.result==='success'){
			alert('Password has been reset. Please check email.')
			let list=this.state.user_list
			for(let i in this.state.user_list)
				list[i].isexpend=false;
			this.setState({user_list:list})
		}else alert('reset error')
		this.setState({isloading:false})
	}

	render(){
		return(
			<div style={{width:'100%',height:'100%',color:'white',overflowY: 'scroll'}}>
				<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
					<div style={{backgroundColor:'#007EA7',borderRadius:5,marginRight:2,width:'80%',height:46,marginLeft:10,display:'flex',flexDirection:'row',alignItems:'center'}}>
						<img src={search_icon} style={{marginLeft:10,marginRight:10,width:30,height:30}} alt='forms'/>
						<input style={{ backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgba(0,0,0,0)', color:"#98E2FF",width:'95%',fontSize: 19,marginLeft:5}} value={this.state.text_search} onChange={(e)=>{this.setState({text_search:e.target.value})}} placeholder="Search for User...."/>
					</div>
				</div>
				<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginTop:20}}>
					<div style={{display:'flex',flexDirection:'row',width:'80%',alignItems:'center',justifyContent:'space-between'}}>
						<div className='center_row'>
							<img src={user_management_purple} style={{marginLeft:10,marginRight:10,width:47,height:47}} alt='forms'/>
							<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",}}>User Management</div>
							<div className='mousehover' onClick={()=>{this.setState({registermode:true,user_obj:{username:'',email:'',position:'',status:0,type:'user'},user_photo:null,stamp_photo:null})}} style={{display:'flex',flexDirection:'row',backgroundColor:'#7DDAFF',borderRadius:5,marginLeft:20,height:40,paddingLeft:20,paddingRight:20,justifyContent:'center',alignItems:'center'}}>
								<img src={add_icon} style={{width:17,height:17}} alt='add_icon'/>
								<div style={{color: "white",fontSize: 14,fontWeight: "bold",marginLeft:5}}>ADD</div>
							</div>
						</div>
						
						<div onClick={()=>{this.get_user_list()}}style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
							<img className='mousehover' src={refresh} style={{width:20,height:20}} alt='refresh'/>
						</div>
					</div>
				</div>
				<div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:20,flexDirection:'column'}}>
					<div style={{display:'flex',flexDirection:'row',width:'90%',borderBottom:'2px solid #028090',paddingTop:10,paddingBottom:10}}>
						<div style={{width:'30%',color:'white',fontSize:20,fontWeight:'bold'}}>User Name</div>
						<div style={{width:'40%',color:'white',fontSize:20,fontWeight:'bold'}}>Email</div>
						<div style={{width:'15%',color:'white',fontSize:20,fontWeight:'bold'}}>Role</div>
						<div style={{width:'15%',color:'white',fontSize:20,fontWeight:'bold'}}>Status</div>
					</div>
				
					{this.state.user_list.map((item,index)=>(
						<div key={index} style={{width:'90%'}}>
							{!this.state.text_search===''||!item.username.toLowerCase().includes(this.state.text_search.toLowerCase())||<div style={{display:'flex',flexDirection:'row',width:'100%',borderBottom:'2px solid #028090',paddingTop:10,paddingBottom:10}}>
								<div style={{width:'30%',color:'white',fontSize:20}}>{item.username}</div>
								<div style={{width:'40%',color:'white',fontSize:20}}>{item.email}</div>
								<div style={{width:'15%',color:'white',fontSize:20}}>{item.type==='admin'?'Admin':'Editor'}</div>
								<div style={{width:'15%',color:'white',fontSize:20}}>{item.status===1?'Active':'Inactive'}</div>
								
								{item.isexpend?
									<div style={{backgroundColor:'#C7E9F7',borderRadius:10,color:'#004271',fontSize:17}}>
										<div onClick={()=>{
											let list=this.state.user_list
											list[index].isexpend=false;
											this.get_user_obj(item._id);
											this.setState({user_list:list,editmode:true,registermode:false,userindex:index,user_obj:{...list[index]}})
										}} className='mousehover' style={{display:'flex',padding:7,justifyContent:'center',alignItems:'center'}}>
											Edit
										</div>
										<div className='mousehover' style={{display:'flex',backgroundColor:'#7DDAFF',padding:7,borderRadius:10,justifyContent:'center',alignItems:'center'}}onClick={()=>{
											this.reset_password_(item._id)
										}}>
											Reset Password
										</div>
										<div onClick={()=>{
											this.setState({ispopup:true,userindex:index})
										}} className='mousehover' style={{display:'flex',padding:7,justifyContent:'center',alignItems:'center'}}>
											Delete
										</div>
										<div onClick={()=>{
											this.act_deact_user(item.status===1?false:true,item._id)
										}} className='mousehover' style={{display:'flex',backgroundColor:'#7DDAFF',padding:7,borderRadius:10,justifyContent:'center',alignItems:'center'}}>
											{item.status===1?'Deactivate':'Activate'}
										</div>
										<div onClick={()=>{
											let list=this.state.user_list
											for(let i in list)
												list[i].isexpend=false
											this.setState({user_list:list})
										}} className='mousehover' style={{display:'flex',padding:7,justifyContent:'center',alignItems:'center'}}>
											Close
										</div>
									</div>:
									<img className='mousehover' onClick={()=>{
										let list=this.state.user_list
										for(let i in list)
											list[i].isexpend=false
										list[index].isexpend=true
										this.setState({user_list:list})
									}}  src={more_icon} style={{width:20,height:20}} alt='more_icon'/>
								}
							</div>}
						</div>
					))}
				</div>
				{(!this.state.editmode&&!this.state.registermode)||<div style={{backgroundColor:'#003459',position:'absolute',top:0,bottom:0,left:0,right:0,opacity:.9}}></div>}
				{(!this.state.editmode&&!this.state.registermode)||<div style={{position:'absolute',top:0,bottom:0,left:0,right:0,display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',overflowY: 'scroll'}}>
					<div style={{fontWeight:'bold',color:'#00DAC3',fontSize:40,margin:10}}>User Management</div>
					{this.state.user_photo?
						<img style={{maxHeight:'40%'}} src={this.state.user_photo} alt="photo_icon"/>:
						<div style={{width:300,height:300,border:'2px solid white'}}></div>
					}
					<form className='mousehover' style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:10,backgroundColor:'#7DDAFF',padding:10,borderRadius:5,flexDirection:'row'}}>
						<img src={picture_icon} style={{width:30,height:30,marginRight:20}} alt='picture_icon'/> 
						<input className="fileInput" type="file" onChange={(e)=>this.handleImageChange(e)}></input>
					</form>
					<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center'}}>
						<div style={{display:'flex',flex:1,margin:40,marginTop:10,marginRight:10,marginBottom:10,paddingBottom:20,borderBottomWidth:2,borderColor:'white',flexDirection:'row'}}>
							<div style={{color:'white',fontSize:20,width:'30%'}}>User Name</div>
							{this.state.registermode?<input style={{borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',backgroundColor: 'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,width:'65%',textAlign:'right'}} placeholder="Enter Username here..." 
								onChange={(e)=>{
									let obj=this.state.user_obj
									obj.username=e.target.value
									this.setState({user_obj:obj})
								}} value={this.state.user_obj.username===undefined?'':this.state.user_obj.username}/>:
								<div style={{color:'rgba(125, 218, 255, 1.0)',fontSize:20,width:'30%'}}>{this.state.user_obj.username}</div>
							}
						</div>
						<div style={{display:'flex',flex:1,margin:40,marginTop:10,marginBottom:10,marginLeft:10,paddingBottom:20,borderBottomWidth:2,borderColor:'white',flexDirection:'row'}}>
							<div style={{color:'white',fontSize:20,width:'30%'}}>Position</div>
							<input style={{borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',backgroundColor: 'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,width:'65%',textAlign:'right'}} placeholder="Enter Position Here..." 
								onChange={(e)=>{
									let obj=this.state.user_obj
									obj.position=e.target.value
									this.setState({user_obj:obj})
								}} value={this.state.user_obj.position===undefined?'':this.state.user_obj.position}/>
						</div>
					</div>
					<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center'}}>
						<div style={{display:'flex',flex:1,margin:40,marginTop:10,marginRight:10,marginBottom:10,paddingBottom:20,flexDirection:'row'}}>
							<div style={{color:'white',fontSize:20,width:'30%'}}>Role</div>
							<div style={{width:'70%'}}>
								<Dropdown style={{width:'50%'}}
									options={[{value:'Admin'},{value:'Editor'}]}
									placeholderClassName='dropdown_placeholder' controlClassName='dropdown_control' 
									onChange={(e)=>{
										let obj=this.state.user_obj
										obj.type=e.value==='Admin'?'admin':'user'
										this.setState({user_obj:obj})
									}} value={`-${this.state.user_obj.type==='admin'?'Admin':'Editor'}`}
								/>
							</div>
						</div>
						<div style={{display:'flex',flex:1,marginTop:10,marginBottom:10,paddingBottom:20,borderBottomWidth:2,borderColor:'white',flexDirection:'row'}}>
							<div style={{color:'white',fontSize:20,width:'30%'}}>Email</div>
							<input style={{borderColor:'rgba(0,0,0,0)',borderBottom:'1px solid rgba(125, 218, 255, 1.0)',backgroundColor: 'rgba(0,0,0,0)',color:'rgba(125, 218, 255, 1.0)',fontSize:20,width:'65%',textAlign:'right'}} placeholder="Enter Email Here...." 
								onChange={(e)=>{
									let obj=this.state.user_obj
									obj.email=e.target.value
									this.setState({user_obj:obj})
								}} value={this.state.user_obj.email}/>
						</div>
						{this.state.registermode||<div style={{display:'flex',flexDirection:'row',flex:1,margin:40,marginTop:10,marginLeft:10,marginBottom:10,paddingBottom:20}}>
							<div style={{color:'white',fontSize:20,width:'30%'}}>Status</div>
							<div style={{width:'70%'}}>
								<Dropdown style={{textAlign:'right',width:'50%'}} placeholderClassName='dropdown_placeholder' controlClassName='dropdown_control' 
									options={[{value:'Active'},{value:'Inactive'}]} 
									onChange={(e)=>{
										let obj=this.state.user_obj
										obj.status=e.value==='Active'?1:0
										this.setState({user_obj:obj})
										console.log(e.value)
										console.log(obj.status)

									}} value={`-${this.state.user_obj.status===1?'Active':'Inactive'}`}
								/>
							</div>
						</div>}
					</div>
					<div style={{width:300,margin:20, padding:10,marginTop:0,marginBottom:0}}>
						{!this.state.stamp_photo||<img style={{maxWidth:400,borderRadius:10}} src={this.state.stamp_photo} alt='stamp'/>}
						<form style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:10,backgroundColor:'#7DDAFF',padding:10,borderRadius:5,flexDirection:'row'}}>
							<input className="fileInput" type="file" onChange={(e)=>this.handleStampChange(e)}></input>
						</form>
					</div>
					<div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center'}}>
						{this.state.registermode||<div className='mousehover' style={{display:'flex',margin:10,width:150,height:60,justifyContent:'center',alignItems:'center',borderRadius:10,fontSize:30,color:'#004271',backgroundColor:'#FF8B8B'}} onClick={()=>{this.setState({ispopup:true})}}>
							Delete
						</div>}
						<div className='mousehover' style={{display:'flex',margin:10,width:150,height:60,justifyContent:'center',alignItems:'center',borderRadius:10,backgroundColor:'#C7E9F7',fontSize:30,color:'#004271'}} onClick={()=>{this.setState({editmode:false,registermode:false})}}>
							Cancel
						</div>
						<div className='mousehover' style={{display:'flex',margin:10,width:150,height:60,justifyContent:'center',alignItems:'center',borderRadius:10,fontSize:30,color:'#004271',backgroundColor:'#7DDAFF'}} onClick={()=>{
							if(this.state.registermode)this.register_user_();
							else this.update_user_()
						}}>
							Save
						</div>
					</div>
				</div>}
				{!this.state.isloading||<LoadingPage/>}
				{!this.state.ispopup||<div style={{backgroundColor:'#003459',position:'absolute',top:0,bottom:0,left:0,right:0,opacity:.5}}></div>}
				{!this.state.ispopup||<div style={{display:'flex',flexDirection:'column',position:'absolute',top: window.innerHeight/2-100,left: window.innerWidth/2-150,width:300,height:200,padding:20,backgroundColor:'rgba(0, 66, 113, 1.0)',alignItems:'center',borderRadius:15,border:'2px solid white',}}>
					<div style={{color: "#7DDAFF",fontSize: 24,fontWeight: "bold",borderBottom:'2px solid white'}}>Confirm?</div>
					<div style={{display:'flex',width:'100%',flexDirection:'row',alignItems:'center',flex:1,justifyContent:'center',height:'100%'}}>
						<div className='mousehover' onClick={()=>{this.delete_user_by_id(this.state.user_list[this.state.userindex]._id)}}
							style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
							<div style={{color: "rgba(68, 215, 182, 1.0)",fontSize: 24,fontWeight: "bold",}}>YES</div>
						</div>
						<div className='mousehover' onClick={()=>{this.setState({ispopup:false})}}
							style={{display:'flex',width:'40%',margin:10,border:'2px solid white',borderRadius:10,alignItems:'center',justifyContent:'center'}}>
							<div style={{color: "red",fontSize: 24,fontWeight: "bold",}}>NO</div>
						</div>
					</div>
				</div>}
			</div>
		)
	}
}

export default connect((store)=>{
  return{
    control:store.firstReducer,
  }
})(UserListPage)



